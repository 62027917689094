<script setup lang="ts">
import useMarkdown from '@/useMarkdown'
import { computed } from 'vue'

const props = defineProps<{
  text?: string
  clipAround?: number
}>()

const { parseMarkdown } = useMarkdown()
const clippedText = computed(() => {
  if (!props.text || !props.clipAround) {
    return props.text
  }
  if (props.clipAround >= props.text.length) {
    return props.text
  }

  return props.text.substring(0, props.text.indexOf(' ', props.clipAround - 5)) + '...'
})
</script>

<template>
  <div class="markdownText" v-html="parseMarkdown(clippedText)" />
</template>

<style lang="scss">
.markdownText {
  p {
    margin-bottom: 1em;
  }
}
</style>
