<script setup lang="ts">
import { computed } from 'vue'
import { HighestDurationPerStep, StepTreeStepNode } from '@/components/step/StepTree.vue'
import { useRouter } from 'vue-router'
import Period from '@/components/Period.vue'
import IconIssue from '@/components/icons/IconIssue.vue'
import { uniqueArray } from '@/app'

const props = defineProps<{
  moduleId: string
  node: StepTreeStepNode
  highestDurationPerStep: HighestDurationPerStep
  dragDropDisabled: boolean
}>()
const emit = defineEmits<{
  moveBefore: [movedNodeId: string, targetNodeId: string]
  moveAfter: [movedNodeId: string, targetNodeId: string]
  makeFirstChild: [movedNodeId: string, targetNodeId: string]
  selectStepId: [value: string]
}>()
const router = useRouter()

const stepNode = computed(() => props.node.stepNode)
const children = computed(() => props.node.children)
const unresolvedIssueCount = computed(() => {
  const all = props.node.stepNode.step.issueLinks
    .filter((l) => !l.issue.resolvedAt)
    .map((l) => l.issue)
  return uniqueArray(all, (item) => item.id).length
})

const classDropContainer = 'drop-container'
const classDropHover = 'drop-hover'
const eventFieldNodeId = 'nodeId'

function isParentOfDraggedNode(e: DragEvent) {
  const types = e.dataTransfer?.types || []
  return props.node.ancestryNodeIds.some((id) => types.includes(`ancestry-node-id/${id}`))
}
function dragStart(e: DragEvent) {
  const dt = e.dataTransfer as DataTransfer
  dt.effectAllowed = 'move'
  dt.dropEffect = 'move'
  dt.setData(eventFieldNodeId, stepNode.value.id)
  dt.setData(`ancestry-node-id/${props.node.stepNode.id}`, 'true')
}
function dragEnter(e: DragEvent) {
  const target = e.target as HTMLElement
  if (!target.classList.contains(classDropContainer)) {
    return
  }
  if (isParentOfDraggedNode(e)) {
    return
  }

  e.preventDefault()
  target.classList.add(classDropHover)
}
function dragLeave(e: DragEvent) {
  const target = e.target as HTMLElement
  if (!target.classList.contains(classDropContainer)) {
    return
  }

  target.classList.remove(classDropHover)
}
function dragEnd(e: DragEvent) {
  document.querySelectorAll(`.${classDropContainer}`).forEach((e) => {
    e.classList.remove(classDropHover)
  })
}

function getNodeId(e: DragEvent) {
  return e.dataTransfer?.getData(eventFieldNodeId) as string
}
function dropBefore(e: DragEvent) {
  if (isParentOfDraggedNode(e)) {
    return
  }
  emit('moveBefore', getNodeId(e), stepNode.value.id)
}
function dropAfter(e: DragEvent) {
  if (isParentOfDraggedNode(e)) {
    return
  }
  emit('moveAfter', getNodeId(e), stepNode.value.id)
}
function makeFirstChild(e: DragEvent) {
  if (isParentOfDraggedNode(e)) {
    return
  }
  emit('makeFirstChild', getNodeId(e), stepNode.value.id)
}
</script>

<template>
  <v-sheet class="step-container" @click.stop="$emit('selectStepId', stepNode.step.id)">
    <v-row dense class="drop-row">
      <div
        class="upper-drop drop-container"
        @dragenter="dragEnter"
        @dragover.prevent
        @dragleave.prevent="dragLeave"
        @drop.prevent="dropBefore"
      />
      <div
        class="inner-drop drop-container"
        @dragenter.prevent="dragEnter"
        @dragover.prevent
        @dragleave.prevent="dragLeave"
        @drop.prevent="makeFirstChild"
      />
      <div
        class="lower-drop drop-container"
        @dragenter.prevent="dragEnter"
        @dragover.prevent
        @dragleave.prevent="dragLeave"
        @drop.prevent="dropAfter"
      />
      <v-col cols="6">
        <div :draggable="!props.dragDropDisabled" @dragstart="dragStart" @dragend="dragEnd">
          <v-chip :variant="props.node.isSelected ? 'outlined' : 'text'" density="compact">
            <v-icon v-if="!props.dragDropDisabled" icon="drag_indicator" class="drag-handle" />

            {{ props.node.numbering }}
            {{ stepNode.step.name }}

            <v-chip v-if="unresolvedIssueCount > 0" density="compact" class="ml-1">
              <icon-issue color="error" />
              {{ unresolvedIssueCount }}
            </v-chip>
          </v-chip>
        </div>
      </v-col>
      <v-col cols="3">
        <v-chip density="compact">{{ stepNode.step.stepType.name }}</v-chip>
      </v-col>
      <v-col cols="3">
        <v-progress-linear
          v-if="children.length == 0"
          :model-value="stepNode.step.durationInSeconds"
          :max="props.highestDurationPerStep[stepNode.step.id]"
          height="15"
          color="secondary"
        >
          <period :seconds="stepNode.step.durationInSeconds" />
        </v-progress-linear>
      </v-col>
    </v-row>

    <div v-if="children.length > 0" class="children-container">
      <step-tree-node
        v-for="c in children"
        :key="c.stepNode.id"
        :node="c"
        :highest-duration-per-step="props.highestDurationPerStep"
        :module-id="props.moduleId"
        :drag-drop-disabled="props.dragDropDisabled"
        @move-before="(movedNodeId, targetNodeId) => $emit('moveBefore', movedNodeId, targetNodeId)"
        @move-after="(movedNodeId, targetNodeId) => $emit('moveAfter', movedNodeId, targetNodeId)"
        @make-first-child="
          (movedNodeId, targetNodeId) => $emit('makeFirstChild', movedNodeId, targetNodeId)
        "
        @select-step-id="(v) => $emit('selectStepId', v)"
      />
    </div>
  </v-sheet>
</template>

<style scoped lang="scss">
.step-container {
  padding-left: 0.3em;
  cursor: pointer;
}
.drag-handle {
  z-index: 110;
  cursor: grab;
}
.drop-row {
  position: relative;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.children-container {
  margin-top: 0.2em;
  margin-left: 1.5em;
  padding-bottom: 1em;
}
.drop-container {
  z-index: 99;
  position: absolute;
  width: 100%;

  * {
    pointer-events: none;
  }
}
.upper-drop {
  height: 50%;
  top: -25%;
  right: 0;
}
.inner-drop {
  height: 50%;
  top: 25%;
  left: 2em;
  width: calc(100% - 2em);
}
.lower-drop {
  height: 1em;
  bottom: -25%;
  right: 0;
}
.drop-hover {
  border: #555 dashed 2px;
}
</style>
