<script setup lang="ts">
import { useI18n } from 'vue-i18n'

const props = defineProps<{
  milliQuantity?: number
}>()

const { t } = useI18n()
</script>

<template>
  {{ props.milliQuantity ? props.milliQuantity / 1000 : '-' }}
</template>

<style scoped lang="scss"></style>
