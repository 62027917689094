<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import DateTime from '@/components/DateTime.vue'
import DataTable from '@/components/dataTable/DataTable.vue'
import DataTableCreateButton from '@/components/dataTable/DataTableCreateButton.vue'
import { Issue } from '@/generated/graphql'
import { computed, ref } from 'vue'
import { uniqueArray } from '@/app'
import { ComponentExposed } from 'vue-component-type-helpers'
import IssueDialog from '@/components/issue/IssueDialog.vue'
import CreateIssueDialog, { CreateIssueSeed } from '@/components/issue/CreateIssueDialog.vue'

type LocalIssue = Pick<Issue, 'id' | 'name' | 'createdAt' | 'resolvedAt'>
type LocalLink = { issue: LocalIssue }

const props = defineProps<{
  links: LocalLink[]
  createSeed?: CreateIssueSeed
  withResolved?: boolean
}>()

const { t } = useI18n()

const unresolvedIssues = computed(() => {
  const all = props.links
    .filter((l) => props.withResolved || !l.issue.resolvedAt)
    .map((l) => l.issue)
  return uniqueArray<LocalIssue, string>(all, (item) => item.id)
})
const issueDialog = ref<ComponentExposed<typeof IssueDialog>>()
const selectIssue = (_: unknown, row: { item: LocalIssue }) => {
  issueDialog.value?.open(row.item.id)
}
const createIssueDialog = ref<ComponentExposed<typeof CreateIssueDialog>>()
const createIssue = () => {
  createIssueDialog.value?.open(props.createSeed)
}
const issueHeaders = [
  {
    key: 'name',
    title: t('entity.issue.field.name'),
  },
  {
    key: 'createdAt',
    title: t('entity.issue.field.createdAt'),
  },
  {
    key: 'actions',
    width: 100,
    sortable: false,
    align: 'end',
  },
]
const issueSort = [{ key: 'createdAt', order: 'desc' }]
</script>

<template>
  <data-table
    :headers="issueHeaders"
    :sort-by="issueSort"
    :items="unresolvedIssues"
    hide-default-footer
    v-bind="$attrs"
    @click:row="selectIssue"
  >
    <template #header.actions>
      <data-table-create-button @click="createIssue" />
    </template>
    <template #item.createdAt="{ item }: { item: LocalIssue }">
      <date-time :model-value="item.createdAt" />
    </template>
  </data-table>

  <issue-dialog ref="issueDialog" />
  <create-issue-dialog ref="createIssueDialog" />
</template>

<style scoped lang="scss"></style>
