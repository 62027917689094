<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { computed, reactive, ref } from 'vue'
import { useMutation, useQuery } from '@vue/apollo-composable'
import {
  CreateProduct,
  Mutation,
  ProductViewCreateMutation,
  ProductViewCreateMutationVariables,
  ProductViewDataQuery,
  Query,
  UpdateProduct,
  UpdateSite,
} from '@/generated/graphql'
import gql from 'graphql-tag'
import useVuelidate from '@vuelidate/core'
import { authzIsOrgProductManagement, currentOrganization, KeysOfType } from '@/app'
import TextField from '@/components/input/TextField.vue'
import { abbreviation, normalDescription, normalName, required, unique } from '@/validation'
import TextAreaField from '@/components/input/TextAreaField.vue'
import { useRouter } from 'vue-router'
import ImagePicker from '@/components/input/ImagePicker.vue'
import { ComponentExposed } from 'vue-component-type-helpers'
import UpsertDialog from '@/components/dialogs/UpsertDialog.vue'
import DeleteDialogNext from '@/components/dialogs/AsyncDeleteDialog.vue'
import AsyncDeleteDialog from '@/components/dialogs/AsyncDeleteDialog.vue'

type LocalProduct = ProductViewDataQuery['product']['products'][0]
type Model = Partial<UpdateProduct>
const modelKeys: KeysOfType<keyof Model> = [
  'id',
  'name',
  'abbreviation',
  'description',
  'imageUploadId',
]
const { t } = useI18n()

const fetchQuery = useQuery<Query>(
  gql`
    query ProductViewData {
      product {
        products {
          id
          name
          abbreviation
          description
          imageUploadId
          imageUpload {
            id
            imageUrl(options: { width: 500, height: 300 })
          }
        }
      }
    }
  `,
  () => ({
    org: currentOrganization.value, // Here to refresh the query when the org changes
  }),
)
const items = computed(() => fetchQuery.result.value?.product.products || [])

const model = reactive<Model>({})

const takenAbbreviations = computed(() =>
  items.value.filter((i) => i.id != model.id).map((i) => i.abbreviation),
)
const validation = useVuelidate<Model>(
  {
    name: { required, normalName },
    abbreviation: { required, abbreviation, unique: unique(takenAbbreviations) },
    description: { normalDescription },
  },
  model,
)

const createMutation = useMutation<ProductViewCreateMutation, ProductViewCreateMutationVariables>(
  gql`
    mutation ProductViewCreate($command: CreateProduct!) {
      product {
        createProduct(command: $command) {
          id
        }
      }
    }
  `,
)
const createDialog = ref<ComponentExposed<typeof UpsertDialog<() => Promise<unknown>>>>()
const doCreate = async () => {
  modelKeys.forEach((k) => (model[k as keyof Model] = undefined))
  await createDialog.value?.open(() =>
    createMutation.mutate({ command: model as CreateProduct }).then(() => fetchQuery.refetch()),
  )
}

const updateMutation = useMutation<Mutation>(gql`
  mutation ProductViewUpdate($command: UpdateProduct!) {
    product {
      updateProduct(command: $command) {
        id
      }
    }
  }
`)
const updateDialog = ref<ComponentExposed<typeof UpsertDialog<() => Promise<unknown>>>>()
const doUpdate = async (item: LocalProduct) => {
  modelKeys.forEach((k) => (model[k as keyof Model] = item[k]))
  await updateDialog.value?.open(() =>
    updateMutation.mutate({ command: model as UpdateSite }).then(() => fetchQuery.refetch()),
  )
}

const deleteMutation = useMutation<Mutation>(gql`
  mutation ProductViewDelete($id: ID!) {
    product {
      deleteProduct(id: $id)
    }
  }
`)
const deleteDialog = ref<ComponentExposed<typeof DeleteDialogNext>>()
const doDelete = async (item: LocalProduct) => {
  await deleteDialog.value?.open(item.name, () =>
    deleteMutation.mutate({ id: item.id }).then(() => fetchQuery.refetch()),
  )
}

const router = useRouter()
function goToConfigurations(productId: string) {
  router.push({
    name: 'productConfigurations',
    params: { productId },
  })
}
</script>

<template>
  <h1>
    {{ t('entity.product.plural') }}
    <v-btn icon="add" color="primary" class="float-right" @click="doCreate()" />
  </h1>

  <v-alert v-if="items.length == 0 && !fetchQuery.loading" type="info">
    {{ t('component.dataTable.noData') }}
  </v-alert>
  <v-row>
    <v-col v-for="p in items" :key="p.id" xxl="2" xl="3" lg="3" sm="4" xs="6">
      <v-card>
        <router-link :to="{ name: 'productConfigurations', params: { productId: p.id } }">
          <v-img v-if="p.imageUpload?.imageUrl" :src="p.imageUpload.imageUrl" height="175px" />
        </router-link>
        <v-card-title>
          <router-link
            :to="{ name: 'productConfigurations', params: { productId: p.id } }"
            style="color: #000"
          >
            {{ p.name }}
          </router-link>
        </v-card-title>
        <v-card-text>
          {{ p.description }}
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            v-if="authzIsOrgProductManagement"
            density="compact"
            size="small"
            icon="settings"
            @click="goToConfigurations(p.id)"
          />
          <v-btn density="compact" size="small" icon="edit" @click="doUpdate(p)" />
          <v-btn
            v-if="authzIsOrgProductManagement"
            density="compact"
            size="small"
            icon="delete"
            @click="doDelete(p)"
          />
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>

  <async-delete-dialog ref="deleteDialog" :warning="t('entity.product.deleteWarning')" hindrance />

  <upsert-dialog ref="createDialog" type="create" :validation="validation">
    <text-field
      v-model="model.name"
      :label="t('entity.product.field.name')"
      required
      :validation="validation.name"
    />
    <text-field
      v-model="model.abbreviation"
      :label="t('entity.product.field.abbreviation')"
      required
      :validation="validation.abbreviation"
    />
    <text-area-field
      v-model="model.description"
      :label="t('entity.product.field.description')"
      required
      :validation="validation.description"
    />
    <image-picker v-model="model.imageUploadId" :label="t('entity.product.field.image')" />
  </upsert-dialog>

  <upsert-dialog ref="updateDialog" type="update" :validation="validation">
    <text-field
      v-model="model.name"
      required
      :label="t('entity.product.field.name')"
      :validation="validation.name"
    />
    <text-field
      v-model="model.abbreviation"
      :label="t('entity.product.field.abbreviation')"
      required
      :validation="validation.abbreviation"
    />
    <text-area-field
      v-model="model.description"
      :label="t('entity.product.field.description')"
      :validation="validation.description"
    />
    <image-picker v-model="model.imageUploadId" :label="t('entity.product.field.image')" />
  </upsert-dialog>
</template>

<style scoped lang="scss"></style>
