<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { useMutation, useQuery } from '@vue/apollo-composable'
import gql from 'graphql-tag'
import { computed, ref } from 'vue'
import { AgreeToTermsDataQuery, AgreeToTermsDataQueryVariables } from '@/generated/graphql'
import Checkbox from '@/components/input/Checkbox.vue'

const { t } = useI18n()

const fetchQuery = useQuery<AgreeToTermsDataQuery, AgreeToTermsDataQueryVariables>(gql`
  query AgreeToTermsData {
    account {
      hasAgreedToTerms
    }
  }
`)
const hasAgreedToTerms = computed(() => fetchQuery.result.value?.account.hasAgreedToTerms)
const showDialog = computed(() => hasAgreedToTerms.value === false)

const hasRead = ref(false)
const agreeMutation = useMutation(gql`
  mutation AgreeToTermsAgreement($logEntry: String!) {
    account {
      agreeToTerms(logEntry: $logEntry)
    }
  }
`)
const doAgree = () => {
  const userAgent = window.navigator.userAgent
  agreeMutation
    .mutate({
      logEntry: `User Agent: "${userAgent}"`,
    })
    .then(() => fetchQuery.refetch())
}
</script>

<template>
  <v-dialog :model-value="showDialog" :close-on-back="false" scrollable persistent width="650">
    <v-card>
      <v-card-title>{{ t('component.agreeToTerms.title') }}</v-card-title>
      <v-card-text>
        <div v-html="t('component.agreeToTerms.text')" />

        <checkbox
          v-model="hasRead"
          density="compact"
          class="mt-2"
          :label="t('component.agreeToTerms.labelReadCheck')"
        />
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn color="primary" :disabled="!hasRead" @click="doAgree">
          {{ t('component.agreeToTerms.buttonAgree') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style scoped lang="scss"></style>
