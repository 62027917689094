<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { computed, reactive, ref } from 'vue'
import { useMutation, useQuery } from '@vue/apollo-composable'
import {
  ArticleSearchQuery,
  ArticleType,
  ArticleViewArticleAndRevisionTakenQuery,
  ArticleViewArticleAndRevisionTakenQueryVariables,
  ArticleViewCreateConsumableMutation,
  ArticleViewCreateConsumableMutationVariables,
  ArticleViewDataQuery,
  ArticleViewDataQueryVariables,
  ArticleViewDeleteArticleMutation,
  ArticleViewDeleteArticleMutationVariables,
  ArticleViewUpdateConsumableMutation,
  ArticleViewUpdateConsumableMutationVariables,
  CreateConsumable,
  UpdateConsumable,
} from '@/generated/graphql'
import gql from 'graphql-tag'
import useVuelidate from '@vuelidate/core'
import { KeysOfType } from '@/app'
import TextField from '@/components/input/TextField.vue'
import { required } from '@/validation'
import DataTable from '@/components/dataTable/DataTable.vue'
import ItemEditIcon from '@/components/button/ButtonEdit.vue'
import ItemDeleteIcon from '@/components/button/ButtonDelete.vue'
import ArticleUnitTag from '@/components/article/ArticleUnitTag.vue'
import UnitSelect from '@/components/input/UnitSelect.vue'
import SelectField from '@/components/input/SelectField.vue'
import { helpers } from '@vuelidate/validators'
import IconArticleType from '@/components/icons/IconArticleType.vue'
import { ComponentExposed } from 'vue-component-type-helpers'
import UpsertDialog from '@/components/dialogs/UpsertDialog.vue'
import DeleteDialogNext from '@/components/dialogs/AsyncDeleteDialog.vue'
import AsyncDeleteDialog from '@/components/dialogs/AsyncDeleteDialog.vue'
import DataTableSearch from '@/components/dataTable/DataTableSearch.vue'
import DataTableCreateButton from '@/components/dataTable/DataTableCreateButton.vue'

type LocalArticle = ArticleViewDataQuery['article']['articles'][0]
type Model = Partial<CreateConsumable>
const modelKeys: KeysOfType<keyof Model> = ['id', 'articleNumber', 'revision', 'unit', 'name']
const { t } = useI18n()

const searchQuery = reactive<ArticleSearchQuery>({})
const fetchQuery = useQuery<ArticleViewDataQuery, ArticleViewDataQueryVariables>(
  gql`
    query ArticleViewData($query: ArticleSearchQuery!) {
      article {
        articles(query: $query) {
          id
          type
          articleNumber
          revision
          unit
          name
          released
        }
      }
    }
  `,
  () => ({
    query: searchQuery,
  }),
  () => ({
    debounce: 500,
  }),
)
const items = computed(() => fetchQuery.result.value?.article.articles || [])
const tableItems = computed(() => items.value.slice(0, 100))

const model = reactive<Model>({})
const articleAndRevisionTakenFetchQuery = useQuery<
  ArticleViewArticleAndRevisionTakenQuery,
  ArticleViewArticleAndRevisionTakenQueryVariables
>(
  gql`
    query ArticleViewArticleAndRevisionTaken($articleNumber: String!, $revision: String!) {
      article {
        articles(query: { articleNumber: $articleNumber, revision: $revision }) {
          id
        }
      }
    }
  `,
  () => ({
    articleNumber: model.articleNumber as string,
    revision: model.revision as string,
  }),
  () => ({
    debounce: 500,
    enabled: !!model.articleNumber && !!model.revision,
  }),
)
const articleAndRevisionTakenById = computed<string | undefined>(
  () => articleAndRevisionTakenFetchQuery.result.value?.article.articles[0]?.id,
)
const validateArticleAndRevisionTaken = helpers.withMessage(
  () => t('entity.article.validation.articleAndRevisionTaken'),
  () => {
    const id = articleAndRevisionTakenById.value
    if (!id) {
      return true
    }

    return id == model.id
  },
)
const validation = useVuelidate<Model>(
  {
    articleNumber: { required, validateArticleAndRevisionTaken },
    revision: { required, validateArticleAndRevisionTaken },
    unit: { required },
  },
  model,
)

const createMutation = useMutation<
  ArticleViewCreateConsumableMutation,
  ArticleViewCreateConsumableMutationVariables
>(gql`
  mutation ArticleViewCreateConsumable($command: CreateConsumable!) {
    article {
      createConsumable(command: $command) {
        id
      }
    }
  }
`)
const createDialog = ref<ComponentExposed<typeof UpsertDialog<() => Promise<unknown>>>>()
const doCreate = async () => {
  modelKeys.forEach((k) => {
    ;(model as Record<string, unknown>)[k] = undefined
  })
  await createDialog.value?.open(() =>
    createMutation.mutate({ command: model as CreateConsumable }).then(() => fetchQuery.refetch()),
  )
}

const updateMutation = useMutation<
  ArticleViewUpdateConsumableMutation,
  ArticleViewUpdateConsumableMutationVariables
>(gql`
  mutation ArticleViewUpdateConsumable($command: UpdateConsumable!) {
    article {
      updateConsumable(command: $command) {
        id
      }
    }
  }
`)
const updateDialog = ref<ComponentExposed<typeof UpsertDialog<() => Promise<unknown>>>>()
const doUpdate = async (site: LocalArticle) => {
  modelKeys.forEach((k) => {
    ;(model as Record<string, unknown>)[k] = site[k as keyof LocalArticle]
  })
  await updateDialog.value?.open(() =>
    updateMutation.mutate({ command: model as UpdateConsumable }).then(() => fetchQuery.refetch()),
  )
}

const deleteMutation = useMutation<
  ArticleViewDeleteArticleMutation,
  ArticleViewDeleteArticleMutationVariables
>(gql`
  mutation ArticleViewDeleteArticle($id: ID!) {
    article {
      deleteArticle(id: $id)
    }
  }
`)
const deleteDialog = ref<ComponentExposed<typeof DeleteDialogNext>>()
const doDelete = async (item: LocalArticle) => {
  const name = item.name || `${item.articleNumber} ${item.revision}`
  await deleteDialog.value?.open(name, () =>
    deleteMutation.mutate({ id: item.id }).then(() => fetchQuery.refetch()),
  )
}

const hasMoreData = computed(() => items.value.length > tableItems.value.length)
const headers = [
  { key: 'type', title: t('entity.article.field.type'), width: 50 },
  { key: 'articleNumber', title: t('entity.article.field.articleNumber'), width: 300 },
  { key: 'revision', title: t('entity.article.field.revision'), width: 100 },
  { key: 'unit', title: t('entity.article.field.unit'), width: 100 },
  { key: 'name', title: t('entity.article.field.name') },
  { key: 'actions', width: 100, sortable: false, align: 'end' },
]
const sort = [
  { key: 'articleNumber', order: 'asc' },
  { key: 'revision', order: 'asc' },
]

function cannotEditReason(item: LocalArticle): string | undefined {
  if (item.type == ArticleType.Part) {
    return t('entity.article.disableEditBecausePart')
  }
  if (item.released) {
    return t('entity.article.disableEditBecauseReleased')
  }

  return undefined
}
function cannotDeleteReason(item: LocalArticle): string | undefined {
  if (item.type == ArticleType.Part) {
    return t('entity.article.disableDeleteBecausePart')
  }
  if (item.released) {
    return t('entity.article.disableDeleteBecauseReleased')
  }

  return undefined
}
</script>
<template>
  <h1>{{ t('entity.article.plural') }}</h1>

  <data-table
    :items="tableItems"
    :headers="headers"
    :loading="fetchQuery.loading.value"
    :sort-by="sort"
    :has-more-data="hasMoreData"
    density="comfortable"
  >
    <template #item.type="{ item }">
      <icon-article-type :type="item.type" />
    </template>
    <template #item.unit="{ item }">
      <article-unit-tag :model-value="item.unit" />
    </template>
    <template #header.actions>
      <data-table-search v-model="searchQuery.containsString" :loading="fetchQuery.loading" />
      <data-table-create-button @click="doCreate" />
    </template>
    <template #item.actions="{ item }: { item: LocalArticle }">
      <item-edit-icon :disable-reason="cannotEditReason(item)" @click="doUpdate(item)" />
      <item-delete-icon :disable-reason="cannotDeleteReason(item)" @click="doDelete(item)" />
    </template>
  </data-table>

  <async-delete-dialog ref="deleteDialog" hindrance />

  <upsert-dialog ref="createDialog" type="create" :validation="validation">
    <select-field
      disabled
      :model-value="t('entity.article.type.CONSUMABLE')"
      :label="t('entity.article.field.type')"
    />
    <text-field
      v-model="model.articleNumber"
      :label="t('entity.article.field.articleNumber')"
      required
      :validation="validation.articleNumber"
    />
    <text-field
      v-model="model.revision"
      :label="t('entity.article.field.revision')"
      required
      :validation="validation.revision"
    />
    <unit-select
      v-model="model.unit"
      :label="t('entity.article.field.unit')"
      required
      :validation="validation.unit"
    />
    <text-field
      v-model="model.name"
      :label="t('entity.article.field.name')"
      :validation="validation.name"
    />
  </upsert-dialog>

  <upsert-dialog ref="updateDialog" type="update" :validation="validation">
    <text-field
      v-model="model.articleNumber"
      :label="t('entity.article.field.articleNumber')"
      required
      :validation="validation.articleNumber"
    />
    <text-field
      v-model="model.revision"
      :label="t('entity.article.field.revision')"
      required
      :validation="validation.revision"
    />
    <unit-select
      v-model="model.unit"
      :label="t('entity.article.field.unit')"
      required
      :validation="validation.unit"
    />
    <text-field
      v-model="model.name"
      :label="t('entity.article.field.name')"
      :validation="validation.name"
    />
  </upsert-dialog>
</template>

<style scoped lang="scss"></style>
