<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import Checkbox from '@/components/input/Checkbox.vue'
import { computed, ref } from 'vue'
import { useMutation, useQuery } from '@vue/apollo-composable'
import gql from 'graphql-tag'
import {
  BopReleaseConfirmationDataQuery,
  BopReleaseConfirmationDataQueryVariables,
  BopReleaseConfirmationReleaseMutation,
  BopReleaseConfirmationReleaseMutationVariables,
} from '@/generated/graphql'
import NotificationSaved from '@/components/notifications/NotificationSaved.vue'

const props = defineProps<{
  productId: string
  productConfigurationId: string
  bopId: string
  modelValue: boolean
}>()
const emit = defineEmits<{
  'update:modelValue': [value: boolean]
  release: []
}>()

const { t } = useI18n()

const fetchQuery = useQuery<
  BopReleaseConfirmationDataQuery,
  BopReleaseConfirmationDataQueryVariables
>(
  gql`
    query BopReleaseConfirmationData($productId: ID!, $productConfigurationId: ID!, $bopId: ID!) {
      product {
        product(id: $productId) {
          name
        }
        productConfiguration(id: $productConfigurationId) {
          name
        }
        bop(id: $bopId) {
          version
          site {
            id
            name
          }
        }
      }
    }
  `,
  () => ({
    productId: props.productId,
    productConfigurationId: props.productConfigurationId,
    bopId: props.bopId,
  }),
)
const data = computed(() => fetchQuery.result.value?.product)

const confirmed = ref(false)
const notifySaved = ref(false)
const mutation = useMutation<
  BopReleaseConfirmationReleaseMutation,
  BopReleaseConfirmationReleaseMutationVariables
>(gql`
  mutation BopReleaseConfirmationRelease($configId: ID!, $siteId: ID!) {
    product {
      releaseLatestBOPVersion(forProductConfigId: $configId, atSiteId: $siteId) {
        id
      }
    }
  }
`)
function release() {
  mutation
    .mutate({
      configId: props.productConfigurationId,
      siteId: data.value?.bop.site.id as string,
    })
    .then(() => {
      notifySaved.value = true
      emit('update:modelValue', false)
      emit('release')
    })
}
</script>

<template>
  <v-dialog
    :model-value="props.modelValue"
    v-bind="$attrs"
    scrollable
    @update:model-value="(v) => $emit('update:modelValue', v)"
  >
    <v-card v-if="!!data">
      <v-card-title>
        {{ t('component.bopReleaseConfirmation.title') }}
      </v-card-title>
      <v-card-text>
        <v-alert type="warning" variant="outlined">
          {{
            t('component.bopReleaseConfirmation.message', {
              productName: data.product.name,
              productConfigurationName: data.productConfiguration.name,
              siteName: data.bop.site.name,
              bopVersion: data.bop.version,
            })
          }}
        </v-alert>

        <checkbox v-model="confirmed" :label="t('component.bopReleaseConfirmation.confirmation')" />
      </v-card-text>
      <v-card-actions>
        <v-btn @click="$emit('update:modelValue', false)">
          {{ t('component.bopReleaseConfirmation.cancelButton') }}
        </v-btn>
        <v-spacer />
        <v-btn
          color="primary"
          variant="elevated"
          :disabled="!confirmed"
          :loading="mutation.loading.value"
          @click="release"
        >
          {{ t('component.bopReleaseConfirmation.releaseButton') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

  <notification-saved v-model="notifySaved" />
</template>

<style scoped lang="scss"></style>
