<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import OrganizationUserAutocomplete from '@/components/input/OrganizationUserAutocomplete.vue'
import { IssueSearchQuery } from '@/generated/graphql'
import Checkbox from '@/components/input/Checkbox.vue'
import TextField from '@/components/input/TextField.vue'
import { ref, watch } from 'vue'

const model = defineModel<IssueSearchQuery>({ required: true })

const poNumbers = ref<string>()
watch(poNumbers, (v) => {
  if (!v) {
    model.value.poNumbers = undefined
    return
  }

  model.value.poNumbers = v.split(',').map((n) => n.trim())
})

const serialNumbers = ref<string>()
watch(serialNumbers, (v) => {
  if (!v) {
    model.value.serialNumbers = undefined
    return
  }

  model.value.serialNumbers = v.split(',').map((n) => n.trim())
})

const { t } = useI18n()
</script>

<template>
  <v-card>
    <v-card-text>
      <organization-user-autocomplete
        v-model="model.assignedToUserId"
        :label="t('entity.issue.filter.assignedToUser')"
      />
      <checkbox
        v-model="model.isResolved"
        :label="t('entity.issue.filter.isResolved')"
        indeterminate
      />
      <text-field v-model="poNumbers" :label="t('entity.issue.filter.poNumbers')" />
      <text-field v-model="serialNumbers" :label="t('entity.issue.filter.serialNumbers')" />
    </v-card-text>
  </v-card>
</template>

<style scoped lang="scss"></style>
