<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { reactive, ref } from 'vue'
import UpsertDialog from '@/components/dialogs/UpsertDialog.vue'
import TextAreaField from '@/components/input/TextAreaField.vue'
import {
  CreateIssue,
  CreateIssueDialogLinkToArticleMutation,
  CreateIssueDialogLinkToArticleMutationVariables,
  CreateIssueDialogLinkToBopMutation,
  CreateIssueDialogLinkToBopMutationVariables,
  CreateIssueDialogLinkToModuleMutation,
  CreateIssueDialogLinkToModuleMutationVariables,
  CreateIssueDialogLinkToSerialNumbersMutation,
  CreateIssueDialogLinkToSerialNumbersMutationVariables,
  CreateIssueDialogLinkToStepMutation,
  CreateIssueDialogLinkToStepMutationVariables,
  CreateIssueDialogMutation,
  CreateIssueDialogMutationVariables,
  LinkIssueToArticle,
  LinkIssueToBop,
  LinkIssueToModule,
  LinkIssueToSerialNumbers,
  LinkIssueToStep,
} from '@/generated/graphql'
import useVuelidate from '@vuelidate/core'
import { useApolloClient, useMutation } from '@vue/apollo-composable'
import gql from 'graphql-tag'
import { ComponentExposed } from 'vue-component-type-helpers'
import { normalName, required } from '@/validation'
import { mockCreateIssue } from '@/generated/graphql-mocks'
import TextField from '@/components/input/TextField.vue'
import { MimeTypeFilter } from '@/app'
import MultiUploadPicker from '@/components/input/MultiUploadPicker.vue'
import OrganizationUserPicker from '@/components/input/OrganizationUserPicker.vue'
import { v4 } from 'uuid'
import { issueRefetchQueries } from '@/components/issue/issueRefetchQueries'
import FormField from '@/components/input/FormField.vue'

const emit = defineEmits<{
  created: [id: string]
}>()

export type CreateIssueSeed =
  | CreateBopIssueSeed
  | CreateModuleIssueSeed
  | CreateStepIssueSeed
  | CreateSerialNumbersIssueSeed
  | CreateArticleIssueSeed
type CreateBopIssueSeed = { bopId: string }
type CreateModuleIssueSeed = { bopId: string; moduleId: string }
type CreateStepIssueSeed = { bopId: string; moduleId: string; stepId: string }
type CreateSerialNumbersIssueSeed = {
  bopId: string
  moduleId: string
  stepId: string
  poNumber: string
  serialNumbers: string[]
}
type CreateArticleIssueSeed = { articleId: string }

const { t } = useI18n()

type Model = Partial<CreateIssue> & { uploadIds: string[]; assignedToUserIds: [] }
const modelKeys = Object.keys(mockCreateIssue())
const model = reactive<Model>({ uploadIds: [], assignedToUserIds: [] })
const validation = useVuelidate<Model>(
  {
    name: { required, normalName },
    description: { required },
  },
  model,
)
const createMutation = useMutation<CreateIssueDialogMutation, CreateIssueDialogMutationVariables>(
  gql`
    mutation CreateIssueDialog($command: CreateIssue!) {
      issue {
        createIssue(command: $command) {
          id
        }
      }
    }
  `,
)
const linkToStepMutation = useMutation<
  CreateIssueDialogLinkToStepMutation,
  CreateIssueDialogLinkToStepMutationVariables
>(gql`
  mutation CreateIssueDialogLinkToStep($command: LinkIssueToStep!) {
    issue {
      linkIssueToStep(command: $command) {
        id
      }
    }
  }
`)
const linkToModuleMutation = useMutation<
  CreateIssueDialogLinkToModuleMutation,
  CreateIssueDialogLinkToModuleMutationVariables
>(gql`
  mutation CreateIssueDialogLinkToModule($command: LinkIssueToModule!) {
    issue {
      linkIssueToModule(command: $command) {
        id
      }
    }
  }
`)
const linkToBOPMutation = useMutation<
  CreateIssueDialogLinkToBopMutation,
  CreateIssueDialogLinkToBopMutationVariables
>(gql`
  mutation CreateIssueDialogLinkToBOP($command: LinkIssueToBOP!) {
    issue {
      linkIssueToBOP(command: $command) {
        id
      }
    }
  }
`)
const linkToSerialNumbersMutation = useMutation<
  CreateIssueDialogLinkToSerialNumbersMutation,
  CreateIssueDialogLinkToSerialNumbersMutationVariables
>(gql`
  mutation CreateIssueDialogLinkToSerialNumbers($command: LinkIssueToSerialNumbers!) {
    issue {
      linkIssueToSerialNumbers(command: $command) {
        id
      }
    }
  }
`)
const linkToArticleMutation = useMutation<
  CreateIssueDialogLinkToArticleMutation,
  CreateIssueDialogLinkToArticleMutationVariables
>(gql`
  mutation CreateIssueDialogLinkToArticle($command: LinkIssueToArticle!) {
    issue {
      linkIssueToArticle(command: $command) {
        id
      }
    }
  }
`)

const seed = ref<CreateIssueSeed & { issueId: string }>()
const poNumber = ref<string>()
const serialNumbers = ref<string[]>([])
const getSerialNumberIncluded = (serialNumber: string) => {
  const values = (seed.value as CreateSerialNumbersIssueSeed).serialNumbers || []
  return values.includes(serialNumber)
}
const setSerialNumberIncluded = (serialNumber: string, value: boolean) => {
  const s = seed.value as CreateSerialNumbersIssueSeed
  if (!value) {
    s.serialNumbers = s.serialNumbers.filter((i) => i != serialNumber)
    if (s.serialNumbers.length == 0) {
      // So we transform it to a step seed
      delete (s as Record<string, unknown>).poNumber
      delete (s as Record<string, unknown>).serialNumbers
    }
    return
  }

  if (!(seed.value as CreateSerialNumbersIssueSeed).serialNumbers) {
    s.poNumber = poNumber.value as string
    s.serialNumbers = [serialNumber]
    return
  }

  s.serialNumbers.push(serialNumber)
}

const dialog = ref<ComponentExposed<typeof UpsertDialog<() => Promise<unknown>>>>()
const apollo = useApolloClient()
const open = async (initSeed?: CreateIssueSeed): Promise<void> => {
  if (!dialog.value) {
    return
  }

  modelKeys.forEach((k) => {
    ;(model as Record<string, unknown>)[k] = undefined
  })
  const id = v4()
  model.id = id
  model.uploadIds = []
  model.assignedToUserIds = []

  if (initSeed) {
    seed.value = {
      ...initSeed,
      issueId: id,
    }
    poNumber.value = (initSeed as CreateSerialNumbersIssueSeed).poNumber
    serialNumbers.value = (initSeed as CreateSerialNumbersIssueSeed).serialNumbers || []
  }

  const mutation = () =>
    createMutation
      .mutate({ command: model as CreateIssue })
      .then(async () => {
        if (!seed.value) {
          return
        }

        const command = seed.value
        if ((command as CreateArticleIssueSeed).articleId) {
          return await linkToArticleMutation.mutate({ command: command as LinkIssueToArticle })
        }
        if ((command as CreateSerialNumbersIssueSeed).poNumber) {
          return await linkToSerialNumbersMutation.mutate({
            command: command as LinkIssueToSerialNumbers,
          })
        }
        if ((command as CreateStepIssueSeed).stepId) {
          return await linkToStepMutation.mutate({ command: command as LinkIssueToStep })
        }
        if ((command as CreateModuleIssueSeed).moduleId) {
          return await linkToModuleMutation.mutate({ command: command as LinkIssueToModule })
        }
        if ((command as CreateBopIssueSeed).bopId) {
          return await linkToBOPMutation.mutate({ command: command as LinkIssueToBop })
        }
      })
      .then(() => {
        apollo.client.refetchQueries({ include: issueRefetchQueries })
        emit('created', id)
      })
  await dialog.value?.open(mutation)
}

defineExpose({ open })
</script>

<template>
  <upsert-dialog ref="dialog" type="create" :validation="validation">
    <template #title>
      {{ t('component.createIssueDialog.title') }}
    </template>
    <text-field
      v-model="model.name"
      :label="t('entity.issue.field.name')"
      required
      :validation="validation.name"
    />
    <text-area-field
      v-model="model.description"
      :label="t('entity.issue.field.description')"
      :validation="validation.description"
      required
      markdown
    />
    <form-field
      v-if="serialNumbers.length > 0"
      :label="t('component.createIssueDialog.linkToSerialNumbers')"
    >
      <v-checkbox
        v-for="(s, i) in serialNumbers"
        :key="i"
        :label="s"
        :model-value="getSerialNumberIncluded(s)"
        hide-details
        @update:model-value="(v) => setSerialNumberIncluded(s, v)"
      />
    </form-field>
    <organization-user-picker
      v-model="model.assignedToUserIds"
      :label="t('entity.issue.field.assignedToUsers')"
    />
    <multi-upload-picker
      v-model="model.uploadIds"
      :mime-types="[MimeTypeFilter.ALL]"
      :label="t('entity.issue.field.uploads')"
    />
  </upsert-dialog>
</template>

<style scoped lang="scss"></style>
