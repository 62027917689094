<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { useMutation, useQuery } from '@vue/apollo-composable'
import gql from 'graphql-tag'
import { computed, ref } from 'vue'
import InlineBreadcrumb from '@/components/InlineBreadcrumb.vue'
import ButtonDelete from '@/components/button/ButtonDelete.vue'
import AsyncDeleteDialog from '@/components/dialogs/AsyncDeleteDialog.vue'
import DeleteDialogNext from '@/components/dialogs/AsyncDeleteDialog.vue'
import {
  IssueLink,
  IssueLinkEditListDataQuery,
  IssueLinkEditListDataQueryVariables,
  SiteViewDeleteMutation,
  SiteViewDeleteMutationVariables,
} from '@/generated/graphql'
import { ComponentExposed } from 'vue-component-type-helpers'
import { issueLinkDisplayDetailsFragments } from '@/components/issue/issueFragments'
import CreateIssueLinkDialog from '@/components/issue/CreateIssueLinkDialog.vue'
import { issueRefetchQueries } from '@/components/issue/issueRefetchQueries'

const props = defineProps<{
  issueId: string
  createButtonContainer: string | HTMLElement
}>()

const { t } = useI18n()

type LocalLink = IssueLinkEditListDataQuery['issue']['issue']['links'][0]
const fetchQuery = useQuery<IssueLinkEditListDataQuery, IssueLinkEditListDataQueryVariables>(
  gql`
    query IssueLinkEditListData($issueId: ID!) {
      issue {
        issue(id: $issueId) {
          id
          links {
            ...IssueLinkDisplayDetails
          }
        }
      }
    }

    ${issueLinkDisplayDetailsFragments}
  `,
  () => ({
    issueId: props.issueId,
  }),
)
const links = computed(() => fetchQuery.result.value?.issue.issue.links || [])

const createDialog = ref<ComponentExposed<typeof CreateIssueLinkDialog>>()
const doCreate = (type: IssueLink['__typename']) => {
  createDialog.value?.open(type, props.issueId).then(() => fetchQuery.refetch())
}

const deleteMutation = useMutation<SiteViewDeleteMutation, SiteViewDeleteMutationVariables>(
  gql`
    mutation IssueLinkEditListDelete($id: ID!) {
      issue {
        deleteIssueLink(id: $id)
      }
    }
  `,
  { refetchQueries: issueRefetchQueries },
)
const deleteDialog = ref<ComponentExposed<typeof DeleteDialogNext>>()
const doDelete = async (item: LocalLink) => {
  await deleteDialog.value?.open(t('entity.issueLink.singular').toLowerCase(), () =>
    deleteMutation.mutate({ id: item.id }).then(() => fetchQuery.refetch()),
  )
}
</script>

<template>
  <v-menu>
    <template #activator="{ props }">
      <slot name="header" v-bind="props" />
    </template>

    <v-list>
      <v-list-item @click="doCreate('BOPIssueLink')">
        {{ t('entity.issueLink.type.bop') }}
      </v-list-item>
      <v-list-item @click="doCreate('StepIssueLink')">
        {{ t('entity.issueLink.type.step') }}
      </v-list-item>
      <v-list-item @click="doCreate('SerialNumberIssueLink')">
        {{ t('entity.issueLink.type.serialNumber') }}
      </v-list-item>
      <v-list-item @click="doCreate('ArticleIssueLink')">
        {{ t('entity.issueLink.type.article') }}
      </v-list-item>
    </v-list>
  </v-menu>

  <create-issue-link-dialog ref="createDialog" />

  <v-list density="compact" v-bind="$attrs" variant="elevated">
    <v-list-item v-for="l in links" :id="l.id">
      <inline-breadcrumb :breadcrumb="l" />

      <template #append>
        <button-delete @click="doDelete(l)" />
      </template>
    </v-list-item>
  </v-list>

  <async-delete-dialog ref="deleteDialog" />
</template>

<style scoped lang="scss"></style>
