<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { computed, reactive, ref } from 'vue'
import { useMutation, useQuery } from '@vue/apollo-composable'
import {
  CreateSite,
  SitesViewDataQuery,
  SitesViewDataQueryVariables,
  SiteViewCreateMutation,
  SiteViewCreateMutationVariables,
  SiteViewDeleteMutation,
  SiteViewDeleteMutationVariables,
  SiteViewUpdateMutation,
  SiteViewUpdateMutationVariables,
  UpdateSite,
} from '@/generated/graphql'
import gql from 'graphql-tag'
import useVuelidate from '@vuelidate/core'
import TextField from '@/components/input/TextField.vue'
import { abbreviation, normalName, required, unique } from '@/validation'
import ItemEditIcon from '@/components/button/ButtonEdit.vue'
import DeleteDialogNext from '@/components/dialogs/AsyncDeleteDialog.vue'
import AsyncDeleteDialog from '@/components/dialogs/AsyncDeleteDialog.vue'
import { ComponentExposed } from 'vue-component-type-helpers'
import ItemDeleteIcon from '@/components/button/ButtonDelete.vue'
import UpsertDialog from '@/components/dialogs/UpsertDialog.vue'
import DataTable from '@/components/dataTable/DataTable.vue'
import DataTableSearch from '@/components/dataTable/DataTableSearch.vue'
import DataTableCreateButton from '@/components/dataTable/DataTableCreateButton.vue'
import IconArchived from '@/components/icons/IconArchived.vue'
import Checkbox from '@/components/input/Checkbox.vue'
import { mockCreateSite, mockUpdateSite } from '@/generated/graphql-mocks'

type LocalSite = SitesViewDataQuery['site']['sites'][0]

const { t } = useI18n()

const fetchQuery = useQuery<SitesViewDataQuery, SitesViewDataQueryVariables>(gql`
  query SitesViewData {
    site {
      sites {
        id
        name
        abbreviation
        archived
      }
    }
  }
`)
const items = computed(() => fetchQuery.result.value?.site.sites || [])

type CreateModel = Partial<CreateSite>
const createModelKeys = Object.keys(mockCreateSite())
const createModel = reactive<CreateModel>({})
const createTakenAbbreviations = computed(() => items.value.map((s) => s.abbreviation))
const createValidation = useVuelidate<CreateModel>(
  {
    name: { required, normalName },
    abbreviation: { required, abbreviation, unique: unique(createTakenAbbreviations) },
  },
  createModel,
)
const createMutation = useMutation<SiteViewCreateMutation, SiteViewCreateMutationVariables>(gql`
  mutation SiteViewCreate($command: CreateSite!) {
    site {
      createSite(command: $command) {
        id
      }
    }
  }
`)
const createDialog = ref<ComponentExposed<typeof UpsertDialog<() => Promise<unknown>>>>()
const doCreate = async () => {
  createModelKeys.forEach((k) => {
    ;(createModel as Record<string, unknown>)[k] = undefined
  })
  await createDialog.value?.open(() =>
    createMutation.mutate({ command: createModel as CreateSite }).then(() => fetchQuery.refetch()),
  )
}

const updateModel = reactive<UpdateModel>({})
const updateTakenAbbreviations = computed(() =>
  items.value.filter((s) => s.id != updateModel.id).map((s) => s.abbreviation),
)
const updateValidation = useVuelidate<UpdateModel>(
  {
    name: { required, normalName },
    abbreviation: { required, abbreviation, unique: unique(updateTakenAbbreviations) },
  },
  updateModel,
)

type UpdateModel = Partial<UpdateSite>
const updateModelKeys = Object.keys(mockUpdateSite())
const updateMutation = useMutation<SiteViewUpdateMutation, SiteViewUpdateMutationVariables>(gql`
  mutation SiteViewUpdate($command: UpdateSite!) {
    site {
      updateSite(command: $command) {
        id
      }
    }
  }
`)
const updateDialog = ref<ComponentExposed<typeof UpsertDialog<() => Promise<unknown>>>>()
const doUpdate = async (item: LocalSite) => {
  updateModelKeys.forEach((k) => {
    ;(updateModel as Record<string, unknown>)[k] = item[k as keyof LocalSite]
  })
  await updateDialog.value?.open(() =>
    updateMutation.mutate({ command: updateModel as UpdateSite }).then(() => fetchQuery.refetch()),
  )
}

const deleteMutation = useMutation<SiteViewDeleteMutation, SiteViewDeleteMutationVariables>(gql`
  mutation SiteViewDelete($id: ID!) {
    site {
      deleteSite(id: $id)
    }
  }
`)
const deleteDialog = ref<ComponentExposed<typeof DeleteDialogNext>>()
const doDelete = async (item: LocalSite) => {
  await deleteDialog.value?.open(item.name, () =>
    deleteMutation.mutate({ id: item.id }).then(() => fetchQuery.refetch()),
  )
}

const search = ref('')
const headers = [
  {
    key: 'name',
    title: t('entity.site.field.name'),
  },
  {
    key: 'archived',
    title: t('entity.site.field.archived'),
    width: 100,
  },
  {
    key: 'actions',
    width: 100,
    sortable: false,
    align: 'end',
  },
]
const sort = [{ key: 'name', order: 'asc' }]
</script>

<template>
  <h1>{{ t('entity.site.plural') }}</h1>

  <data-table
    :items="items"
    :headers="headers"
    :loading="fetchQuery.loading.value"
    :sort-by="sort"
    :search="search"
    density="comfortable"
  >
    <template #header.actions>
      <data-table-search v-model="search" />
      <data-table-create-button @click="doCreate" />
    </template>
    <template #item.actions="{ item }: { item: LocalSite }">
      <item-edit-icon @click="doUpdate(item)" />
      <item-delete-icon @click="doDelete(item)" />
    </template>
    <template #item.archived="{ item }: { item: LocalSite }">
      <icon-archived :is-archived="item.archived" />
    </template>
  </data-table>

  <async-delete-dialog ref="deleteDialog" :warning="t('entity.site.deleteWarning')" hindrance />

  <upsert-dialog ref="createDialog" type="create" :validation="createValidation">
    <text-field
      v-model="createModel.name"
      required
      :label="t('entity.site.field.name')"
      :validation="createValidation.name"
    />
    <text-field
      v-model="createModel.abbreviation"
      :label="t('entity.site.field.abbreviation')"
      required
      :validation="createValidation.abbreviation"
    />
  </upsert-dialog>

  <upsert-dialog ref="updateDialog" type="update" :validation="updateValidation">
    <text-field
      v-model="updateModel.name"
      required
      :label="t('entity.site.field.name')"
      :validation="updateValidation.name"
    />
    <text-field
      v-model="updateModel.abbreviation"
      :label="t('entity.site.field.abbreviation')"
      required
      :validation="updateValidation.abbreviation"
    />
    <checkbox v-model="updateModel.archived" :label="t('entity.site.field.archived')" />
  </upsert-dialog>
</template>

<style scoped lang="scss"></style>
