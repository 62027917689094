<script setup lang="ts">
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import ArticleName from '@/components/article/ArticleName.vue'

type BreadcrumbBOP = {
  bop: {
    version: number
    productConfiguration: {
      name: string
      product: {
        name: string
      }
    }
    site: {
      name: string
    }
  }
}
type BreadcrumbModule = BreadcrumbBOP & {
  module: {
    name: string
  }
}
type BreadcrumbStep = BreadcrumbModule & {
  step: {
    name: string
  }
}
type BreadcrumbSerialNumber = BreadcrumbStep & {
  poNumber: string
  serialNumber: string
}
type BreadcrumbArticle = {
  article: {
    articleNumber: string
    revision: string
  }
}

const props = defineProps<{
  breadcrumb:
    | BreadcrumbBOP
    | BreadcrumbModule
    | BreadcrumbStep
    | BreadcrumbSerialNumber
    | BreadcrumbArticle
  shortened?: boolean
}>()
const { t } = useI18n()

const bop = computed(() =>
  (props.breadcrumb as BreadcrumbBOP).bop ? (props.breadcrumb as BreadcrumbBOP) : undefined,
)
const module = computed(() =>
  (props.breadcrumb as BreadcrumbModule).module ? (props.breadcrumb as BreadcrumbModule) : undefined,
)
const step = computed(() =>
  (props.breadcrumb as BreadcrumbStep).step ? (props.breadcrumb as BreadcrumbStep) : undefined,
)
const serialNumber = computed(() =>
  (props.breadcrumb as BreadcrumbSerialNumber).serialNumber
    ? (props.breadcrumb as BreadcrumbSerialNumber)
    : undefined,
)
const article = computed(() =>
  (props.breadcrumb as BreadcrumbArticle).article ? (props.breadcrumb as BreadcrumbArticle) : undefined,
)
</script>

<template>
  <template v-if="bop">
    {{ t('entity.product.singular') }}
    <v-icon icon="navigate_next" />
    {{ bop.bop.productConfiguration.product.name }}
    <v-icon icon="navigate_next" />
    {{ bop.bop.productConfiguration.name }}
    <v-icon icon="navigate_next" />
    {{ bop.bop.site.name }}
    <v-icon icon="navigate_next" />
    {{ bop.bop.version }}
  </template>
  <template v-if="module && !props.shortened">
    <v-icon icon="navigate_next" />
    {{ module.module.name }}
  </template>
  <template v-if="step && !props.shortened">
    <v-icon icon="navigate_next" />
    {{ step.step.name }}
  </template>
  <template v-if="serialNumber && !props.shortened">
    <v-icon icon="navigate_next" />
    {{ serialNumber.poNumber }} - {{ serialNumber.serialNumber }}
  </template>
  <template v-if="article">
    {{ t('entity.article.singular') }}
    <v-icon icon="navigate_next" />
    <article-name :article="article.article" />
  </template>
</template>

<style scoped lang="scss"></style>
