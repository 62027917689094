<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { computed, reactive } from 'vue'
import { useQuery } from '@vue/apollo-composable'
import {
  ProductionBatchQuery,
  ProductionBatchViewDataQuery,
  ProductionBatchViewDataQueryVariables,
} from '@/generated/graphql'
import gql from 'graphql-tag'
import IconChecked from '@/components/icons/IconChecked.vue'
import InlineBreadcrumb from '@/components/InlineBreadcrumb.vue'
import DataTableServer from '@/components/dataTable/DataTableServer.vue'
import Checkbox from '@/components/input/Checkbox.vue'
import TextField from '@/components/input/TextField.vue'
import ViewProductionBatchDialog from '@/components/productionBatch/ViewProductionBatchDialog.vue'
import { useRoute, useRouter } from 'vue-router'

type LocalProductionBatch = ProductionBatchViewDataQuery['shopFloor']['productionBatches'][0]
const { t } = useI18n()

const searchQuery = reactive<ProductionBatchQuery>({
  page: 0,
  itemsPerPage: 10,
})
const fetchQuery = useQuery<ProductionBatchViewDataQuery, ProductionBatchViewDataQueryVariables>(
  gql`
    query ProductionBatchViewData($query: ProductionBatchQuery!) {
      shopFloor {
        productionBatches(query: $query) {
          page
          itemsPerPage
          totalItems
          totalPages
          data {
            id
            poNumber
            serialNumbers
            completed
            module {
              id
              name
            }
            bop {
              id
              version
              productConfiguration {
                id
                name
                product {
                  id
                  name
                }
              }
              site {
                id
                name
              }
            }
          }
        }
      }
    }
  `,
  () => ({
    query: searchQuery,
  }),
  () => ({
    debounce: 300,
  }),
)
const data = computed(() => fetchQuery.result.value?.shopFloor.productionBatches)
const items = computed(() => data.value?.data || [])

const router = useRouter()
const route = useRoute()
const selectedId = computed({
  get() {
    return route.params.productionBatchId
  },
  set(val?: string) {
    router.push({ name: 'production-batches', params: { productionBatchId: val } })
  },
})

const selectBatch = (_: unknown, row: { item: LocalProductionBatch }) => {
  selectedId.value = row.item.id
}

const doUpdatePage = (page: number) => {
  searchQuery.page = page - 1
}

const headers = [
  {
    key: 'poNumber',
    title: t('entity.productionBatch.field.poNumber'),
    width: 100,
    sortable: false,
  },
  {
    key: 'serialNumbers',
    title: t('entity.productionBatch.field.serialNumbers'),
    width: 100,
    sortable: false,
  },
  {
    key: 'completed',
    title: t('entity.productionBatch.field.completed'),
    width: 25,
    sortable: false,
  },
  { key: 'module', title: t('entity.module.singular'), width: 250, sortable: false },
  { key: 'actions', width: 25, sortable: false, align: 'end' },
]
</script>
<template>
  <h1>{{ t('entity.productionBatch.plural') }}</h1>

  <v-row>
    <v-col cols="3">
      <v-card>
        <v-card-text>
          <text-field
            v-model="searchQuery.poNumberContains"
            :label="t('entity.productionBatch.filter.poNumber')"
          />

          <text-field
            v-model="searchQuery.serialNumberContains"
            :label="t('entity.productionBatch.filter.serialNumbers')"
          />
          <checkbox
            v-model="searchQuery.completed"
            :label="t('entity.productionBatch.filter.completed')"
            indeterminate
          />
        </v-card-text>
      </v-card>
    </v-col>
    <v-col>
      <data-table-server
        v-model:items-per-page="searchQuery.itemsPerPage"
        :items="items"
        :headers="headers"
        :loading="fetchQuery.loading"
        :items-length="data?.totalItems || 0"
        density="comfortable"
        disable-sort
        @click:row="selectBatch"
        @update:page="doUpdatePage"
      >
        <template #item.module="{ item }: { item: LocalProductionBatch }">
          <inline-breadcrumb :breadcrumb="item" />
        </template>
        <template #item.completed="{ item }: { item: LocalProductionBatch }">
          <icon-checked :value="item.completed" />
        </template>
        <template #item.serialNumbers="{ item }: { item: LocalProductionBatch }">
          {{ item.serialNumbers.join(',') }}
        </template>
        <template #item.actions="{ item }: { item: LocalProductionBatch }"> </template>
      </data-table-server>
    </v-col>
  </v-row>

  <view-production-batch-dialog v-model="selectedId" />
</template>

<style scoped lang="scss"></style>
