<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { computed, reactive, ref } from 'vue'
import {
  CsvConfig,
  CsvEbomLoaderGetCsvColumnNamesQuery,
  CsvEbomLoaderGetCsvColumnNamesQueryVariables,
  CsvEbomLoaderLoadEbomMutation,
  CsvEbomLoaderLoadEbomMutationVariables,
  CsvEncoding,
} from '@/generated/graphql'
import TextField from '@/components/input/TextField.vue'
import useVuelidate from '@vuelidate/core'
import { normalName, required } from '@/validation'
import { useMutation, useQuery } from '@vue/apollo-composable'
import gql from 'graphql-tag'
import SelectField, { SelectOption } from '@/components/input/SelectField.vue'

const props = defineProps<{
  bopId: string
  uploadId: string
}>()
const emit = defineEmits<{
  done: [value: string]
}>()

const { t } = useI18n()

const fetchQuery = useQuery<
  CsvEbomLoaderGetCsvColumnNamesQuery,
  CsvEbomLoaderGetCsvColumnNamesQueryVariables
>(
  gql`
    query CsvEbomLoaderGetCsvColumnNames($uploadId: ID!) {
      upload {
        upload(id: $uploadId) {
          id
          csvColumnNames
        }
      }
    }
  `,
  () => ({
    uploadId: props.uploadId,
  }),
)
const columnNames = computed<SelectOption<string>[]>(
  () =>
    fetchQuery.result.value?.upload.upload.csvColumnNames.map((c) => ({ title: c, value: c })) ||
    [],
)

type Config = Partial<CsvConfig>
const model = reactive<Config>({
  encoding: CsvEncoding.Utf_8,
})
const validation = useVuelidate<Config>(
  {
    ebomName: { required, normalName },
    encoding: { required },
    parentArticleNumberColumn: { required },
    articleNumberColumn: { required },
    nameColumn: {},
    revisionColumn: { required },
    unitColumn: {},
    quantityColumn: { required },
  },
  model,
)

type LocalLoadEbomFromCsv = CsvEbomLoaderLoadEbomMutation['article']['loadEbomFromCsv']
const mutation = useMutation<CsvEbomLoaderLoadEbomMutation, CsvEbomLoaderLoadEbomMutationVariables>(
  gql`
    mutation CsvEbomLoaderLoadEbom($command: LoadEbomFromCsvCommand!) {
      article {
        loadEbomFromCsv(command: $command) {
          error
          ebom {
            id
            ebomParts {
              id
              articleNumber
              revision
              milliQuantity
              name
              articlePath {
                articleNumbers
              }
              article {
                id
              }
            }
          }
        }
      }
    }
  `,
)
const processingError = ref<string>()
const humanReadableProcessingError = computed(() => {
  // translations
  return processingError.value
})
async function onProcess() {
  validation.value.$touch()
  if (validation.value.$invalid) {
    return
  }

  processingError.value = undefined

  await mutation
    .mutate({
      command: {
        bopId: props.bopId,
        config: model as CsvConfig,
        uploadId: props.uploadId,
      },
    })
    .then((result) => {
      const loadResult = result?.data?.article.loadEbomFromCsv as LocalLoadEbomFromCsv
      if (loadResult.error) {
        processingError.value = loadResult.error
        return
      }

      emit('done', loadResult.ebom?.id as string)
    })
    .catch(() => {
      processingError.value = 'unknown'
    })
}

const encodingItems = Object.values(CsvEncoding).map((e) => ({
  title: e.toUpperCase().replaceAll('_', '-'),
  value: e,
}))
</script>

<template>
  <h2>{{ t('component.csvEbomLoader.headerEbom') }}</h2>
  <text-field
    v-model="model.ebomName"
    required
    :label="t('component.csvEbomLoader.ebomName')"
    :validation="validation.ebomName"
  />
  <select-field
    v-model="model.encoding"
    required
    :label="t('component.csvEbomLoader.encoding')"
    :validation="validation.encoding"
    :items="encodingItems"
  />

  <h2>{{ t('component.csvEbomLoader.headerColumns') }}</h2>
  <p>{{ t('component.csvEbomLoader.instructionColumns') }}</p>
  <select-field
    v-model="model.articleNumberColumn"
    required
    :label="t('component.csvEbomLoader.columnArticleNumber')"
    :validation="validation.articleNumberColumn"
    :items="columnNames"
  />
  <select-field
    v-model="model.quantityColumn"
    :validation="validation.quantityColumn"
    required
    :label="t('component.csvEbomLoader.columnQuantity')"
    :items="columnNames"
  />
  <select-field
    v-model="model.nameColumn"
    :validation="validation.nameColumn"
    :label="t('component.csvEbomLoader.columnArticleName')"
    :items="columnNames"
  />
  <select-field
    v-model="model.revisionColumn"
    :validation="validation.revisionColumn"
    required
    :label="t('component.csvEbomLoader.columnArticleRevision')"
    :items="columnNames"
  />
  <select-field
    v-model="model.unitColumn"
    :validation="validation.unitColumn"
    :label="t('component.csvEbomLoader.columnUnit')"
    :items="columnNames"
  />
  <select-field
    v-model="model.parentArticleNumberColumn"
    :validation="validation.parentArticleNumberColumn"
    required
    :label="t('component.csvEbomLoader.columnParentArticleNumber')"
    :items="columnNames"
  />

  <v-alert v-if="!!processingError" type="error">
    {{ humanReadableProcessingError }}
  </v-alert>

  <div class="text-right mt-10">
    <v-btn
      color="primary"
      :disabled="validation.$invalid"
      :loading="mutation.loading.value"
      @click="onProcess"
    >
      {{ t('component.csvEbomLoader.buttonProcess') }}
    </v-btn>
  </div>
</template>

<style scoped lang="scss"></style>
