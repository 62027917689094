import gql from 'graphql-tag'

export const issueLinkDisplayDetailsFragments = gql`
  fragment IssueLinkDisplayDetails on IssueLink {
    ... on IssueLinkI {
      id
    }
    ... on BOPIssueLink {
      bop {
        ...IssueLinkBOPDetails
      }
    }
    ... on ModuleIssueLink {
      bop {
        ...IssueLinkBOPDetails
      }
      module {
        ...IssueLinkModuleDetails
      }
    }
    ... on StepIssueLink {
      bop {
        ...IssueLinkBOPDetails
      }
      module {
        ...IssueLinkModuleDetails
      }
      step {
        ...IssueLinkStepDetails
      }
    }
    ... on SerialNumberIssueLink {
      bop {
        ...IssueLinkBOPDetails
      }
      module {
        ...IssueLinkModuleDetails
      }
      step {
        ...IssueLinkStepDetails
      }
      poNumber
      serialNumber
    }
    ... on ArticleIssueLink {
      article {
        id
        articleNumber
        revision
      }
    }
  }
  fragment IssueLinkBOPDetails on BOP {
    id
    version
    productConfiguration {
      id
      name
      product {
        id
        name
      }
    }
    site {
      id
      name
    }
  }
  fragment IssueLinkModuleDetails on Module {
    id
    name
  }
  fragment IssueLinkStepDetails on Step {
    id
    name
  }
`

export const associatedIssueTableIssueLinkFragment = gql`
  fragment AssociatedIssueTableIssueLink on IssueLinkI {
    id
    issue {
      id
      name
      createdAt
      resolvedAt
    }
  }
`
