<script setup lang="ts" generic="T, MM">
import { BopPickerDataQuery, BopPickerDataQueryVariables } from '@/generated/graphql'
import { useQuery } from '@vue/apollo-composable'
import gql from 'graphql-tag'
import SelectField, { SelectOption } from '@/components/input/SelectField.vue'
import { computed } from 'vue'
import { itemSortByNumber } from '@/app'
import { useI18n } from 'vue-i18n'

const props = defineProps<{
  productConfigurationId: string
  siteId: string
  label: string
  required?: boolean
}>()

const { t } = useI18n()

type LocalBOP = BopPickerDataQuery['product']['productConfiguration']['bopsAtSite'][0]
const fetchQuery = useQuery<BopPickerDataQuery, BopPickerDataQueryVariables>(
  gql`
    query BopPickerData($productConfigurationId: ID!, $siteId: ID!) {
      product {
        productConfiguration(id: $productConfigurationId) {
          bopsAtSite(siteId: $siteId) {
            id
            version
            versionStatus
          }
        }
      }
    }
  `,
  () => ({
    productConfigurationId: props.productConfigurationId,
    siteId: props.siteId,
  }),
)
const options = computed<SelectOption<LocalBOP>[]>(() =>
  itemSortByNumber(
    fetchQuery.result.value?.product.productConfiguration.bopsAtSite,
    (c) => 100000 - c.version,
  ).map((b) => ({
    title: `${b.version} (${t(`component.versionStatus.status.${b.versionStatus}`)})`,
    value: b,
  })),
)
</script>

<template>
  <select-field :label="props.label" :required="props.required" :items="options" />
</template>

<style scoped lang="scss"></style>
