<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { useMutation, useQuery } from '@vue/apollo-composable'
import {
  CreateIssueComment,
  IssueCommentListCreateMutation,
  IssueCommentListCreateMutationVariables,
  IssueCommentListDeleteMutation,
  IssueCommentListDeleteMutationVariables,
  IssueCommentListQuery,
  IssueCommentListQueryVariables,
  IssueCommentListResolveMutation,
  IssueCommentListResolveMutationVariables,
  IssueCommentListUpdateMutation,
  IssueCommentListUpdateMutationVariables,
  SetIssueResolved,
  UpdateIssueComment,
} from '@/generated/graphql'
import gql from 'graphql-tag'
import { computed, reactive, ref } from 'vue'
import useVuelidate from '@vuelidate/core'
import { required } from '@/validation'
import MultiUploadPicker from '@/components/input/MultiUploadPicker.vue'
import { currentUserAndPermissions, MimeTypeFilter } from '@/app'
import TextAreaField from '@/components/input/TextAreaField.vue'
import { mockCreateIssueComment, mockUpdateIssueComment } from '@/generated/graphql-mocks'
import DateTime from '@/components/DateTime.vue'
import AsyncDeleteDialog from '@/components/dialogs/AsyncDeleteDialog.vue'
import DeleteDialogNext from '@/components/dialogs/AsyncDeleteDialog.vue'
import UpsertDialog from '@/components/dialogs/UpsertDialog.vue'
import { ComponentExposed } from 'vue-component-type-helpers'
import Checkbox from '@/components/input/Checkbox.vue'
import UploadList from '@/components/upload/UploadList.vue'
import Markdown from '@/components/Markdown.vue'

const props = defineProps<{
  issueId: string
  createButtonContainer: string | HTMLElement
}>()

const { t } = useI18n()

type LocalComment = IssueCommentListQuery['issue']['issue']['comments'][0]
const fetchQuery = useQuery<IssueCommentListQuery, IssueCommentListQueryVariables>(
  gql`
    query IssueCommentList($id: ID!) {
      issue {
        issue(id: $id) {
          comments {
            id
            createdAt
            createdByUser {
              id
              firstname
              lastname
            }
            uploadIds
            description
            resolvedAt
            updatedAt
          }
        }
      }
    }
  `,
  () => ({
    id: props.issueId,
  }),
)
const comments = computed(() => fetchQuery.result.value?.issue.issue.comments || [])

type CreateModel = Partial<CreateIssueComment> & { uploadIds: [] }
const createModelKeys = Object.keys(mockCreateIssueComment())
const createModel = reactive<CreateModel>({
  uploadIds: [],
})
const createValidation = useVuelidate<UpdateModel>({ description: { required } }, createModel)
const createMutation = useMutation<
  IssueCommentListCreateMutation,
  IssueCommentListCreateMutationVariables
>(gql`
  mutation IssueCommentListCreate($command: CreateIssueComment!) {
    issue {
      createIssueComment(command: $command) {
        id
      }
    }
  }
`)
const createDialog = ref<ComponentExposed<typeof UpsertDialog<() => Promise<unknown>>>>()
const doCreate = async () => {
  createModelKeys.forEach((k) => {
    ;(createModel as Record<string, unknown>)[k] = undefined
  })
  createModel.uploadIds = []
  createModel.issueId = props.issueId
  await createDialog.value?.open(() =>
    createMutation.mutate({ command: createModel as CreateIssueComment }).then(() => {
      fetchQuery.refetch()
    }),
  )
}

type UpdateModel = Partial<UpdateIssueComment>
const updateModelKeys = Object.keys(mockUpdateIssueComment())
const updateModel = reactive<UpdateModel>({})
const updateValidation = useVuelidate<UpdateModel>({ description: { required } }, updateModel)
const updateMutation = useMutation<
  IssueCommentListUpdateMutation,
  IssueCommentListUpdateMutationVariables
>(gql`
  mutation IssueCommentListUpdate($command: UpdateIssueComment!) {
    issue {
      updateIssueComment(command: $command) {
        id
      }
    }
  }
`)
const updateDialog = ref<ComponentExposed<typeof UpsertDialog<() => Promise<unknown>>>>()
const doUpdate = async (item: LocalComment) => {
  updateModelKeys.forEach((k) => {
    ;(updateModel as Record<string, unknown>)[k] = item[k as keyof LocalComment]
  })
  updateModel.resolved = !!item.resolvedAt
  await updateDialog.value?.open(() =>
    updateMutation.mutate({ command: updateModel as UpdateIssueComment }).then(() => {
      fetchQuery.refetch()
    }),
  )
}

const deleteMutation = useMutation<
  IssueCommentListDeleteMutation,
  IssueCommentListDeleteMutationVariables
>(gql`
  mutation IssueCommentListDelete($id: ID!) {
    issue {
      deleteIssueComment(id: $id)
    }
  }
`)
const deleteDialog = ref<ComponentExposed<typeof DeleteDialogNext>>()
const doDelete = async (item: LocalComment) => {
  await deleteDialog.value?.open(item.description, () =>
    deleteMutation.mutate({ id: item.id }).then(() => fetchQuery.refetch()),
  )
}

const resolveMutation = useMutation<
  IssueCommentListResolveMutation,
  IssueCommentListResolveMutationVariables
>(gql`
  mutation IssueCommentListResolve($command: SetIssueResolved!) {
    issue {
      setIssueCommentResolved(command: $command) {
        id
      }
    }
  }
`)
const doResolve = (command: SetIssueResolved) => {
  resolveMutation.mutate({ command }).then(() => {
    fetchQuery.refetch()
  })
}

const currentUserId = computed(() => currentUserAndPermissions.value?.user.id)
</script>

<template>
  <slot name="header" @click="doCreate" />

  <v-list variant="elevated" v-bind="$attrs">
    <v-list-item v-for="c in comments" :key="c.id" class="mb-1">
      <template #prepend>
        <v-icon
          :icon="!!c.resolvedAt ? 'check' : 'error_outline'"
          :color="!!c.resolvedAt ? 'success' : 'error'"
          size="30"
        />
      </template>

      <markdown :text="c.description" />

      <div v-if="c.uploadIds.length > 0" class="mt-5 mb-3">
        <h4>{{ t('entity.issueComment.field.uploads') }}</h4>
        <upload-list :ids="c.uploadIds" />
      </div>

      <v-list-item-action class="mt-1">
        <span class="text-grey-darken-1">
          <date-time :model-value="c.createdAt" class="mr-5" />
          {{ c.createdByUser.firstname }} {{ c.createdByUser.lastname }}
        </span>
        <v-spacer />
        <v-btn
          v-if="!c.resolvedAt"
          variant="flat"
          density="compact"
          icon="check"
          :title="t('component.issueCommentList.buttonResolve')"
          @click="doResolve({ id: c.id, resolved: true })"
        />
        <v-btn
          v-if="!!c.resolvedAt"
          variant="tonal"
          density="compact"
          icon="error_outline"
          :title="t('component.issueCommentList.buttonUnresolve')"
          @click="doResolve({ id: c.id, resolved: false })"
        />

        <template v-if="c.createdByUser.id == currentUserId" cols="3" class="text-right">
          <v-btn variant="flat" density="compact" icon="edit" @click="doUpdate(c)" />
          <v-btn variant="flat" density="compact" icon="delete" @click="doDelete(c)" />
        </template>
      </v-list-item-action>
    </v-list-item>
  </v-list>

  <async-delete-dialog ref="deleteDialog" />

  <upsert-dialog ref="createDialog" type="create" :validation="createValidation">
    <text-area-field
      v-model="createModel.description"
      :validation="createValidation.description"
      :label="t('entity.issueComment.field.description')"
      class="commentTextarea"
      markdown
    />
    <multi-upload-picker
      v-model="createModel.uploadIds"
      :label="t('entity.issueComment.field.uploads')"
      :mime-types="[MimeTypeFilter.ALL]"
    />
  </upsert-dialog>

  <upsert-dialog ref="updateDialog" type="update" :validation="updateValidation">
    <text-area-field
      v-model="updateModel.description"
      :validation="updateValidation.description"
      :label="t('entity.issueComment.field.description')"
      class="commentTextarea"
      markdown
    />
    <multi-upload-picker
      v-model="updateModel.uploadIds"
      :label="t('entity.issueComment.field.uploads')"
      :mime-types="[MimeTypeFilter.ALL]"
    />
    <checkbox v-model="updateModel.resolved" :label="t('entity.issueComment.field.resolved')" />
  </upsert-dialog>
</template>

<style lang="scss"></style>
