<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import {
  DeleteOrganizationPermissions,
  Mutation,
  OrganizationUserPermissions,
  PermissionOrganization,
  PermissionUser,
  Query,
  SetOrganizationPermissions,
  SetUserPermissions,
  User,
} from '@/generated/graphql'
import { useMutation, useQuery } from '@vue/apollo-composable'
import gql from 'graphql-tag'
import { computed, ref, unref, watch } from 'vue'
import SelectField, { SelectOption } from '@/components/input/SelectField.vue'
import NotificationSaved from '@/components/notifications/NotificationSaved.vue'

const props = defineProps<{
  modelValue?: User
}>()
defineEmits<{
  'update:modelValue': [value?: User]
}>()

const { t } = useI18n()

const fetchQuery = useQuery<Query>(
  gql`
    query getUserPermissions($id: ID!) {
      account {
        adminOrganizations {
          id
          name
        }
        adminUser(id: $id) {
          firstname
          lastname
        }
        adminUserPermissions(userId: $id)
        adminOrganizationPermissions(userId: $id) {
          organization {
            id
            name
          }
          permissions
        }
      }
    }
  `,
  () => ({ id: props.modelValue?.id }),
  () => ({ enabled: !!props.modelValue }),
)
const notifySaved = ref(false)

const user = computed<User | undefined>(() => fetchQuery.result.value?.account.adminUser)
const userPermissions = computed<PermissionUser[]>(
  () => fetchQuery.result.value?.account.adminUserPermissions || [],
)
const optionsPermissionUser = Object.values(PermissionUser).map((v: string) => ({
  title: v,
  value: v,
}))

const modelUserPermissions = ref<PermissionUser[]>([])
watch(userPermissions, (v) => (modelUserPermissions.value = v))

const userPermissionMutation = useMutation<Mutation, { command: SetUserPermissions }>(
  gql`
    mutation adminSetUserPermissions($command: SetUserPermissions!) {
      account {
        adminSetUserPermissions(command: $command)
      }
    }
  `,
  {
    refetchQueries: ['getCurrentUserAndPermissions'],
  },
)
function setUserPermissions() {
  userPermissionMutation
    .mutate({
      command: {
        userId: props.modelValue?.id || '',
        permissions: unref(modelUserPermissions),
      },
    })
    .then(() => (notifySaved.value = true))
}

const orgPermissions = computed<OrganizationUserPermissions[]>(
  () => fetchQuery.result.value?.account.adminOrganizationPermissions || [],
)
const userAlreadyInOrgIds = computed(() => orgPermissions.value.map((p) => p.organization.id))
const optionsPermissionOrg = Object.values(PermissionOrganization).map((v: string) => ({
  title: v,
  value: v,
}))

const modelOrgPermissions = ref<{ [key: string]: PermissionOrganization[] }>({})
watch(orgPermissions, (v) =>
  v.forEach((p) => {
    modelOrgPermissions.value[p.organization.id] = p.permissions
  }),
)

const orgPermissionMutation = useMutation<Mutation, { command: SetOrganizationPermissions }>(
  gql`
    mutation adminSetOrgPermissions($command: SetOrganizationPermissions!) {
      account {
        adminSetOrganizationPermissions(command: $command)
      }
    }
  `,
  {
    refetchQueries: ['getCurrentUserAndPermissions', 'getSwitchOrganizations'],
  },
)
function setOrgPermissions(orgId: string, permissions: PermissionOrganization[]) {
  orgPermissionMutation
    .mutate({
      command: {
        orgId,
        permissions,
        userId: props.modelValue?.id || '',
      },
    })
    .then(() => (notifySaved.value = true))
}

const organizationOptions = computed<SelectOption<string>[]>(
  () =>
    fetchQuery.result.value?.account.adminOrganizations
      .filter((o) => !userAlreadyInOrgIds.value.includes(o.id))
      .map((o) => ({
        title: o.name,
        value: o.id,
      })) || [],
)
const modelAddOrg = ref<string>()
function addOrganizationPermissions() {
  orgPermissionMutation
    .mutate({
      command: {
        permissions: [],
        userId: props.modelValue?.id || '',
        orgId: unref(modelAddOrg) || '',
      },
    })
    .then(() => {
      notifySaved.value = true
      modelAddOrg.value = undefined
      fetchQuery.refetch()
    })
}

const deleteOrgMutation = useMutation<Mutation, { command: DeleteOrganizationPermissions }>(gql`
  mutation deleteOrgUserPermissions($command: DeleteOrganizationPermissions!) {
    account {
      adminDeleteOrganizationPermissions(command: $command)
    }
  }
`)
function deleteOrg(orgId: string) {
  deleteOrgMutation
    .mutate({
      command: {
        orgId,
        userId: props.modelValue?.id || '',
      },
    })
    .then(() => {
      notifySaved.value = true
      fetchQuery.refetch()
    })
}
</script>

<template>
  <v-dialog
    max-width="650"
    :model-value="!!props.modelValue"
    scrollable
    @update:model-value="() => $emit('update:modelValue', undefined)"
  >
    <v-card>
      <v-card-title>
        {{ t('component.userPermissions.title') }} - {{ user?.firstname }} {{ user?.lastname }}
      </v-card-title>
      <v-card-text>
        <select-field
          v-model="modelUserPermissions"
          :items="optionsPermissionUser"
          :label="t('component.userPermissions.field.userPermissions')"
          multiple
          @update:model-value="setUserPermissions()"
        />

        <h3>{{ t('component.userPermissions.organizationPermissions') }}</h3>
        <select-field
          v-model="modelAddOrg"
          :label="t('component.userPermissions.addOrganization')"
          :items="organizationOptions"
        >
          <template #append>
            <v-btn
              icon="add"
              color="primary"
              :loading="orgPermissionMutation.loading.value"
              @click="addOrganizationPermissions"
            />
          </template>
        </select-field>

        <select-field
          v-for="perms in orgPermissions"
          :key="perms.organization.id"
          v-model="modelOrgPermissions[perms.organization.id]"
          :items="optionsPermissionOrg"
          :label="perms.organization.name"
          multiple
          @update:model-value="
            (v: PermissionOrganization[]) => setOrgPermissions(perms.organization.id, v)
          "
        >
          <template #append>
            <v-btn
              :disabled="modelOrgPermissions[perms.organization.id].length != 0"
              icon="delete"
              :loading="deleteOrgMutation.loading.value"
              @click="deleteOrg(perms.organization.id)"
            />
          </template>
        </select-field>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn @click="$emit('update:modelValue', undefined)"> Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

  <notification-saved v-model="notifySaved" />
</template>

<style scoped lang="scss"></style>
