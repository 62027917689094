<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { DateTime } from 'luxon'
import { computed } from 'vue'

const props = defineProps<{
  modelValue?: string
  format?: Format
}>()

const { t } = useI18n()

export type Format = 'date' | 'date-time' | 'time'
const format = computed(() => props.format || 'date-time')
const date = computed(() =>
  props.modelValue ? DateTime.fromISO(props.modelValue).toFormat('d LLL ') : '',
)
const time = computed(() =>
  props.modelValue ? DateTime.fromISO(props.modelValue).toFormat('H:mm') : '',
)
</script>

<template>
  <span>
    <template v-if="!props.modelValue"> - </template>
    <template v-if="format == 'date' || format == 'date-time'">
      {{ date }}
    </template>
    <template v-if="format == 'date-time' || format == 'time'">
      {{ time }}
    </template>
  </span>
</template>

<style scoped lang="scss"></style>
