<script setup lang="ts">
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'

const props = defineProps<{
  disableReason?: string
}>()
defineEmits<{
  click: []
}>()

const { t } = useI18n()
const disabled = computed(() => !!props.disableReason)
</script>

<template>
  <v-btn
    density="compact"
    variant="text"
    icon="delete"
    :color="disabled ? `grey` : undefined"
    :style="disabled ? 'cursor: not-allowed' : undefined"
    :title="disabled ? props.disableReason : t('button.delete')"
    @click.stop="disabled ? undefined : $emit('click')"
  />
</template>

<style scoped lang="scss"></style>
