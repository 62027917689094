<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { computed, reactive, ref, unref } from 'vue'
import { useMutation, useQuery } from '@vue/apollo-composable'
import {
  BopProcessViewDataQuery,
  BopProcessViewDataQueryVariables,
  BopProcessViewUpdateMutation,
  BopProcessViewUpdateMutationVariables,
  CreateModule,
  GraphEntityType,
  Mutation,
  SetDagGraph,
  SetDagGraphNode,
  UpdateModule,
  VersionStatus,
} from '@/generated/graphql'
import gql from 'graphql-tag'
import Breadcrumb from '@/components/Breadcrumb.vue'
import { useRoute } from 'vue-router'
import { authzIsOrgMbom, authzIsOrgProductManagement } from '@/app'
import Dag from '@/components/graphs/dag/Dag.vue'
import useVuelidate from '@vuelidate/core'
import { abbreviation, normalDescription, normalName, required, unique } from '@/validation'
import { v4 } from 'uuid'
import NotificationSaved from '@/components/notifications/NotificationSaved.vue'
import EditDialog from '@/components/dialogs/EditDialog.vue'
import TextField from '@/components/input/TextField.vue'
import TextAreaField from '@/components/input/TextAreaField.vue'
import BopNavigationDrawer from '@/components/bop/BopNavigationDrawer.vue'
import DownloadMbomDialog from '@/components/mbom/DownloadMbomDialog.vue'
import { ComponentExposed } from 'vue-component-type-helpers'
import DeleteDialogNext from '@/components/dialogs/AsyncDeleteDialog.vue'
import AsyncDeleteDialog from '@/components/dialogs/AsyncDeleteDialog.vue'
import { mockUpdateModule } from '@/generated/graphql-mocks'
import MilliQuantityField from '@/components/input/MilliQuantityField.vue'

type LocalModuleNode = BopProcessViewDataQuery['product']['bop']['nodes'][0]

type Model = Partial<UpdateModule>
const modelKeys = Object.keys(mockUpdateModule())
const { t } = useI18n()
const route = useRoute()

const productId = computed(() => route.params.productId as string)
const productConfigId = computed(() => route.params.productConfigId as string)
const siteId = computed(() => route.params.siteId as string)
const bopId = computed(() => route.params.bopId as string)
const fetchQuery = useQuery<BopProcessViewDataQuery, BopProcessViewDataQueryVariables>(
  gql`
    query BopProcessViewData($siteId: ID!, $productId: ID!, $productConfigId: ID!, $bopId: ID!) {
      site {
        site(id: $siteId) {
          id
          abbreviation
        }
      }
      product {
        product(id: $productId) {
          id
          abbreviation
        }
        productConfiguration(id: $productConfigId) {
          id
          abbreviation
        }
        bop(id: $bopId) {
          id
          versionStatus
          nodes {
            id
            parentIds
            milliQuantity
            module {
              id
              name
              abbreviation
              description
            }
          }
        }
      }
    }
  `,
  () => ({
    productId: productId.value,
    productConfigId: productConfigId.value,
    siteId: siteId.value,
    bopId: bopId.value,
  }),
)
const isEditable = computed(
  () =>
    fetchQuery.result.value?.product.bop.versionStatus != VersionStatus.Released &&
    authzIsOrgProductManagement.value,
)
const canMbom = computed(
  () =>
    fetchQuery.result.value?.product.bop.versionStatus == VersionStatus.Released &&
    authzIsOrgMbom.value,
)
const fetchedNodes = computed<LocalModuleNode[]>(
  () => fetchQuery.result.value?.product.bop.nodes || [],
)

function nodeText(node: LocalModuleNode) {
  return node.module.name
}

const nodeModel = ref<SetDagGraphNode>()
const model = reactive<Model>({})

const takenAbbreviations = computed(() =>
  fetchedNodes.value
    .map((n) => n.module)
    .filter((s) => s.id != model.id)
    .map((s) => s.abbreviation),
)
const validation = useVuelidate<Model>(
  {
    name: { required, normalName },
    abbreviation: { required, abbreviation, unique: unique(takenAbbreviations) },
    description: { normalDescription },
  },
  model,
)
function resetModel(nodeId: string, parentIds: string[]) {
  modelKeys.forEach((k) => (model[k as keyof Model] = undefined))
  model.id = v4()
  nodeModel.value = {
    id: nodeId,
    parentIds: parentIds,
    entityType: GraphEntityType.Module,
    entityId: model.id,
    milliQuantity: 1000,
  }
  validation.value.$reset()
}

const createOpen = ref(false)
function draftModule(nodeId: string, parentIds: string[]) {
  resetModel(nodeId, parentIds)
  createOpen.value = true
}

const notifySaved = ref(false)
function moduleNodesToGraphNodes(nodes: LocalModuleNode[]): SetDagGraphNode[] {
  return nodes.map((n) => moduleNodeToGraphNode(n))
}
function moduleNodeToGraphNode(node: LocalModuleNode): SetDagGraphNode {
  return {
    id: node.id,
    parentIds: node.parentIds,
    entityType: GraphEntityType.Module,
    entityId: node.module.id,
    milliQuantity: node.milliQuantity,
  }
}

const setGraphMutation = useMutation<Mutation, { graph: SetDagGraph }>(gql`
  mutation setGraph($graph: SetDAGGraph!) {
    product {
      setBillOfProcessGraph(command: $graph)
    }
  }
`)
function setGraph(nodes: LocalModuleNode[]) {
  setGraphMutation
    .mutate({
      graph: {
        entityId: bopId.value,
        nodes: moduleNodesToGraphNodes(nodes),
      },
    })
    .then(() => {
      notifySaved.value = true
      fetchQuery.refetch()
    })
}

const createMutation = useMutation<Mutation, { graph: SetDagGraph; module: CreateModule }>(gql`
  mutation createModule($module: CreateModule!, $graph: SetDAGGraph!) {
    product {
      createModule(command: $module) {
        id
      }
      setBillOfProcessGraph(command: $graph)
    }
  }
`)

function create() {
  const nodes = [...moduleNodesToGraphNodes(fetchedNodes.value), nodeModel.value as SetDagGraphNode]
  let module = { ...model } as CreateModule
  module.productId = productId.value

  createMutation
    .mutate({
      graph: {
        entityId: bopId.value,
        nodes,
      },
      module,
    })
    .then(() => {
      createOpen.value = false
      notifySaved.value = true
      fetchQuery.refetch()
    })
}

const updateOpen = ref(false)
function selectForUpdate(node: LocalModuleNode) {
  const m = node.module
  nodeModel.value = moduleNodeToGraphNode(node)
  modelKeys.forEach((k) => (model[k as keyof Model] = m[k]))
  updateOpen.value = true
  validation.value.$reset()
}
const updateMutation = useMutation<
  BopProcessViewUpdateMutation,
  BopProcessViewUpdateMutationVariables
>(gql`
  mutation BopProcessViewUpdate($module: UpdateModule!, $node: UpdateModuleNode!) {
    product {
      updateModule(command: $module) {
        id
      }
      updateModuleNode(command: $node) {
        id
      }
    }
  }
`)

function update() {
  const node = nodeModel.value as SetDagGraphNode
  updateMutation
    .mutate({
      module: unref(model) as UpdateModule,
      node: {
        id: node.id,
        milliQuantity: node.milliQuantity,
      },
    })
    .then(() => {
      updateOpen.value = false
      notifySaved.value = true
      fetchQuery.refetch()
    })
}

const deleteMutation = useMutation<Mutation, { id: string }>(gql`
  mutation deleteModule($id: ID!) {
    product {
      deleteModule(id: $id)
    }
  }
`)
const deleteDialog = ref<ComponentExposed<typeof DeleteDialogNext>>()
const doDelete = async (item: LocalModuleNode) => {
  await deleteDialog.value?.open(item.module.name, () =>
    deleteMutation.mutate({ id: item.module.id }).then(() => fetchQuery.refetch()),
  )
}

const downloadMbomOpen = ref(false)
</script>

<template>
  <breadcrumb />
  <bop-navigation-drawer :bop-id="bopId" />

  <h1>
    {{ t('entity.bop.singular') }}

    <span class="float-right text-right">
      <v-btn v-if="isEditable" color="primary" icon="add" @click="draftModule(v4(), [])" />

      <template v-if="canMbom">
        <v-btn color="primary" @click="downloadMbomOpen = true">
          {{ t('view.organization.bopProcess.downloadMbomButton') }}
        </v-btn>
        <download-mbom-dialog v-model="downloadMbomOpen" :bop-id="bopId" />
      </template>
    </span>
  </h1>

  <dag
    :nodes="fetchedNodes"
    :is-editable="unref(isEditable)"
    :node-text="nodeText"
    :create-node="draftModule"
    @nodes="setGraph"
  >
    <template #menu-items-prepend="{ node }">
      <router-link :to="{ name: 'module', params: { moduleId: node.module.id } }">
        <v-list-item>
          <v-list-item-title>
            {{ t('view.organization.bopProcess.menuView') }}
          </v-list-item-title>
        </v-list-item>
      </router-link>

      <template v-if="isEditable">
        <v-list-item @click="selectForUpdate(node)">
          <v-list-item-title>
            {{ t('view.organization.bopProcess.menuUpdateNode') }}
          </v-list-item-title>
        </v-list-item>
        <v-list-item @click="doDelete(node)">
          <v-list-item-title>
            {{ t('view.organization.bopProcess.menuDeleteNode') }}
          </v-list-item-title>
        </v-list-item>
      </template>
    </template>
  </dag>

  <edit-dialog
    v-if="!!nodeModel"
    v-model="createOpen"
    create
    :validation="validation"
    @save="create()"
  >
    <text-field
      v-model="model.name"
      :label="t('entity.module.field.name')"
      required
      :validation="validation.name"
    />
    <text-field
      v-model="model.abbreviation"
      :label="t('entity.module.field.abbreviation')"
      required
      :validation="validation.abbreviation"
    />
    <text-area-field
      v-model="model.description"
      :label="t('entity.module.field.description')"
      :validation="validation.description"
    />
    <milli-quantity-field
      v-model="nodeModel.milliQuantity"
      :label="t('entity.graphNode.field.milliQuantity')"
    />
  </edit-dialog>
  <edit-dialog v-model="updateOpen" :validation="validation" @save="update()">
    <text-field
      v-model="model.name"
      :label="t('entity.module.field.name')"
      required
      :validation="validation.name"
    />
    <text-field
      v-model="model.abbreviation"
      :label="t('entity.module.field.abbreviation')"
      required
      :validation="validation.abbreviation"
    />
    <text-area-field
      v-model="model.description"
      :label="t('entity.module.field.description')"
      :validation="validation.description"
    />
    <milli-quantity-field
      v-model="nodeModel!!.milliQuantity"
      :label="t('entity.graphNode.field.milliQuantity')"
    />
  </edit-dialog>

  <async-delete-dialog ref="deleteDialog" :warning="t('entity.module.deleteWarning')" hindrance />

  <notification-saved v-model="notifySaved" />
</template>

<style lang="scss"></style>
