<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import {
  Article,
  ManualArticleAllocationRowAllocationMutation,
  ManualArticleAllocationRowAllocationMutationVariables,
} from '@/generated/graphql'
import { reactive, ref, watch } from 'vue'
import useVuelidate from '@vuelidate/core'
import { minValue, required } from '@/validation'
import { useMutation } from '@vue/apollo-composable'
import gql from 'graphql-tag'
import { authzIsOrgPartAllocation } from '@/app'
import NotificationSaved from '@/components/notifications/NotificationSaved.vue'
import ArticleQuantityField from '@/components/input/ArticleQuantityField.vue'
import ArticlePicker from '@/components/input/ArticlePicker.vue'
import IconManual from '@/components/icons/IconManual.vue'

const props = defineProps<{
  stepId: string
}>()
const emit = defineEmits<{
  allocated: []
}>()

const { t } = useI18n()

type Model = {
  article?: Article
  milliQuantity?: number
}
const model = reactive<Model>({})
watch(
  () => props.stepId,
  () => {
    model.article = undefined
    model.milliQuantity = undefined
  },
)
const validation = useVuelidate<Model>(
  {
    article: { required },
    milliQuantity: { required, min: minValue(0) },
  },
  model,
)

const notifySaved = ref(false)
const setManualAllocationMutation = useMutation<
  ManualArticleAllocationRowAllocationMutation,
  ManualArticleAllocationRowAllocationMutationVariables
>(gql`
  mutation ManualArticleAllocationRowAllocation($command: AllocateArticleToStepCommand!) {
    article {
      allocateArticleToStep(command: $command)
    }
  }
`)

function setManualAllocation() {
  validation.value.$touch()
  if (validation.value.$invalid) {
    return
  }

  setManualAllocationMutation
    .mutate({
      command: {
        articleId: model.article?.id as string,
        stepId: props.stepId,
        milliQuantity: model.milliQuantity as number,
      },
    })
    .then(() => {
      model.article = undefined
      model.milliQuantity = undefined
      validation.value.$reset()
      notifySaved.value = true
      emit('allocated')
    })
}
</script>

<template>
  <tr v-if="authzIsOrgPartAllocation">
    <td><icon-manual /></td>
    <td colspan="2">
      <article-picker v-model="model.article" :validation="validation.part" density="compact" />
    </td>
    <td colspan="2">
      <article-quantity-field
        v-model="model.milliQuantity"
        :validation="validation.milliQuantity"
        density="compact"
        class="ma-0"
      >
        <template #append>
          <v-btn
            density="compact"
            variant="flat"
            color="primary"
            :disabled="validation.$invalid"
            :loading="setManualAllocationMutation.loading.value"
            icon="add"
            @click="setManualAllocation()"
          />
        </template>
      </article-quantity-field>
    </td>
  </tr>

  <notification-saved v-model="notifySaved" />
</template>

<style scoped lang="scss">
.form-field {
  margin-bottom: 0.3em;
}
</style>
