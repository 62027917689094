<script setup lang="ts" generic="I">
import { useI18n } from 'vue-i18n'
import { ref } from 'vue'
import useAsyncDialog from '@/components/dialogs/useAsyncDialog'
import NotificationSaved from '@/components/notifications/NotificationSaved.vue'

const { t } = useI18n()
const { openAsyncDialog, asyncDialogResolve, asyncDialogLoading, asyncDialogIsOpen } =
  useAsyncDialog<() => Promise<void>>()

const item = ref<I>()
const mutation = ref<() => Promise<unknown>>()
const open = async (initMutation: () => Promise<unknown>, initItem: I) => {
  item.value = initItem
  mutation.value = initMutation
  return openAsyncDialog()
}

const notifySaved = ref(false)
const doAccept = async () => {
  if (!mutation.value) {
    return
  }

  await mutation.value()
  notifySaved.value = true
}

defineExpose({ open })
</script>

<template>
  <v-dialog
    v-if="asyncDialogResolve"
    v-model="asyncDialogIsOpen"
    max-width="650"
    v-bind="$attrs"
    scrollable
  >
    <v-card>
      <v-card-title>
        {{ t('component.confirmDialog.title') }}

        <v-btn
          density="compact"
          icon="close"
          class="float-right"
          variant="flat"
          @click="asyncDialogResolve()"
        />
      </v-card-title>
      <v-card-text>
        <slot name="default" :item="item" />
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn @click="asyncDialogResolve()">
          {{ t('button.cancel') }}
        </v-btn>
        <v-btn
          color="primary"
          variant="elevated"
          :loading="asyncDialogLoading"
          @click="asyncDialogResolve(doAccept)"
        >
          {{ t('button.ok') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

  <notification-saved v-model="notifySaved" />
</template>

<style scoped lang="scss"></style>
