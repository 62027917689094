<script setup lang="ts" generic="T">
import { useI18n } from 'vue-i18n'
import { computed, Ref, unref } from 'vue'
import { VDataTable, VDataTableServer } from 'vuetify/components'

const props = defineProps<{
  serverPowered?: boolean
  hasMoreData?: boolean
  items: T[]
  loading?: boolean | Ref<boolean>
}>()

const { t } = useI18n()

const component = computed(() => (props.serverPowered ? VDataTableServer : VDataTable))
const isLoading = computed(() => unref(props.loading))
</script>

<template>
  <v-card>
    <component
      :is="component"
      v-bind="$attrs"
      :items="props.items"
      :loading-text="t('component.dataTable.loading')"
      :no-data-text="t('component.dataTable.noData')"
      :page-text="t('component.dataTable.page')"
      :items-per-page-text="t('component.dataTable.itemsPerPage')"
      :loading="isLoading"
    >
      <template v-for="(_, slot) of $slots" #[slot]="scope">
        <slot v-if="slot != 'footer.prepend'" :name="slot" v-bind="scope" />
      </template>
      <template v-if="hasMoreData" #footer.prepend>
        <v-alert density="compact" type="info" class="mx-5 mt-3">
          {{ t('component.dataTable.moreData', { count: items.length }) }}
        </v-alert>
      </template>
    </component>
  </v-card>
</template>

<style lang="scss">
.v-data-table__th {
  font-weight: bold !important;
}
</style>
