<script setup lang="ts" generic="T, MM">
import { Query } from '@/generated/graphql'
import { useQuery } from '@vue/apollo-composable'
import gql from 'graphql-tag'
import SelectField, { SelectOption } from '@/components/input/SelectField.vue'
import { computed } from 'vue'
import { itemSortByName } from '@/app'

const props = defineProps<{
  label: string
  required?: boolean
}>()

const fetchQuery = useQuery<Query>(gql`
  query getStepTypesForPicker {
    product {
      stepTypes {
        id
        name
        archived
      }
    }
  }
`)
const options = computed<SelectOption<string>[]>(() =>
  itemSortByName(
    fetchQuery.result.value?.product.stepTypes.filter((t) => !t.archived),
    (t) => t.name,
  ).map((t) => ({
    title: t.name,
    value: t.id,
  })),
)
</script>

<template>
  <select-field :label="props.label" :required="props.required" :items="options" />
</template>

<style scoped lang="scss"></style>
