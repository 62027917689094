
      export type PossibleTypesResultData = {
  "possibleTypes": {
    "ArticleAllocation": [
      "EbomPartAllocation",
      "ManualArticleAllocation"
    ],
    "Check": [
      "ConfirmCheck",
      "NumericCheck",
      "StringCheck"
    ],
    "CheckI": [
      "ConfirmCheck",
      "NumericCheck",
      "StringCheck"
    ],
    "DAGNode": [
      "ModuleNode"
    ],
    "IArticle": [
      "Article",
      "EbomPart"
    ],
    "IArticleAllocation": [
      "EbomPartAllocation",
      "ManualArticleAllocation"
    ],
    "IssueLink": [
      "ArticleIssueLink",
      "BOPIssueLink",
      "ModuleIssueLink",
      "SerialNumberIssueLink",
      "StepIssueLink"
    ],
    "IssueLinkI": [
      "ArticleIssueLink",
      "BOPIssueLink",
      "ModuleIssueLink",
      "SerialNumberIssueLink",
      "StepIssueLink"
    ],
    "TreeGraph": [],
    "TreeNode": [
      "StepNode"
    ]
  }
};
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "ArticleAllocation": [
      "EbomPartAllocation",
      "ManualArticleAllocation"
    ],
    "Check": [
      "ConfirmCheck",
      "NumericCheck",
      "StringCheck"
    ],
    "CheckI": [
      "ConfirmCheck",
      "NumericCheck",
      "StringCheck"
    ],
    "DAGNode": [
      "ModuleNode"
    ],
    "IArticle": [
      "Article",
      "EbomPart"
    ],
    "IArticleAllocation": [
      "EbomPartAllocation",
      "ManualArticleAllocation"
    ],
    "IssueLink": [
      "ArticleIssueLink",
      "BOPIssueLink",
      "ModuleIssueLink",
      "SerialNumberIssueLink",
      "StepIssueLink"
    ],
    "IssueLinkI": [
      "ArticleIssueLink",
      "BOPIssueLink",
      "ModuleIssueLink",
      "SerialNumberIssueLink",
      "StepIssueLink"
    ],
    "TreeGraph": [],
    "TreeNode": [
      "StepNode"
    ]
  }
};
      export default result;
    