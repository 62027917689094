<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { ref, watch } from 'vue'
import UploadPicker, { PickedUpload } from '@/components/upload/UploadPicker.vue'
import { MimeTypeFilter } from '@/app'
import CsvEbomLoader from '@/components/ebom/CsvEbomLoader.vue'
import PromoteEbomParts from '@/components/ebom/PromoteEbomParts.vue'
import EbomPartAllocationSuggestionsLoader from '@/components/ebom/EbomPartAllocationSuggestionsLoader.vue'

const props = defineProps<{
  bopId: string
}>()
const emit = defineEmits<{
  done: []
}>()

const { t } = useI18n()
const isOpen = ref(false)

enum STEP {
  PICK = 1,
  LOAD = 2,
  PARTS = 3,
  SUGGESTIONS = 4,
  DONE = 5,
}
const stepperModel = ref<STEP>(STEP.PICK)
watch(isOpen, (v) => {
  if (v) {
    stepperModel.value = STEP.PICK
  }
})
function isComplete(currentStep: STEP) {
  return stepperModel.value > currentStep
}

const uploadPickerOpen = ref(false)
const uploadPickerMimeTypesFilter = ref<MimeTypeFilter[]>([])
const uploadPicked = ref<PickedUpload>()
function onUpload(upload: PickedUpload) {
  uploadPicked.value = upload
  stepperModel.value = STEP.LOAD
}

enum TYPE {
  CSV,
}
const pickedType = ref<TYPE>()
function pickType(type: TYPE) {
  switch (type) {
    case TYPE.CSV:
      uploadPickerMimeTypesFilter.value = [MimeTypeFilter.TEXT_CSV]
      break
  }

  pickedType.value = type
  uploadPickerOpen.value = true
}

const ebomId = ref<string>()
function onLoaded(newEbomId: string) {
  ebomId.value = newEbomId
  stepperModel.value = STEP.PARTS
}

function onEbomPartsPromoted() {
  stepperModel.value = STEP.SUGGESTIONS
}

function onEbomPartAllocationSuggestionsDone() {
  stepperModel.value = STEP.DONE
}

function onDone() {
  emit('done')
  isOpen.value = false
}
</script>

<template>
  <v-btn variant="flat" color="primary" density="comfortable" @click="isOpen = true">
    {{ t('component.ebomLoader.buttonActivate') }}
  </v-btn>

  <v-stepper v-model="stepperModel">
    <v-dialog :model-value="isOpen" persistent scrollable>
      <v-card>
        <v-card-title class="stepper-title">
          <v-stepper-header>
            <v-stepper-item
              :value="STEP.PICK"
              :complete="isComplete(STEP.PICK)"
              :title="t('component.ebomLoader.titlePickEbom')"
            />
            <v-divider />
            <v-stepper-item
              :value="STEP.LOAD"
              :complete="isComplete(STEP.LOAD)"
              :title="t('component.ebomLoader.titleLoadEbom')"
            />
            <v-divider />
            <v-stepper-item
              :value="STEP.PARTS"
              :complete="isComplete(STEP.PARTS)"
              :title="t('component.ebomLoader.titlePromoteEbomParts')"
            />
            <v-divider />
            <v-stepper-item
              :value="STEP.SUGGESTIONS"
              :complete="isComplete(STEP.SUGGESTIONS)"
              :title="t('component.ebomLoader.titleEbomPartAllocationSuggestions')"
            />
            <v-divider />
            <v-stepper-item
              :value="STEP.DONE"
              :complete="isComplete(STEP.DONE)"
              :title="t('component.ebomLoader.titleDone')"
            />
          </v-stepper-header>
        </v-card-title>
        <v-card-text class="stepper-text">
          <v-stepper-window>
            <v-stepper-window-item :value="STEP.PICK">
              <v-row>
                <v-col offset="4" cols="4" class="text-center">
                  <v-btn color="primary" @click="pickType(TYPE.CSV)">
                    {{ t('component.ebomLoader.loadEbomFromCsv') }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-stepper-window-item>
            <v-stepper-window-item :value="STEP.LOAD">
              <csv-ebom-loader
                v-if="pickedType == TYPE.CSV && !!uploadPicked"
                :bop-id="props.bopId"
                :upload-id="uploadPicked.id"
                @done="onLoaded"
              />
            </v-stepper-window-item>
            <v-stepper-window-item :value="STEP.PARTS">
              <promote-ebom-parts v-if="ebomId" :ebom-id="ebomId" @done="onEbomPartsPromoted" />
            </v-stepper-window-item>
            <v-stepper-window-item :value="STEP.SUGGESTIONS">
              <ebom-part-allocation-suggestions-loader
                v-if="ebomId"
                :ebom-id="ebomId"
                @done="onEbomPartAllocationSuggestionsDone()"
              />
            </v-stepper-window-item>
            <v-stepper-window-item :value="STEP.DONE">
              <v-row>
                <v-col offset="2" cols="8" class="text-center">
                  <v-icon icon="verified" color="green" size="60" class="mt-3 mb-3" />
                  <h2>{{ t('component.ebomLoader.doneHeader') }}</h2>
                  <p>{{ t('component.ebomLoader.doneMessage') }}</p>

                  <v-btn class="mt-5" color="primary" variant="elevated" @click="onDone()">
                    {{ t('component.ebomLoader.doneButton') }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-stepper-window-item>
          </v-stepper-window>
        </v-card-text>
        <v-card-actions>
          <v-btn v-if="stepperModel != STEP.DONE" variant="flat" :disabled="false" @click="onDone">
            {{ t('component.ebomLoader.buttonCancel') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-stepper>

  <upload-picker
    v-model="uploadPickerOpen"
    :mime-types="uploadPickerMimeTypesFilter"
    pick-one
    @pick-one="onUpload"
  />
</template>

<style scoped lang="scss">
.stepper-title {
  padding: 0;
}
.stepper-text {
  padding: 0 !important;
}
</style>
