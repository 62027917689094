<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { AddTreeGraphNode, CreateStep, GraphEntityType, Mutation } from '@/generated/graphql'
import { reactive, ref } from 'vue'
import ToolPicker from '@/components/input/ToolPicker.vue'
import StepTypePicker from '@/components/input/StepTypePicker.vue'
import TextAreaField from '@/components/input/TextAreaField.vue'
import TextField from '@/components/input/TextField.vue'
import MultiImagePicker from '@/components/input/MultiImagePicker.vue'
import useVuelidate from '@vuelidate/core'
import { maxValue, minValue, normalName, required, scrollToFirstInvalidField } from '@/validation'
import { useMutation } from '@vue/apollo-composable'
import gql from 'graphql-tag'
import NotificationSaved from '@/components/notifications/NotificationSaved.vue'
import { v4 } from 'uuid'
import DurationField from '@/components/input/DurationField.vue'

type Model = Partial<CreateStep>

const props = defineProps<{
  productId: string
  moduleId: string
  siteId: string
}>()
const emit = defineEmits<{
  created: [id: string]
}>()

const { t } = useI18n()

const model = reactive<Model>({
  id: v4(),
  productId: props.productId,
  imageUploadIds: [],
  toolIds: [],
  durationInSeconds: 0,
})
const validation = useVuelidate<Model>(
  {
    name: { required, normalName },
    stepTypeId: { required },
    durationInSeconds: { max: maxValue(32767), min: minValue(0) },
  },
  model,
)

const notifySaved = ref(false)

const createMutation = useMutation<Mutation, { step: CreateStep; node: AddTreeGraphNode }>(
  gql`
    mutation createStepAndAddNodeToModuleGraph($step: CreateStep!, $node: AddTreeGraphNode!) {
      product {
        createStep(command: $step) {
          id
        }
        addNodeToModuleGraph(command: $node)
      }
    }
  `,
  { refetchQueries: ['StepTreeData'] },
)
function create() {
  validation.value.$touch()
  if (validation.value.$invalid) {
    scrollToFirstInvalidField()
    return
  }

  const stepId = model.id as string
  const node: AddTreeGraphNode = {
    entityId: props.moduleId,
    node: {
      entityType: GraphEntityType.Step,
      entityId: stepId,
      parentId: undefined,
      id: v4(),
      milliQuantity: 1000,
    },
  }

  createMutation
    .mutate({
      step: model as CreateStep,
      node,
    })
    .then(() => {
      notifySaved.value = true
      emit('created', stepId)
    })
}
</script>

<template>
  <h2>{{ t('component.createStep.title') }}</h2>

  <v-card>
    <v-card-text>
      <text-field
        v-model="model.name"
        :label="t('entity.step.field.name')"
        :validation="validation.name"
        required
      />
      <step-type-picker
        v-model="model.stepTypeId"
        :label="t('entity.step.field.stepType')"
        :validation="validation.stepTypeId"
        required
      />
      <duration-field
        v-model="model.durationInSeconds"
        :label="t('entity.step.field.durationInSeconds')"
        :validation="validation.durationInSeconds"
        required
      />
      <text-area-field
        v-model="model.instructionText"
        :label="t('entity.step.field.instructionText')"
        :validation="validation.instructionText"
        markdown
      />
      <tool-picker
        v-model="model.toolIds"
        :site-id="props.siteId"
        :label="t('entity.step.field.tools')"
      />
      <multi-image-picker
        v-if="model.imageUploadIds"
        v-model="model.imageUploadIds"
        :label="t('entity.step.field.images')"
      />
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn
        color="primary"
        variant="elevated"
        :loading="createMutation.loading.value"
        @click="create()"
      >
        {{ t('button.save') }}
      </v-btn>
    </v-card-actions>
  </v-card>

  <notification-saved v-model="notifySaved" />
</template>

<style scoped lang="scss"></style>
