<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import {
  CreateProductionBatchCommand,
  CreateProductionBatchMutation,
  CreateProductionBatchMutationVariables,
} from '@/generated/graphql'
import { currentUserAndPermissions } from '@/app'
import { reactive, ref } from 'vue'
import useVuelidate from '@vuelidate/core'
import { minLength, required } from '@/validation'
import TextField from '@/components/input/TextField.vue'
import SerialNumberField from '@/components/input/SerialNumberField.vue'
import { useMutation } from '@vue/apollo-composable'
import gql from 'graphql-tag'
import { ComponentExposed } from 'vue-component-type-helpers'
import UpsertDialog from '@/components/dialogs/UpsertDialog.vue'
import { mockCreateProductionBatchCommand } from '@/generated/graphql-mocks'
import { v4 } from 'uuid'

const emit = defineEmits<{
  created: [id: string]
}>()

const { t } = useI18n()

type Model = Partial<CreateProductionBatchCommand>
const modelKeys = Object.keys(mockCreateProductionBatchCommand())
const model = reactive<Model>({})
const validation = useVuelidate<Model>(
  {
    poNumber: { required, length: minLength(1) },
    serialNumbers: { required, length: minLength(1) },
  },
  model,
)

const createMutation = useMutation<
  CreateProductionBatchMutation,
  CreateProductionBatchMutationVariables
>(
  gql`
    mutation CreateProductionBatch($command: CreateProductionBatchCommand!) {
      shopFloor {
        createProductionBatch(command: $command) {
          id
        }
      }
    }
  `,
  {
    refetchQueries: ['UserProductionBatchesData'],
  },
)

const dialog = ref<ComponentExposed<typeof UpsertDialog>>()
const open = async (bopId: string, moduleId: string) => {
  modelKeys.forEach((k) => (model[k as keyof Model] = undefined))
  model.id = v4()
  model.bopId = bopId
  model.moduleId = moduleId
  model.assignedUserId = currentUserAndPermissions.value?.user?.id as string
  model.serialNumbers = ['']

  let batchId: string | undefined = undefined
  await dialog.value?.open(() =>
    createMutation
      .mutate({ command: model as CreateProductionBatchCommand })
      .then(() => (batchId = model.id)),
  )

  return batchId
}
defineExpose({ open })

const addSerialNumber = () => {
  model.serialNumbers?.push('')
}
const deleteSerialNumber = (index: number) => {
  if (model.serialNumbers?.length == 1) {
    return
  }
  model.serialNumbers?.splice(index, 1)
}
</script>

<template>
  <upsert-dialog ref="dialog" type="create" :validation="validation" min-height="60%">
    <text-field
      v-model="model.poNumber"
      :label="t('entity.productionBatch.field.poNumber')"
      required
      :validation="validation.poNumber"
    />

    <h3>
      {{ t('entity.productionBatch.field.serialNumbers') }}
      <v-btn
        density="compact"
        variant="tonal"
        color="primary"
        class="float-right"
        icon="add"
        @click="addSerialNumber"
      />
    </h3>
    <serial-number-field
      v-for="(s, i) in model.serialNumbers"
      :key="i"
      v-model="model.serialNumbers!![i]"
      @delete="deleteSerialNumber(i)"
    />
  </upsert-dialog>
</template>

<style scoped lang="scss"></style>
