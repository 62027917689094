<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { useQuery } from '@vue/apollo-composable'
import gql from 'graphql-tag'
import { computed, ref, watch } from 'vue'
import {
  ViewProductionBatchDataQuery,
  ViewProductionBatchDataQueryVariables,
} from '@/generated/graphql'
import IconChecked from '@/components/icons/IconChecked.vue'
import InlineBreadcrumb from '@/components/InlineBreadcrumb.vue'
import ViewProductionBatchSerialNumber from '@/components/productionBatch/ViewProductionBatchSerialNumber.vue'

const { t } = useI18n()

const model = defineModel<string>({})
const fetchQuery = useQuery<ViewProductionBatchDataQuery, ViewProductionBatchDataQueryVariables>(
  gql`
    query ViewProductionBatchData($id: ID!) {
      shopFloor {
        productionBatch(id: $id) {
          poNumber
          serialNumbers
          completed
          assignedUser {
            firstname
            lastname
          }
          module {
            id
            name
          }
          bop {
            id
            version
            site {
              id
              name
            }
            productConfiguration {
              id
              name
              product {
                id
                name
              }
            }
          }
        }
      }
    }
  `,
  () => ({
    id: model.value as string,
  }),
  () => ({
    enabled: !!model.value,
  }),
)
const data = computed(() => fetchQuery.result.value?.shopFloor.productionBatch)
const serialNumbers = computed(() => data.value?.serialNumbers || [])

const tabsModel = ref<string>()
watch(serialNumbers, (v) => {
  tabsModel.value = v[0]
})
</script>

<template>
  <v-dialog
    v-if="!!data"
    :model-value="!!model"
    width="1200"
    min-height="90%"
    scrollable
    @update:model-value="model = undefined"
  >
    <v-card>
      <v-card-title>
        {{ data.poNumber }}
        <v-btn
          icon="close"
          class="float-right"
          variant="flat"
          density="compact"
          @click="model = undefined"
        />
      </v-card-title>
      <v-card-text>
        <dl>
          <dd>{{ t('entity.module.singular') }}</dd>
          <dt><inline-breadcrumb :breadcrumb="data" /></dt>
          <dd>{{ t('entity.productionBatch.field.completed') }}</dd>
          <dt><icon-checked :value="data.completed" /></dt>
          <dd>{{ t('entity.productionBatch.field.assignedUser') }}</dd>
          <dt>{{ data.assignedUser?.firstname }} {{ data.assignedUser?.lastname }}</dt>
        </dl>

        <h2 class="mt-5">{{ t('entity.productionBatch.field.serialNumbers') }}</h2>
        <v-row>
          <v-col cols="4" md="3" xl="2">
            <v-tabs v-model="tabsModel" color="primary" direction="vertical">
              <v-tab v-for="s in data.serialNumbers" :key="s">{{ s }}</v-tab>
            </v-tabs>
          </v-col>
          <v-col>
            <v-tabs-window v-model="tabsModel">
              <v-tabs-window-item v-for="s in data.serialNumbers" :key="s">
                <view-production-batch-serial-number
                  :po-number="data.poNumber"
                  :serial-number="s"
                />
              </v-tabs-window-item>
            </v-tabs-window>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn color="primary" @click="model = undefined">
          {{ t('button.close') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style scoped lang="scss">
dd {
  font-size: 0.8em;
  margin-top: 0.5em;
  color: #888;
}
</style>
