// i18n
import { createI18n } from 'vue-i18n'
import { locales } from '@/translations/main'

export const i18n = createI18n({
  legacy: false,
  locale: 'en',
  fallbackLocale: 'en',
  messages: locales,
  warnHtmlInMessage: false,
  warnHtmlMessage: false,
})
