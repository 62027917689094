<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { computed, reactive, ref, watch } from 'vue'
import { useMutation, useQuery } from '@vue/apollo-composable'
import {
  AdminOrganizationViewCreateMutation,
  AdminOrganizationViewCreateMutationVariables,
  AdminOrganizationViewDataQuery,
  AdminOrganizationViewDataQueryVariables,
  AdminOrganizationViewUpdateMutation,
  AdminOrganizationViewUpdateMutationVariables,
  CreateOrganization,
  Mutation,
  UpdateOrganization,
} from '@/generated/graphql'
import gql from 'graphql-tag'
import useVuelidate from '@vuelidate/core'
import { KeysOfType } from '@/app'
import ItemEditIcon from '@/components/button/ButtonEdit.vue'
import ItemDeleteIcon from '@/components/button/ButtonDelete.vue'
import TextField from '@/components/input/TextField.vue'
import OrganizationSlug from '@/components/input/OrganizationSlug.vue'
import slugify from 'slugify'
import { normalName, required } from '@/validation'
import { ComponentExposed } from 'vue-component-type-helpers'
import UpsertDialog from '@/components/dialogs/UpsertDialog.vue'
import DeleteDialogNext from '@/components/dialogs/AsyncDeleteDialog.vue'
import AsyncDeleteDialog from '@/components/dialogs/AsyncDeleteDialog.vue'
import DataTable from '@/components/dataTable/DataTable.vue'
import DataTableSearch from '@/components/dataTable/DataTableSearch.vue'
import DataTableCreateButton from '@/components/dataTable/DataTableCreateButton.vue'

type LocalOrg = AdminOrganizationViewDataQuery['account']['adminOrganizations'][0]
type Model = Partial<UpdateOrganization>
const modelKeys: KeysOfType<keyof Model> = ['id', 'name', 'slug']
const { t } = useI18n()

const fetchQuery = useQuery<
  AdminOrganizationViewDataQuery,
  AdminOrganizationViewDataQueryVariables
>(gql`
  query AdminOrganizationViewData {
    account {
      adminOrganizations {
        id
        name
        slug
      }
    }
  }
`)
const items = computed(() => fetchQuery.result.value?.account.adminOrganizations || [])

const model = reactive<Model>({})
const validation = useVuelidate<Model>({ name: { required, normalName } }, model)

const shouldUpdateSlug = ref(true)
watch(
  () => [model.name, model.slug],
  () => {
    if (!shouldUpdateSlug.value) {
      return
    }

    model.slug = slugify(model.name || '', { lower: true })
  },
)

const createMutation = useMutation<
  AdminOrganizationViewCreateMutation,
  AdminOrganizationViewCreateMutationVariables
>(gql`
  mutation AdminOrganizationViewCreate($command: CreateOrganization!) {
    account {
      adminCreateOrganization(command: $command) {
        id
      }
    }
  }
`)
const createDialog = ref<ComponentExposed<typeof UpsertDialog<() => Promise<unknown>>>>()
const doCreate = async () => {
  modelKeys.forEach((k) => (model[k as keyof Model] = undefined))
  shouldUpdateSlug.value = true
  await createDialog.value?.open(() =>
    createMutation
      .mutate({ command: model as CreateOrganization })
      .then(() => fetchQuery.refetch()),
  )
}

const updateMutation = useMutation<
  AdminOrganizationViewUpdateMutation,
  AdminOrganizationViewUpdateMutationVariables
>(gql`
  mutation AdminOrganizationViewUpdate($command: UpdateOrganization!) {
    account {
      adminUpdateOrganization(command: $command) {
        id
      }
    }
  }
`)
const updateDialog = ref<ComponentExposed<typeof UpsertDialog<() => Promise<unknown>>>>()
const doUpdate = async (item: LocalOrg) => {
  modelKeys.forEach((k) => (model[k as keyof Model] = item[k]))
  shouldUpdateSlug.value = false
  await updateDialog.value?.open(() =>
    updateMutation
      .mutate({ command: model as UpdateOrganization })
      .then(() => fetchQuery.refetch()),
  )
}

const deleteMutation = useMutation<Mutation>(gql`
  mutation AdminOrganizationViewDelete($id: ID!) {
    account {
      adminDeleteOrganization(id: $id)
    }
  }
`)
const deleteDialog = ref<ComponentExposed<typeof DeleteDialogNext>>()
const doDelete = async (item: LocalOrg) => {
  await deleteDialog.value?.open(item.name, () =>
    deleteMutation.mutate({ id: item.id }).then(() => fetchQuery.refetch()),
  )
}

const search = ref('')
const headers = [
  {
    key: 'name',
    title: t('entity.organization.field.name'),
  },
  {
    key: 'slug',
    title: t('entity.organization.field.slug'),
  },
  {
    key: 'actions',
    width: '100',
    sortable: false,
    align: 'end',
  },
]
const sort = [{ key: 'name', order: 'asc' }]
</script>

<template>
  <h1>{{ t('entity.organization.plural') }}</h1>

  <data-table
    :items="items"
    :headers="headers"
    :loading="fetchQuery.loading.value"
    :sort-by="sort"
    :search="search"
    density="comfortable"
  >
    <template #header.actions>
      <data-table-search v-model="search" />
      <data-table-create-button @click="doCreate" />
    </template>
    <template #item.actions="{ item }: { item: LocalOrg }">
      <item-edit-icon @click="doUpdate(item)" />
      <item-delete-icon @click="doDelete(item)" />
    </template>
  </data-table>

  <async-delete-dialog
    ref="deleteDialog"
    :warning="t('entity.organization.deleteWarning')"
    hindrance
  />

  <upsert-dialog ref="createDialog" type="create" :validation="validation">
    <text-field
      v-model="model.name"
      :label="t('entity.organization.field.name')"
      required
      :validation="validation.name"
    />
    <organization-slug v-model="model.slug" :label="t('entity.organization.field.slug')" required />
  </upsert-dialog>

  <upsert-dialog ref="updateDialog" type="update" :validation="validation">
    <text-field
      v-model="model.name"
      :label="t('entity.organization.field.name')"
      required
      :validation="validation.name"
    />
    <organization-slug
      v-model="model.slug"
      :label="t('entity.organization.field.slug')"
      required
      :current-org-id="model.id!"
    />
  </upsert-dialog>
</template>

<style scoped lang="scss"></style>
