<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { useQuery } from '@vue/apollo-composable'
import gql from 'graphql-tag'
import { computed, ref, watch } from 'vue'
import { EbomDiffDialogDataQuery, EbomDiffDialogDataQueryVariables } from '@/generated/graphql'
import { SelectOption } from '@/components/input/SelectField.vue'
import EbomDiffViewer from '@/components/ebom/EbomDiffViewer.vue'

const props = defineProps<{
  bopId: string
  currentEbomId: string
}>()
const emit = defineEmits<{
  close: []
}>()

type LocalEbom = EbomDiffDialogDataQuery['article']['ebomsForBop'][0]

const { t } = useI18n()

const fetchQuery = useQuery<EbomDiffDialogDataQuery, EbomDiffDialogDataQueryVariables>(
  gql`
    query EbomDiffDialogData($ebomId: ID!, $bopId: ID!) {
      article {
        ebom(id: $ebomId) {
          id
          name
        }
        ebomsForBop(bopId: $bopId) {
          id
          name
          sourceType
          sourceId
        }
      }
    }
  `,
  () => ({
    ebomId: props.currentEbomId,
    bopId: props.bopId,
  }),
)
const eboms = computed<SelectOption<LocalEbom>[]>(
  () =>
    fetchQuery.result.value?.article.ebomsForBop
      .filter((e) => e.id != props.currentEbomId)
      .map((e) => ({
        title: e.name,
        value: e,
      })) || [],
)
const currentEbom = computed(() => fetchQuery.result.value?.article.ebom)
const otherEbom = ref<LocalEbom>()
const isOpen = ref(false)
watch(isOpen, (v) => {
  if (!v) {
    emit('close')
  }
})
</script>

<template>
  <v-select
    v-model="otherEbom"
    :label="t('component.ebomDiffDialog.selectLabel')"
    hide-details
    :items="eboms"
    density="compact"
  >
    <template
      #item="{ item, props }: { item: SelectOption<LocalEbom>; props: Record<string, unknown> }"
    >
      <v-list-item
        v-bind="props"
        :title="item.title"
        :subtitle="`${item.value.sourceId} (${item.value.sourceType})`"
      />
    </template>
    <template #append>
      <v-btn v-bind="$attrs" :disabled="!otherEbom" @click="isOpen = true">
        {{ t('component.ebomDiffDialog.diffButton') }}
      </v-btn>
    </template>
  </v-select>

  <v-dialog v-model="isOpen" width="100%" height="100%" scrollable>
    <v-card height="100%" density="compact">
      <v-card-title>
        {{
          t('component.ebomDiffDialog.title', {
            oldEbomName: otherEbom?.name,
            newEbomName: currentEbom?.name,
          })
        }}
        <v-btn
          variant="text"
          icon="close"
          class="float-right"
          density="compact"
          @click="isOpen = false"
        />
      </v-card-title>
      <v-card-text>
        <ebom-diff-viewer
          v-if="!!otherEbom"
          :old-ebom-id="otherEbom.id"
          :new-ebom-id="props.currentEbomId"
          :bop-id="props.bopId"
        />
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn variant="flat" color="primary" @click="isOpen = false">
          {{ t('component.ebomDiffDialog.closeButton') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style scoped lang="scss"></style>
