<script setup lang="ts" generic="MM">
import type { Validation, ValidationArgs } from '@vuelidate/core'
import { watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { scrollToFirstInvalidField } from '@/validation'

const props = defineProps<{
  modelValue: boolean
  create?: boolean
  validation?: Validation<ValidationArgs, MM>
  saveLoading?: boolean
}>()
const emit = defineEmits<{
  'update:modelValue': [value: boolean]
  save: []
  cancel: []
  open: []
}>()

const { t } = useI18n()

watch(
  () => props.modelValue,
  () => {
    if (props.modelValue) {
      emit('open')
    }
  },
  { immediate: true },
)

function save() {
  props.validation?.$touch()
  if (props.validation?.$invalid) {
    scrollToFirstInvalidField()
    return
  }

  emit('save')
}

function cancel() {
  emit('cancel')
  emit('update:modelValue', false)
  props.validation?.$reset()
}
</script>

<template>
  <v-dialog
    max-width="650"
    :model-value="props.modelValue"
    scrollable
    @update:model-value="(v) => $emit('update:modelValue', v)"
    @keydown.ctrl.enter.stop="save"
    @keydown.meta.enter.stop="save"
  >
    <v-card class="edit-dialog-content">
      <v-card-title>
        <slot name="title">
          {{ props.create ? 'Create' : 'Edit' }}
        </slot>
      </v-card-title>
      <v-card-text>
        <div class="edit-dialog-form">
          <slot name="form">
            <v-form>
              <slot name="default" />
            </v-form>
          </slot>
        </div>
      </v-card-text>
      <v-card-actions>
        <slot name="buttons-left" />
        <v-spacer />
        <v-btn @click="cancel">
          {{ t('component.editDialog.cancel') }}
        </v-btn>
        <v-btn
          color="primary"
          variant="elevated"
          :loading="props.saveLoading"
          :disabled="props.saveLoading"
          @click="save"
        >
          {{ t('component.editDialog.save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style>
.edit-dialog-form {
  min-width: 500px;
}
</style>
