<script setup lang="ts" generic="T">
import AbstractDataTable from '@/components/dataTable/AbstractDataTable.vue'

const itemsPerPageOptions = [
  { value: 10, title: '10' },
  { value: 25, title: '25' },
  { value: 50, title: '50' },
  { value: 100, title: '100' },
]
</script>

<template>
  <abstract-data-table server-powered :items-per-page-options="itemsPerPageOptions">
    <template v-for="(_, slot) of $slots" #[slot]="scope">
      <slot :name="slot" v-bind="scope" />
    </template>
  </abstract-data-table>
</template>

<style lang="scss"></style>
