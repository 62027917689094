<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { useQuery } from '@vue/apollo-composable'
import gql from 'graphql-tag'
import { computed, ref, watch } from 'vue'
import {
  UploadListDataQuery,
  UploadListDataQueryVariables,
  UploadListUrlQuery,
  UploadListUrlQueryVariables,
} from '@/generated/graphql'
import DataTable from '@/components/dataTable/DataTable.vue'
import DateTime from '@/components/DateTime.vue'

const props = defineProps<{
  ids: string[]
}>()

const { t } = useI18n()

type LocalUpload = UploadListDataQuery['upload']['uploads'][0]
const fetchQuery = useQuery<UploadListDataQuery, UploadListDataQueryVariables>(
  gql`
    query UploadListData($ids: [ID!]!) {
      upload {
        uploads(ids: $ids) {
          id
          createdAt
          name
          contentType
        }
      }
    }
  `,
  () => ({
    ids: props.ids,
  }),
)
const items = computed(() => fetchQuery.result.value?.upload.uploads || [])

const selectedId = ref<string>()
const urlQuery = useQuery<UploadListUrlQuery, UploadListUrlQueryVariables>(
  gql`
    query UploadListUrl($id: ID!) {
      upload {
        upload(id: $id) {
          id
          url
          name
        }
      }
    }
  `,
  () => ({
    id: selectedId.value as string,
  }),
  () => ({
    enabled: !!selectedId.value,
  }),
)
watch(urlQuery.result, (v) => {
  if (!v) {
    return
  }

  const link = document.createElement('a')

  link.href = v.upload.upload.url
  link.setAttribute('target', '_blank')
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
})

const onRowClick = (_: unknown, row: { item: LocalUpload }) => {
  selectedId.value = row.item.id
}

const headers = [
  {
    key: 'name',
    title: t('entity.upload.field.name'),
  },
  {
    key: 'contentType',
    title: t('entity.upload.field.contentType'),
    width: 200,
  },
  {
    key: 'createdAt',
    title: t('entity.upload.field.createdAt'),
    width: 150,
  },
]
const sort = [{ key: 'name', order: 'asc' }]
</script>

<template>
  <data-table
    :items="items"
    :headers="headers"
    :loading="fetchQuery.loading.value"
    :sort-by="sort"
    density="comfortable"
    hide-default-footer
    @click:row="onRowClick"
  >
    <template #item.createdAt="{ item }: { item: LocalUpload }">
      <date-time :model-value="item.createdAt" />
    </template>
  </data-table>
</template>

<style scoped lang="scss"></style>
