<script setup lang="ts" generic="T, MM">
import { SitePickerDataQuery, SitePickerDataQueryVariables } from '@/generated/graphql'
import { useQuery } from '@vue/apollo-composable'
import gql from 'graphql-tag'
import { computed, watch } from 'vue'
import { itemSortByName } from '@/app'
import { useI18n } from 'vue-i18n'

const props = defineProps<{
  withArchived?: boolean
}>()
const model = defineModel<string | undefined>()

const { t } = useI18n()

type LocalSite = SitePickerDataQuery['site']['sites'][0]
const fetchQuery = useQuery<SitePickerDataQuery, SitePickerDataQueryVariables>(gql`
  query SitePickerData {
    site {
      sites {
        id
        name
        archived
      }
    }
  }
`)
const sites = computed<LocalSite[]>(() =>
  itemSortByName(
    fetchQuery.result.value?.site.sites.filter((s) => !s.archived || props.withArchived),
    (c) => c.name,
  ),
)
watch(sites, (v) => {
  if (v.length == 1) {
    model.value = v[0].id
  }
})
</script>

<template>
  <v-btn-group divided>
    <v-btn v-if="sites.length == 0" color="warning" variant="tonal">
      {{ t('component.siteButtonPicker.noData') }}
    </v-btn>
    <v-btn v-for="s in sites" :key="s.id" :active="model == s.id" @click="model = s.id">
      {{ s.name }}
    </v-btn>
  </v-btn-group>
</template>

<style scoped lang="scss"></style>
