<script setup lang="ts" generic="T, MM">
import { ProductConfiguration, Query } from '@/generated/graphql'
import { useQuery } from '@vue/apollo-composable'
import gql from 'graphql-tag'
import SelectField, { SelectOption } from '@/components/input/SelectField.vue'
import { computed } from 'vue'
import { itemSortByName } from '@/app'

const props = defineProps<{
  productId: string
  label: string
  required?: boolean
}>()

const fetchQuery = useQuery<Query>(
  gql`
    query getProductConfigsForPicker($productId: ID!) {
      product {
        product(id: $productId) {
          productConfigurations {
            id
            name
          }
        }
      }
    }
  `,
  () => ({
    productId: props.productId,
  }),
)
const options = computed<SelectOption<ProductConfiguration>[]>(() =>
  itemSortByName(fetchQuery.result.value?.product.product.productConfigurations, (c) => c.name).map(
    (c) => ({
      title: c.name,
      value: c,
    }),
  ),
)
</script>

<template>
  <select-field :label="props.label" :required="props.required" :items="options" />
</template>

<style scoped lang="scss"></style>
