<script setup lang="ts" generic="MM">
import FormField from '@/components/input/FormField.vue'
import { Validation, ValidationArgs } from '@vuelidate/core'
import { ref, watch } from 'vue'
import { floor } from 'lodash'
import { secondsToHumanReadable } from '@/app'

const props = defineProps<{
  label?: string
  validation?: Validation<ValidationArgs, MM>
}>()
const emit = defineEmits<{
  blur: []
}>()
const model = defineModel<number | undefined>()

const privateVal = ref<string>('')
watch(
  model,
  (v) => {
    privateVal.value = secondsToHumanReadable(v)
  },
  { immediate: true },
)

const pattern = /^(?<days>\d+d\s*)?(?<hours>\d+h\s*)?(?<minutes>\d+m\s*)?(?<seconds>\d+s\s*)?$/i
watch(privateVal, (v) => {
  const match = v.match(pattern)
  if (!match) {
    return
  }

  let val = 0
  if (match.groups?.days) {
    val += parseInt(match.groups.days.trim()) * 86400
  }
  if (match.groups?.hours) {
    val += parseInt(match.groups.hours.trim()) * 3600
  }
  if (match.groups?.minutes) {
    val += parseInt(match.groups.minutes.trim()) * 60
  }
  if (match.groups?.seconds) {
    val += parseInt(match.groups.seconds.trim())
  }

  model.value = val
})

const onBlur = () => {
  const match = privateVal.value.match(pattern)
  if (!match) {
    model.value = undefined
    privateVal.value = ''
  }

  props.validation?.$touch()
  emit('blur')
}
</script>

<template>
  <form-field :label="props.label" :validation="props.validation">
    <v-text-field
      v-model="privateVal"
      v-bind="$attrs"
      hide-details
      style="min-width: 8em"
      placeholder="3h2m1s"
      @blur="onBlur"
    >
      <template v-for="(_, slot) of $slots" #[slot]="scope">
        <slot :name="slot" v-bind="scope" />
      </template>
    </v-text-field>
  </form-field>
</template>
