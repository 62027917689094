import markdownit from 'markdown-it'
import markdownItLinkAttributes from 'markdown-it-link-attributes'

const md = markdownit({
  breaks: true,
  html: true,
  linkify: true,
})
md.use(markdownItLinkAttributes, {
  attrs: {
    target: '_blank',
    rel: 'noopener',
  },
})

const parse = (text?: string) => (text ? md.render(text) : undefined)

export default function useMarkdown() {
  return { parseMarkdown: parse }
}
