<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { useMutation, useQuery } from '@vue/apollo-composable'
import gql from 'graphql-tag'
import {
  CheckType,
  CreateCheckConfigurationCommand,
  DeleteCheckConfigurationMutation,
  DeleteCheckConfigurationMutationVariables,
  Move,
  MoveCheckConfigurationMutation,
  MoveCheckConfigurationMutationVariables,
  Placement,
  StepCheckConfigurationsDataQuery,
  StepCheckConfigurationsDataQueryVariables,
  UpdateCheckConfigurationCommand,
  UpdateCheckConfigurationMutation,
  UpdateCheckConfigurationMutationVariables,
} from '@/generated/graphql'
import { computed, reactive, ref, watch } from 'vue'
import IconCheckType from '@/components/icons/IconCheckType.vue'
import DataTable from '@/components/dataTable/DataTable.vue'
import DataTableCreateButton from '@/components/dataTable/DataTableCreateButton.vue'
import useVuelidate from '@vuelidate/core'
import { normalDescription, required } from '@/validation'
import { ComponentExposed } from 'vue-component-type-helpers'
import UpsertDialog from '@/components/dialogs/UpsertDialog.vue'
import Checkbox from '@/components/input/Checkbox.vue'
import CheckTypePicker from '@/components/input/CheckTypePicker.vue'
import MilliQuantityField from '@/components/input/MilliQuantityField.vue'
import TextField from '@/components/input/TextField.vue'
import ItemEditIcon from '@/components/button/ButtonEdit.vue'
import AsyncDeleteDialog from '@/components/dialogs/AsyncDeleteDialog.vue'
import DeleteDialogNext from '@/components/dialogs/AsyncDeleteDialog.vue'
import ItemDeleteIcon from '@/components/button/ButtonDelete.vue'
import {
  mockCreateCheckConfigurationCommand,
  mockUpdateCheckConfigurationCommand,
} from '@/generated/graphql-mocks'
import IconChecked from '@/components/icons/IconChecked.vue'

const props = defineProps<{
  bopId: string
  moduleId: string
  stepId: string
}>()

type LocalCheckConfiguration = StepCheckConfigurationsDataQuery['product']['checkConfigurations'][0]

const { t } = useI18n()

const fetchQuery = useQuery<
  StepCheckConfigurationsDataQuery,
  StepCheckConfigurationsDataQueryVariables
>(
  gql`
    query StepCheckConfigurationsData($stepId: ID!) {
      product {
        checkConfigurations(stepId: $stepId) {
          id
          type
          isRequired
          description
          upperLimit
          lowerLimit
          order
        }
      }
    }
  `,
  () => ({
    stepId: props.stepId,
  }),
)
const configs = computed(() => fetchQuery.result.value?.product.checkConfigurations || [])

type CreateModel = Partial<CreateCheckConfigurationCommand>
const createModelKeys = Object.keys(mockCreateCheckConfigurationCommand())
const createModel = reactive<CreateModel>({})
watch(
  () => createModel.type,
  (v) => {
    if (v != CheckType.Numeric) {
      createModel.upperLimit = undefined
      createModel.lowerLimit = undefined
    }
  },
)
const createValidation = useVuelidate<CreateModel>(
  {
    type: { required },
    description: { required, normalDescription },
  },
  createModel,
)
const createMutation = useMutation(gql`
  mutation CreateCheckConfiguration($command: CreateCheckConfigurationCommand!) {
    product {
      createCheckConfiguration(command: $command) {
        id
      }
    }
  }
`)
const createDialog = ref<ComponentExposed<typeof UpsertDialog<() => Promise<unknown>>>>()
const doCreate = async (order: number) => {
  createModelKeys.forEach((k) => {
    ;(createModel as Record<string, unknown>)[k] = undefined
  })
  createModel.isRequired = false
  createModel.bopId = props.bopId
  createModel.moduleId = props.moduleId
  createModel.stepId = props.stepId
  createModel.order = order

  await createDialog.value?.open(() =>
    createMutation
      .mutate({ command: createModel as CreateCheckConfigurationCommand })
      .then(() => fetchQuery.refetch()),
  )
}

type UpdateModel = Partial<UpdateCheckConfigurationCommand>
const updateModelKeys = Object.keys(mockUpdateCheckConfigurationCommand())
const updateModel = reactive<UpdateModel>({})
const updateType = ref<CheckType>()
const updateValidation = useVuelidate<UpdateModel>(
  { description: { normalDescription } },
  updateModel,
)
const updateMutation = useMutation<
  UpdateCheckConfigurationMutation,
  UpdateCheckConfigurationMutationVariables
>(gql`
  mutation UpdateCheckConfiguration($command: UpdateCheckConfigurationCommand!) {
    product {
      updateCheckConfiguration(command: $command) {
        id
      }
    }
  }
`)
const updateDialog = ref<ComponentExposed<typeof UpsertDialog<() => Promise<unknown>>>>()
const doUpdate = async (item: LocalCheckConfiguration) => {
  updateModelKeys.forEach((k) => {
    ;(updateModel as Record<string, unknown>)[k] = item[k as keyof LocalCheckConfiguration]
  })
  updateType.value = item.type
  await updateDialog.value?.open(() =>
    updateMutation
      .mutate({ command: updateModel as UpdateCheckConfigurationCommand })
      .then(() => fetchQuery.refetch()),
  )
}

const moveMutation = useMutation<
  MoveCheckConfigurationMutation,
  MoveCheckConfigurationMutationVariables
>(gql`
  mutation MoveCheckConfiguration($stepId: ID!, $command: Move!) {
    product {
      moveCheckConfiguration(inStepId: $stepId, command: $command)
    }
  }
`)
const doMove = async (id: string, otherId: string, placement: Placement) => {
  const command: Move = {
    ids: [id],
    otherId,
    placement,
  }
  moveMutation.mutate({ command, stepId: props.stepId }).then(() => fetchQuery.refetch())
}

const deleteMutation = useMutation<
  DeleteCheckConfigurationMutation,
  DeleteCheckConfigurationMutationVariables
>(gql`
  mutation DeleteCheckConfiguration($id: ID!) {
    product {
      deleteCheckConfiguration(id: $id)
    }
  }
`)
const deleteDialog = ref<ComponentExposed<typeof DeleteDialogNext>>()
const doDelete = async (item: LocalCheckConfiguration) => {
  await deleteDialog.value?.open(item.description, () =>
    deleteMutation.mutate({ id: item.id }).then(() => fetchQuery.refetch()),
  )
}

const headers = [
  {
    key: 'type',
    title: t('entity.checkConfiguration.field.type'),
    width: 50,
  },
  {
    key: 'isRequired',
    title: t('entity.checkConfiguration.field.isRequired'),
    width: 50,
  },
  {
    key: 'description',
    title: t('entity.checkConfiguration.field.description'),
  },
  {
    key: 'actions',
    width: '200',
    sortable: false,
    align: 'end',
  },
]
const sort = [{ key: 'name', order: 'asc' }]
</script>

<template>
  <data-table
    :items="configs"
    :headers="headers"
    :loading="fetchQuery.loading"
    :sort-by="sort"
    density="comfortable"
    hide-default-footer
  >
    <template #header.actions>
      <data-table-create-button @click="doCreate(configs.length)" />
    </template>
    <template #item.actions="{ item, index }: { item: LocalCheckConfiguration; index: number }">
      <item-edit-icon @click="doUpdate(item)" />
      <item-delete-icon @click="doDelete(item)" />
      <v-btn
        :disabled="index == 0"
        density="compact"
        icon="expand_less"
        @click="doMove(item.id, configs[index - 1].id, Placement.Above)"
      />
      <v-btn
        :disabled="index >= configs.length - 1"
        density="compact"
        icon="expand_more"
        @click="doMove(item.id, configs[index + 1].id, Placement.Below)"
      />
    </template>
    <template #item.type="{ item }: { item: LocalCheckConfiguration }">
      <icon-check-type :type="item.type" />
    </template>
    <template #item.isRequired="{ item }: { item: LocalCheckConfiguration }">
      <icon-checked :value="item.isRequired" />
    </template>
  </data-table>

  <upsert-dialog ref="createDialog" type="create" :validation="createValidation">
    <check-type-picker
      v-model="createModel.type"
      required
      :validation="createValidation.type"
      :label="t('entity.checkConfiguration.field.type')"
    />
    <text-field
      v-model="createModel.description"
      required
      :validation="createValidation.description"
      :label="t('entity.checkConfiguration.field.description')"
    />
    <checkbox
      v-model="createModel.isRequired"
      :label="t('entity.checkConfiguration.field.isRequired')"
    />
    <milli-quantity-field
      v-if="createModel.type == CheckType.Numeric"
      v-model="createModel.lowerLimit"
      :label="t('entity.checkConfiguration.field.lowerLimit')"
    />
    <milli-quantity-field
      v-if="createModel.type == CheckType.Numeric"
      v-model="createModel.upperLimit"
      :label="t('entity.checkConfiguration.field.upperLimit')"
    />
  </upsert-dialog>

  <upsert-dialog ref="updateDialog" type="update" :validation="updateValidation">
    <text-field
      v-model="updateModel.description"
      :validation="updateValidation.description"
      :label="t('entity.checkConfiguration.field.description')"
    />
    <checkbox
      v-model="updateModel.isRequired"
      :label="t('entity.checkConfiguration.field.isRequired')"
    />
    <milli-quantity-field
      v-if="updateType == CheckType.Numeric"
      v-model="updateModel.lowerLimit"
      :label="t('entity.checkConfiguration.field.lowerLimit')"
    />
    <milli-quantity-field
      v-if="updateType == CheckType.Numeric"
      v-model="updateModel.upperLimit"
      :label="t('entity.checkConfiguration.field.upperLimit')"
    />
  </upsert-dialog>

  <async-delete-dialog ref="deleteDialog" />
</template>

<style scoped lang="scss"></style>
