<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { useQuery } from '@vue/apollo-composable'
import gql from 'graphql-tag'
import { computed } from 'vue'
import {
  OrganizationUserListDataQuery,
  OrganizationUserListDataQueryVariables,
} from '@/generated/graphql'
import DataTable from '@/components/dataTable/DataTable.vue'

const props = defineProps<{
  ids: string[]
}>()

const { t } = useI18n()

const fetchQuery = useQuery<OrganizationUserListDataQuery, OrganizationUserListDataQueryVariables>(
  gql`
    query OrganizationUserListData($ids: [ID!]!) {
      account {
        organizationUsers(ids: $ids) {
          id
          firstname
          lastname
        }
      }
    }
  `,
  () => ({
    ids: props.ids,
  }),
)
const items = computed(() => fetchQuery.result.value?.account.organizationUsers || [])

const headers = [
  {
    key: 'firstname',
    title: t('entity.user.field.firstname'),
  },
  {
    key: 'lastname',
    title: t('entity.user.field.lastname'),
  },
]
const sort = [
  { key: 'firstname', order: 'asc' },
  { key: 'lastname', order: 'asc' },
]
</script>

<template>
  <data-table
    :items="items"
    :headers="headers"
    :loading="fetchQuery.loading.value"
    :sort-by="sort"
    density="comfortable"
    hide-default-footer
  />
</template>

<style scoped lang="scss"></style>
