<script setup lang="ts">
import { computed, watch } from 'vue'
import { UserInfoDataQuery, UserInfoDataQueryVariables } from '@/generated/graphql'
import { useQuery } from '@vue/apollo-composable'
import gql from 'graphql-tag'
import { currentOrganization, currentUserAndPermissions } from '@/app'

const fetchQuery = useQuery<UserInfoDataQuery, UserInfoDataQueryVariables>(gql`
  query UserInfoData {
    account {
      me {
        organization {
          id
          name
          slug
        }
        user {
          id
          firstname
          lastname
          email
        }
        userPermissions
        orgPermissions
      }
    }
  }
`)
let fetchedOrganization: string | undefined
watch(currentOrganization, () => {
  if (fetchedOrganization == currentOrganization.value?.id) {
    return
  }

  fetchQuery.refetch()
  fetchedOrganization = currentOrganization.value?.id
})

const info = computed(() => fetchQuery.result.value?.account.me)
watch(info, (v) => {
  if (!v) {
    return
  }

  currentUserAndPermissions.value = v
})
</script>

<template><span /></template>

<style scoped lang="scss"></style>
