<script setup lang="ts">
import { computed } from 'vue'
import { secondsToHumanReadable } from '@/app'

const props = defineProps<{
  seconds: number
}>()

const asString = computed(() => secondsToHumanReadable(props.seconds))
</script>

<template>
  {{ asString }}
</template>

<style scoped lang="scss"></style>
