<script setup lang="ts" generic="T">
import AbstractDataTable from '@/components/dataTable/AbstractDataTable.vue'

const props = defineProps<{
  items: T[]
}>()
</script>

<template>
  <abstract-data-table :items-length="props.items.length" :items="props.items">
    <template v-for="(_, slot) of $slots" #[slot]="scope">
      <slot :name="slot" v-bind="scope" />
    </template>
  </abstract-data-table>
</template>

<style lang="scss"></style>
