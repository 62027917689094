<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import {
  EbomPartAllocationRowAllocationMutation,
  EbomPartAllocationRowAllocationMutationVariables,
  EbomPartAllocationRowDataQuery,
  EbomPartAllocationRowDataQueryVariables,
} from '@/generated/graphql'
import { computed, reactive, ref, watch } from 'vue'
import useVuelidate from '@vuelidate/core'
import { minValue, required } from '@/validation'
import { useMutation, useQuery } from '@vue/apollo-composable'
import gql from 'graphql-tag'
import { authzIsOrgPartAllocation } from '@/app'
import NotificationSaved from '@/components/notifications/NotificationSaved.vue'
import ArticleQuantityField from '@/components/input/ArticleQuantityField.vue'
import AllocateableEbomPartPicker, {
  EbomPartPickerOption,
} from '@/components/input/AllocateableEbomPartPicker.vue'
import SelectField, { SelectOption } from '@/components/input/SelectField.vue'
import IconEbom from '@/components/icons/IconEbom.vue'

const props = defineProps<{
  bopId: string
  stepId: string
}>()
const emit = defineEmits<{
  allocated: []
}>()

const { t } = useI18n()

const fetchQuery = useQuery<
  EbomPartAllocationRowDataQuery,
  EbomPartAllocationRowDataQueryVariables
>(
  gql`
    query EbomPartAllocationRowData($bopId: ID!) {
      article {
        ebomsForBop(bopId: $bopId) {
          id
          name
        }
      }
    }
  `,
  () => ({
    bopId: props.bopId,
  }),
)
const ebomOptions = computed<SelectOption<string>[]>(
  () =>
    fetchQuery.result.value?.article.ebomsForBop.map((e) => ({
      title: e.name,
      value: e.id,
    })) || [],
)

const selectedEbomId = ref<string>()
watch(ebomOptions, (v) => {
  if (v.length == 1) {
    selectedEbomId.value = v[0].value
  }
})

type Model = {
  ebomPart?: EbomPartPickerOption
  milliQuantity?: number
}
const model = reactive<Model>({})
watch(
  () => props.stepId,
  () => {
    model.ebomPart = undefined
    model.milliQuantity = undefined
  },
)
const validation = useVuelidate<Model>(
  {
    ebomPart: { required },
    milliQuantity: { required, min: minValue(0) },
  },
  model,
)

const notifySaved = ref(false)
const mutation = useMutation<
  EbomPartAllocationRowAllocationMutation,
  EbomPartAllocationRowAllocationMutationVariables
>(gql`
  mutation EbomPartAllocationRowAllocation($command: AllocateEbomPartToStepCommand!) {
    article {
      allocateEbomPartToStep(command: $command)
    }
  }
`)

function setAllocation() {
  validation.value.$touch()
  if (validation.value.$invalid) {
    return
  }

  mutation
    .mutate({
      command: {
        ebomPartId: model.ebomPart?.id as string,
        stepId: props.stepId,
        milliQuantity: model.milliQuantity as number,
      },
    })
    .then(() => {
      model.ebomPart = undefined
      model.milliQuantity = undefined
      validation.value.$reset()
      notifySaved.value = true
      emit('allocated')
    })
}
</script>

<template>
  <tr v-if="authzIsOrgPartAllocation">
    <td><icon-ebom /></td>
    <td colspan="2">
      <v-row>
        <v-col cols="6" xl="4">
          <select-field v-model="selectedEbomId" density="compact" :items="ebomOptions" />
        </v-col>
        <v-col>
          <allocateable-ebom-part-picker
            v-if="selectedEbomId"
            v-model="model.ebomPart"
            :ebom-id="selectedEbomId"
            :validation="validation.part"
            density="compact"
          />
        </v-col>
      </v-row>
    </td>
    <td colspan="2">
      <article-quantity-field
        v-model="model.milliQuantity"
        :validation="validation.milliQuantity"
        density="compact"
      >
        <template #append>
          <v-btn
            density="compact"
            variant="flat"
            color="primary"
            :disabled="validation.$invalid"
            :loading="mutation.loading.value"
            icon="add"
            @click="setAllocation()"
          />
        </template>
      </article-quantity-field>
    </td>
  </tr>

  <notification-saved v-model="notifySaved" />
</template>

<style scoped lang="scss">
.form-field {
  margin-bottom: 0.3em;
}
</style>
