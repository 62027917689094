<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { useQuery } from '@vue/apollo-composable'
import gql from 'graphql-tag'
import { computed, ref } from 'vue'
import {
  ConfirmCheck,
  NumericCheck,
  StringCheck,
  ViewProductionBatchSerialNumberDataQuery,
  ViewProductionBatchSerialNumberDataQueryVariables,
} from '@/generated/graphql'
import DataTable from '@/components/dataTable/DataTable.vue'
import DateTime from '@/components/DateTime.vue'
import CheckDialog from '@/components/check/CheckDialog.vue'
import IconChecked from '@/components/icons/IconChecked.vue'
import ArticleQuantity from '@/components/article/ArticleQuantity.vue'
import Markdown from '@/components/Markdown.vue'
import IssueDialog from '@/components/issue/IssueDialog.vue'
import { ComponentExposed } from 'vue-component-type-helpers'

const { t } = useI18n()

const props = defineProps<{
  poNumber: string
  serialNumber: string
}>()

type LocalCheck = ViewProductionBatchSerialNumberDataQuery['shopFloor']['checks'][0]
type LocalIssue = ViewProductionBatchSerialNumberDataQuery['issue']['issues'][0]
const fetchQuery = useQuery<
  ViewProductionBatchSerialNumberDataQuery,
  ViewProductionBatchSerialNumberDataQueryVariables
>(
  gql`
    query ViewProductionBatchSerialNumberData($poNumber: String!, $serialNumber: String!) {
      shopFloor {
        checks(query: { serialNumber: $serialNumber, poNumber: $poNumber }) {
          ... on CheckI {
            id
            createdAt
            reportingUser {
              firstname
              lastname
            }
            configuration {
              description
              id
            }
            ... on StringCheck {
              text
            }
            ... on NumericCheck {
              milliQuantity
            }
            ... on ConfirmCheck {
              confirmed
            }
          }
        }
      }
      issue {
        issues(query: { serialNumbers: [$serialNumber] }) {
          id
          name
          createdAt
          createdByUser {
            firstname
            lastname
          }
        }
      }
    }
  `,
  () => ({
    poNumber: props.poNumber,
    serialNumber: props.serialNumber,
  }),
)
const checks = computed(() => fetchQuery.result.value?.shopFloor.checks || [])
const checksHeaders = [
  {
    key: 'createdAt',
    title: t('entity.check.field.createdAt'),
    width: 50,
  },
  {
    key: 'reportingUser',
    title: t('entity.check.field.reportingUser'),
    width: 50,
  },
  {
    key: 'configuration.description',
    title: t('entity.checkConfiguration.field.description'),
    width: 50,
  },
  {
    key: 'value',
    title: t('entity.check.field.value'),
    width: 100,
  },
]
const selectedCheckId = ref<string>()
const selectCheck = (_: unknown, row: { item: { id: string } }) => {
  selectedCheckId.value = row.item.id
}

const issues = computed(() => fetchQuery.result.value?.issue.issues || [])
const issuesHeaders = [
  {
    key: 'createdAt',
    title: t('entity.issue.field.createdAt'),
    width: 50,
  },
  {
    key: 'createdByUser',
    title: t('entity.issue.field.createdByUser'),
    width: 50,
  },
  {
    key: 'name',
    title: t('entity.issue.field.name'),
    width: 100,
  },
]
const issueDialog = ref<ComponentExposed<typeof IssueDialog>>()
const selectIssue = (_: unknown, row: { item: { id: string } }) => {
  issueDialog.value?.open(row.item.id)
}
</script>

<template>
  <h3>{{ t('entity.check.plural') }}</h3>

  <data-table
    :items="checks"
    :headers="checksHeaders"
    :loading="fetchQuery.loading"
    density="comfortable"
    hide-default-footer
    disable-sort
    @click:row="selectCheck"
  >
    <template #item.createdAt="{ item }: { item: LocalCheck }">
      <date-time :model-value="item.createdAt" />
    </template>
    <template #item.reportingUser="{ item }: { item: LocalCheck }">
      {{ item.reportingUser.firstname }} {{ item.reportingUser.lastname }}
    </template>
    <template #item.value="{ item }: { item: LocalCheck }">
      <icon-checked
        v-if="item.__typename == 'ConfirmCheck'"
        :value="(item as ConfirmCheck).confirmed"
      />
      <article-quantity
        v-if="item.__typename == 'NumericCheck'"
        :milli-quantity="(item as NumericCheck).milliQuantity"
      />
      <markdown
        v-if="item.__typename == 'StringCheck'"
        :text="(item as StringCheck).text"
        :clip-around="40"
      />
    </template>
  </data-table>
  <check-dialog v-model="selectedCheckId" />

  <h3 class="mt-5">{{ t('entity.issue.plural') }}</h3>
  <data-table
    :items="issues"
    :headers="issuesHeaders"
    :loading="fetchQuery.loading"
    density="comfortable"
    hide-default-footer
    disable-sort
    @click:row="selectIssue"
  >
    <template #item.createdAt="{ item }: { item: LocalIssue }">
      <date-time :model-value="item.createdAt" />
    </template>
    <template #item.createdByUser="{ item }: { item: LocalIssue }">
      {{ item.createdByUser.firstname }} {{ item.createdByUser.lastname }}
    </template>
  </data-table>
  <issue-dialog ref="issueDialog" />
</template>

<style scoped lang="scss"></style>
