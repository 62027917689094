<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { useQuery } from '@vue/apollo-composable'
import { ViewStepDataQuery, ViewStepDataQueryVariables } from '@/generated/graphql'
import gql from 'graphql-tag'
import { computed, ref } from 'vue'
import ImagesViewer from '@/components/upload/ImagesViewer.vue'
import IconArticleType from '@/components/icons/IconArticleType.vue'
import ArticleName from '@/components/article/ArticleName.vue'
import ArticleUnitTag from '@/components/article/ArticleUnitTag.vue'
import Markdown from '@/components/Markdown.vue'

const props = defineProps<{
  stepId: string
}>()

type LocalImageUpload = ViewStepDataQuery['product']['step']['imageUploads'][0]
type LocalPartAllocation = ViewStepDataQuery['product']['step']['articleAllocations'][0]

const { t } = useI18n()

const imgHeight = 300
const imgWidth = 300
const fetchQuery = useQuery<ViewStepDataQuery, ViewStepDataQueryVariables>(
  gql`
    query ViewStepData($id: ID!, $imgHeight: Int!, $imgWidth: Int!) {
      product {
        step(id: $id) {
          id
          durationInSeconds
          imageUploadIds
          imageUploads {
            id
            imageUrl(options: { height: $imgHeight, width: $imgWidth })
          }
          instructionText
          name
          stepType {
            id
            name
          }
          toolIds
          tools {
            id
            name
            description
            imageUploads {
              id
              imageUrl(options: { height: $imgHeight, width: $imgWidth })
            }
          }
          articleAllocations {
            ... on IArticleAllocation {
              id
              milliQuantity
              article {
                id
                type
                name
                unit
                revision
                articleNumber
              }
            }
          }
        }
      }
    }
  `,
  () => ({
    id: props.stepId,
    imgHeight,
    imgWidth,
  }),
  () => ({
    pollInterval: 50_000, // Image urls are valid for 60s
  }),
)
const step = computed(() => fetchQuery.result.value?.product.step)
const articleAllocations = computed(
  () => (step.value?.articleAllocations as LocalPartAllocation[]) || [],
)
const selectedImageId = ref<string>()
</script>

<template>
  <template v-if="!step">
    <v-skeleton-loader type="article" />
    <v-skeleton-loader type="card" />
    <v-skeleton-loader type="article" />
  </template>

  <template v-if="step">
    <h1>
      {{ step.name }}
      <v-chip>{{ step.stepType.name }}</v-chip>
    </h1>

    <v-row>
      <v-col>
        <template v-if="articleAllocations.length > 0">
          <h2 style="margin-top: 0">{{ t('entity.article.plural') }}</h2>
          <v-table density="comfortable">
            <thead>
              <tr>
                <td>{{ t('entity.article.field.type') }}</td>
                <td>{{ t('entity.article.singular') }}</td>
                <td>{{ t('entity.article.field.quantity') }}</td>
              </tr>
            </thead>
            <tbody>
              <tr v-for="a in articleAllocations" :key="a.id">
                <td><icon-article-type :type="a.article.type" /></td>
                <td><article-name :article="a.article" /></td>
                <td>
                  {{ a.milliQuantity / 1000 }}
                  <article-unit-tag :model-value="a.article.unit" abbreviated />
                </td>
              </tr>
            </tbody>
          </v-table>
        </template>

        <template v-if="step.tools.length > 0">
          <h2>{{ t('entity.step.field.tools') }}</h2>
          <v-expansion-panels variant="accordion" elevation="1">
            <v-expansion-panel v-for="t in step.tools" :key="t.id">
              <v-expansion-panel-title>
                <template #default="{ expanded }">
                  <p :class="{ 'font-weight-bold': expanded }">{{ t.name }}</p>
                </template>
              </v-expansion-panel-title>
              <v-expansion-panel-text>
                <p class="mb-5">
                  {{ t.description }}
                </p>

                <v-row>
                  <v-col v-for="u in t.imageUploads" :key="u.id" cols="6">
                    <v-img :src="u.imageUrl" :max-width="imgWidth" :max-height="imgHeight" />
                  </v-col>
                </v-row>
              </v-expansion-panel-text>
            </v-expansion-panel>
          </v-expansion-panels>
        </template>

        <h2>{{ t('entity.step.field.instructionText') }}</h2>
        <markdown :text="step.instructionText" />
      </v-col>

      <v-col v-if="step.imageUploads.length > 0" align-self="center" cols="6">
        <v-row class="justify-center vertical_align_center">
          <v-col
            v-for="u in step.imageUploads"
            :key="u.id"
            cols="3"
            class="text-center"
            style="align-content: center"
          >
            <v-img
              :src="u.imageUrl"
              :max-width="imgWidth"
              :max-height="imgHeight"
              style="cursor: zoom-in"
              @click="selectedImageId = u.id"
            />
          </v-col>
        </v-row>

        <images-viewer
          v-model="selectedImageId"
          :upload-ids="step.imageUploadIds"
          :selected-upload-id="selectedImageId"
        />
      </v-col>
    </v-row>
  </template>
</template>

<style scoped lang="scss">
h2 {
  margin-top: 1.5em;
}
thead {
  font-weight: bold;
}
</style>
