<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import useCurrentProductionBatch from '@/components/operator/currentProductionBatch'
import { computed, watch } from 'vue'

const props = defineProps<{
  stepId?: string
  moduleId: string
}>()

const { t } = useI18n()
const { currentProductionBatch, deselectCurrentProductionBatch, setCurrentStep } =
  useCurrentProductionBatch()

const moduleId = computed(() => props.moduleId)
const stepId = computed(() => props.stepId)
watch(
  [moduleId, stepId, currentProductionBatch],
  () => {
    if (!currentProductionBatch.value || !stepId.value) {
      return
    }
    if (moduleId.value != currentProductionBatch.value?.module.id) {
      deselectCurrentProductionBatch()
    }

    setCurrentStep(stepId.value)
  },
  { immediate: true },
)
</script>

<template>
  <v-alert v-if="!!currentProductionBatch" type="info" density="compact">
    {{ t('component.operatorCurrentProductionBatch.title', currentProductionBatch) }}

    <template #append>
      <v-btn variant="tonal" @click="deselectCurrentProductionBatch()">
        {{ t('component.operatorCurrentProductionBatch.deselectButton') }}
      </v-btn>
    </template>
  </v-alert>
</template>

<style scoped lang="scss"></style>
