import Keycloak from 'keycloak-js'
import { ref } from 'vue'

export type Authentication = {
  token: string
  userId: string
  logout: () => Promise<void>
}
export const authentication = ref<Authentication>()

const initOptions: Keycloak.KeycloakInitOptions = {
  useNonce: true,
  responseMode: 'fragment',
  flow: 'standard',
  pkceMethod: 'S256',
  enableLogging: false,
  scope: 'openid baseboard-console',
  onLoad: 'login-required',
}
const constructorOptions = {
  url: import.meta.env.VITE_KEYCLOAK_URL as string,
  realm: import.meta.env.VITE_KEYCLOAK_REALM as string,
  clientId: import.meta.env.VITE_KEYCLOAK_CLIENT_ID as string,
}
const keycloak = new Keycloak(constructorOptions)

type Token = Keycloak.KeycloakTokenParsed & {
  baseboard: {
    'user-id': string
  }
}

let intervalId: number = 0
function authSuccess() {
  const details = keycloak.tokenParsed as Token
  authentication.value = {
    token: keycloak.token as string,
    userId: details.baseboard['user-id'],
    logout: async () => await keycloak.logout(),
  }

  if (intervalId !== 0) {
    window.clearInterval(intervalId)
  }
  intervalId = window.setInterval(() => keycloak.updateToken(65), 60_000)

  if (initOptions.enableLogging) {
    console.log(`[AUTH] Got token and started refresh interval`, details)
  }
}

document.addEventListener('visibilitychange', () => {
  if (document.visibilityState == 'visible') {
    keycloak.updateToken(30)
  }
})
window.addEventListener('focus', () => {
  keycloak.updateToken(30)
})

export default async (): Promise<undefined> => {
  return new Promise((resolve, reject) => {
    try {
      keycloak.onAuthSuccess = () => {
        authSuccess()
        resolve(undefined)
      }
      keycloak.onAuthError = (errorData) => {
        console.error('[AUTH] Could not authenticate', errorData)
        keycloak.login()
      }
      keycloak.onAuthRefreshSuccess = () => {
        if (initOptions.enableLogging) {
          console.log('[AUTH] Token refresh successful')
        }
        authSuccess()
      }
      keycloak.onAuthRefreshError = () => {
        console.error('[AUTH] Could not refresh token')
        keycloak.login()
      }
      keycloak.init(initOptions).then((authenticated) => {
        if (!authenticated) {
          if (initOptions.enableLogging) {
            console.log('[AUTH] Not authenticated, asking keycloak for a login')
          }
          keycloak.login()
        }
      })
    } catch (error) {
      console.error('Failed to initialize Keycloak adapter:', error)
      reject(error)
    }
  })
}
