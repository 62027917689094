<script setup lang="ts">
import { computed } from 'vue'
import OperatorStepTreeNode from '@/components/operator/OperatorStepTreeNode.vue'
import { OperatorModuleViewTreeNode } from '@/views/organization/operator/OperatorModuleView.vue'

const props = defineProps<{
  node: OperatorModuleViewTreeNode
}>()
const emit = defineEmits<{
  selectStepId: [value: string]
}>()

const stepNode = computed(() => props.node.stepNode)
const children = computed(() => props.node.children)
const isSelectable = computed(() => children.value.length == 0)

function selectStep() {
  if (!isSelectable.value) {
    return
  }

  emit('selectStepId', stepNode.value.step.id)
}
</script>

<template>
  <div class="node">
    <div :class="{ isSelectable, isNotSelectable: !isSelectable }" @click.stop="selectStep">
      <v-chip :variant="props.node.isSelected ? 'outlined' : 'text'" density="compact">
        {{ props.node.numbering }}
        {{ stepNode.step.name }}
      </v-chip>
      <v-chip density="compact" class="stepType">{{ stepNode.step.stepType.name }}</v-chip>
    </div>
    <div v-if="children.length > 0" class="children-container">
      <operator-step-tree-node
        v-for="c in children"
        :key="c.stepNode.id"
        :node="c"
        @select-step-id="(v) => $emit('selectStepId', v)"
      />
    </div>
  </div>
</template>

<style scoped lang="scss">
.node {
  position: relative;

  margin-bottom: 0.3em;

  .stepType {
    position: absolute;
    right: 0;
  }
  .isNotSelectable {
    cursor: not-allowed;
    opacity: 0.6;
  }
  .isSelectable {
    cursor: pointer;
  }
}
.children-container {
  margin: 0.1em 0 0 1.5em;
}
</style>
