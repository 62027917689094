<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { computed, Ref, unref } from 'vue'

const props = defineProps<{
  loading?: boolean | Ref<boolean>
}>()

const { t } = useI18n()

const isLoading = computed(() => unref(props.loading))
</script>

<template>
  <v-btn density="compact" icon="add" color="primary" :loading="isLoading" />
</template>

<style scoped lang="scss"></style>
