import { computed, ref } from 'vue'
import {
  CurrentUserAndPermissions,
  Organization,
  PermissionOrganization,
  PermissionUser,
} from '@/generated/graphql'
import { floor } from 'lodash'

export type KeysOfType<U extends string, R extends string[] = []> = {
  [S in U]: Exclude<U, S> extends never ? [...R, S] : KeysOfType<Exclude<U, S>, [...R, S]>
}[U] &
  string[]

export interface SimpleItem {
  id: string
  name: string
}

export function itemSortByName<T>(items: T[] | undefined, extractor: (item: T) => string): T[] {
  if (!items) {
    return []
  }
  return JSON.parse(JSON.stringify(items)).sort((a: T, b: T) =>
    extractor(a).localeCompare(extractor(b)),
  )
}
export function itemSortByNumber<T>(items: T[] | undefined, extractor: (item: T) => number): T[] {
  if (!items) {
    return []
  }
  return JSON.parse(JSON.stringify(items)).sort((a: T, b: T) => extractor(a) - extractor(b))
}
export function sortTree<T>(
  items: T[] | undefined,
  hasChildrenExtractor: (item: T) => boolean,
  nameExtractor: (item: T) => string,
): T[] {
  if (!items) {
    return []
  }
  return JSON.parse(JSON.stringify(items)).sort((a: T, b: T) => {
    const aHasChildren = hasChildrenExtractor(a)
    const bHasChildren = hasChildrenExtractor(b)
    if (aHasChildren && !bHasChildren) {
      return -1
    }
    if (bHasChildren && !aHasChildren) {
      return 1
    }

    return nameExtractor(a).localeCompare(nameExtractor(b))
  })
}

export function uniqueArray<T, P>(array: T[], propertyExtractor: (item: T) => P) {
  return array.filter(
    (value, index, self) =>
      index === self.findIndex((t) => propertyExtractor(t) === propertyExtractor(value)),
  )
}

export const secondsToHumanReadable = (seconds?: number) => {
  if (!seconds) {
    return ''
  }

  let remainder = seconds
  let ret = ''
  const units = [
    { unit: 'd', count: 86400 },
    { unit: 'h', count: 3600 },
    { unit: 'm', count: 60 },
    { unit: 's', count: 1 },
  ]
  units.forEach(({ unit, count }) => {
    if (remainder < count) {
      return
    }

    const units = floor(remainder / count)
    remainder -= units * count
    ret += `${units}${unit}`
  })

  return ret
}

export const currentOrganization = ref<Organization>()
export const currentUserAndPermissions = ref<CurrentUserAndPermissions | undefined>()

function hasUserPermission(
  permission: PermissionUser,
  userAndPerms?: CurrentUserAndPermissions,
): boolean {
  return userAndPerms?.userPermissions.includes(permission) || false
}

function hasOrgPermission(
  permission: PermissionOrganization,
  userAndPerms?: CurrentUserAndPermissions,
): boolean {
  return (
    userAndPerms?.orgPermissions?.includes(permission) ||
    userAndPerms?.orgPermissions?.includes(PermissionOrganization.OrgAdmin) ||
    false
  )
}

export const authzIsGlobalAdmin = computed<boolean>(() =>
  hasUserPermission(PermissionUser.UserGlobalAdmin, currentUserAndPermissions.value),
)
export const authzIsOrgAdmin = computed<boolean>(
  () =>
    authzIsGlobalAdmin.value ||
    hasOrgPermission(PermissionOrganization.OrgAdmin, currentUserAndPermissions.value),
)
export const authzIsOrgProductManagement = computed<boolean>(() =>
  hasOrgPermission(PermissionOrganization.OrgProductManagement, currentUserAndPermissions.value),
)
export const authzIsOrgUpload = computed<boolean>(() =>
  hasOrgPermission(PermissionOrganization.OrgUpload, currentUserAndPermissions.value),
)
export const authzIsOrgPartAllocation = computed<boolean>(() =>
  hasOrgPermission(PermissionOrganization.OrgPartAllocation, currentUserAndPermissions.value),
)
export const authzIsOrgMbom = computed<boolean>(() =>
  hasOrgPermission(PermissionOrganization.OrgMbom, currentUserAndPermissions.value),
)
export const authzIsOrgOperator = computed<boolean>(() =>
  hasOrgPermission(PermissionOrganization.OrgOperator, currentUserAndPermissions.value),
)
export const authzIsOrgArticleManagement = computed<boolean>(() =>
  hasOrgPermission(PermissionOrganization.OrgArticleManagement, currentUserAndPermissions.value),
)

export enum MimeTypeFilter {
  TEXT_CSV = 'text/csv',
  IMAGE_SVG = 'image/svg+xml',
  IMAGE_WEBP = 'image/webp',
  IMAGE_JPEG = 'image/jpeg',
  IMAGE_PNG = 'image/png',
  ALL = '*/*',
}
