<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { useQuery } from '@vue/apollo-composable'
import gql from 'graphql-tag'
import { computed, reactive, ref, watch } from 'vue'
import {
  IssueSearchQuery,
  IssueSortBy,
  IssueViewDataQuery,
  IssueViewDataQueryVariables,
  SortDirection,
} from '@/generated/graphql'
import DataTableCreateButton from '@/components/dataTable/DataTableCreateButton.vue'
import DateTime from '@/components/DateTime.vue'
import InlineBreadcrumb from '@/components/InlineBreadcrumb.vue'
import { issueLinkDisplayDetailsFragments } from '@/components/issue/issueFragments'
import DataTableServer from '@/components/dataTable/DataTableServer.vue'
import IssueDialog from '@/components/issue/IssueDialog.vue'
import { ComponentExposed } from 'vue-component-type-helpers'
import IssueFilters from '@/components/issue/IssueFilters.vue'
import CreateIssueDialog from '@/components/issue/CreateIssueDialog.vue'
import { useRoute, useRouter } from 'vue-router'

const { t } = useI18n()
const route = useRoute()
const router = useRouter()

type LocalIssue = IssueViewDataQuery['issue']['issues'][0]

const query = reactive<IssueSearchQuery>({})
const updateQuery = (sort: { key: keyof LocalIssue; order: 'asc' | 'desc' }[]) => {
  if (sort.length == 0) {
    return
  }
  const first = sort[0]
  if (first.key == 'createdAt') {
    query.sortBy = IssueSortBy.CreatedAt
  }
  if (first.key == 'updatedAt') {
    query.sortBy = IssueSortBy.UpdatedAt
  }
  if (first.order == 'asc') {
    query.sortDirection = SortDirection.Asc
  }
  if (first.order == 'desc') {
    query.sortDirection = SortDirection.Desc
  }
}

const fetchQuery = useQuery<IssueViewDataQuery, IssueViewDataQueryVariables>(
  gql`
    query IssueViewData($query: IssueSearchQuery!) {
      issue {
        issues(query: $query) {
          id
          name
          createdAt

          updatedAt
          assignedToUsers {
            id
            firstname
            lastname
          }
          resolvedAt
          links {
            ...IssueLinkDisplayDetails
          }
        }
      }
    }

    ${issueLinkDisplayDetailsFragments}
  `,
  () => ({
    query,
  }),
  {
    debounce: 300,
  },
)
const items = computed(() => fetchQuery.result.value?.issue.issues || [])
const tableItems = computed(() => items.value.slice(0, 100))
const hasMoreData = computed(() => items.value.length > tableItems.value.length)

const issueId = computed(() => route.params.issueId as string | undefined)
const issueDialog = ref<ComponentExposed<typeof IssueDialog>>()
watch(
  [issueId, issueDialog],
  async () => {
    const id = issueId.value
    if (id) {
      await issueDialog.value?.open(id)
      console.log('done')
      router.push({ params: { issueId: '' } })
    }
  },
  { immediate: true },
)
const selectRow = async (_: unknown, row: { item: LocalIssue }) => {
  selectIssue(row.item.id)
}
const selectIssue = (issueId: string) => {
  router.push({ params: { issueId } })
}

const createIssueDialog = ref<ComponentExposed<typeof CreateIssueDialog>>()
const doCreate = async () => {
  await createIssueDialog.value?.open()
  fetchQuery.refetch()
}

const headers = [
  {
    key: 'name',
    title: t('entity.issue.field.name'),
    sortable: false,
  },
  {
    key: 'links',
    title: t('entity.issue.field.links'),
    sortable: false,
    width: 350,
  },
  {
    key: 'assignedToUsers',
    title: t('entity.issue.field.assignedToUsers'),
    sortable: false,
    width: 300,
  },
  {
    key: 'createdAt',
    title: t('entity.issue.field.createdAt'),
    width: 125,
  },
  {
    key: 'updatedAt',
    title: t('entity.issue.field.updatedAt'),
    width: 125,
  },
  {
    key: 'resolvedAt',
    title: t('entity.issue.field.resolvedAt'),
    width: 125,
    sortable: false,
  },
  {
    key: 'actions',
    width: 100,
    sortable: false,
    align: 'end',
  },
]
const sort = [{ key: 'name', order: 'asc' }]
</script>

<template>
  <h1>{{ t('entity.issue.plural') }}</h1>

  <v-row>
    <v-col cols="3">
      <issue-filters v-model="query" />
    </v-col>
    <v-col>
      <data-table-server
        :items="tableItems"
        :items-length="tableItems.length"
        :has-more-data="hasMoreData"
        :headers="headers"
        :loading="fetchQuery.loading"
        density="comfortable"
        @click:row="selectRow"
        @update:sort-by="updateQuery"
      >
        <template #header.actions>
          <data-table-create-button @click="doCreate" />
        </template>
        <template #item.links="{ item }: { item: LocalIssue }">
          <inline-breadcrumb v-if="item.links.length > 0" :breadcrumb="item.links[0]" shortened />
          <v-chip v-if="item.links.length > 1" density="compact" class="ml-2 mb-1">
            +{{ item.links.length - 1 }}
          </v-chip>
        </template>
        <template #item.assignedToUsers="{ item }: { item: LocalIssue }">
          <template v-if="item.assignedToUsers.length > 0">
            {{ item.assignedToUsers[0].firstname }} {{ item.assignedToUsers[0].lastname }}
          </template>
          <v-chip v-if="item.assignedToUsers.length > 1" density="compact" class="ml-2 mb-1">
            +{{ item.assignedToUsers.length - 1 }}
          </v-chip>
        </template>
        <template #item.createdAt="{ item }: { item: LocalIssue }">
          <date-time :model-value="item.createdAt" />
        </template>
        <template #item.updatedAt="{ item }: { item: LocalIssue }">
          <date-time :model-value="item.updatedAt" />
        </template>
        <template #item.resolvedAt="{ item }: { item: LocalIssue }">
          <date-time :model-value="item.resolvedAt" />
        </template>
      </data-table-server>
    </v-col>
  </v-row>

  <issue-dialog ref="issueDialog" />
  <create-issue-dialog ref="createIssueDialog" @created="selectIssue" />
</template>

<style scoped lang="scss"></style>
