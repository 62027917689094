<script setup lang="ts" generic="MM">
import { ErrorObject, Validation } from '@vuelidate/core'
import { computed } from 'vue'

const props = defineProps<{
  validation?: Validation | Validation[]
}>()
const errors = computed<ErrorObject[]>(() => {
  if (!props.validation) {
    return []
  }
  if (Array.isArray(props.validation)) {
    return props.validation.flatMap((v?: Validation) => (v ? v.$errors : []))
  }
  return props.validation.$errors
})
</script>

<template>
  <ul v-if="errors.length > 0" class="text-error validation-errors">
    <li v-for="e in errors" :key="e.$uid">
      {{ e.$message }}
    </li>
  </ul>
</template>

<style lang="scss" scoped>
.validation-errors {
  margin-top: 5px;
  font-size: 0.9em;
}
</style>
