<script setup lang="ts" generic="T, MM">
import { Module, Query } from '@/generated/graphql'
import { useQuery } from '@vue/apollo-composable'
import gql from 'graphql-tag'
import SelectField, { SelectOption } from '@/components/input/SelectField.vue'
import { computed } from 'vue'
import { itemSortByName } from '@/app'

const props = defineProps<{
  bopId: string
  label: string
  required?: boolean
}>()

const fetchQuery = useQuery<Query>(
  gql`
    query getModulesForPicker($bopId: ID!) {
      product {
        bop(id: $bopId) {
          nodes {
            module {
              id
              name
              description
            }
          }
        }
      }
    }
  `,
  () => ({
    bopId: props.bopId,
  }),
)
const options = computed<SelectOption<Module>[]>(() =>
  itemSortByName(fetchQuery.result.value?.product.bop.nodes, (n) => n.module.name).map((n) => ({
    title: n.module.name,
    value: n.module,
  })),
)
</script>

<template>
  <select-field :label="props.label" :required="props.required" :items="options" />
</template>

<style scoped lang="scss"></style>
