<script setup lang="ts">
import { useI18n } from 'vue-i18n'

const props = defineProps<{
  value: boolean
}>()

const { t } = useI18n()
</script>

<template>
  <v-icon :icon="props.value ? 'check_box' : 'check_box_outline_blank'" />
</template>

<style scoped lang="scss"></style>
