<script setup lang="ts" generic="T, MM">
import { useQuery } from '@vue/apollo-composable'
import gql from 'graphql-tag'
import SelectField from '@/components/input/SelectField.vue'
import { computed } from 'vue'
import { itemSortByName } from '@/app'
import { ProductPickerDataQuery, ProductPickerDataQueryVariables } from '@/generated/graphql'

const fetchQuery = useQuery<ProductPickerDataQuery, ProductPickerDataQueryVariables>(gql`
  query ProductPickerData {
    product {
      products {
        id
        name
      }
    }
  }
`)
const options = computed(() =>
  itemSortByName(fetchQuery.result.value?.product.products, (c) => c.name).map((c) => ({
    title: c.name,
    value: c.id,
  })),
)
</script>

<template>
  <select-field :items="options" />
</template>

<style scoped lang="scss"></style>
