<script setup lang="ts">
import { useQuery } from '@vue/apollo-composable'
import {
  OrganizationUserPickerFindUserQuery,
  OrganizationUserPickerFindUserQueryVariables,
  OrganizationUserPickerQuery,
  OrganizationUserPickerQueryVariables,
} from '@/generated/graphql'
import gql from 'graphql-tag'
import { computed, ref } from 'vue'
import DataTable from '@/components/dataTable/DataTable.vue'
import { useI18n } from 'vue-i18n'
import DataTableCreateButton from '@/components/dataTable/DataTableCreateButton.vue'
import ButtonDelete from '@/components/button/ButtonDelete.vue'
import FormField from '@/components/input/FormField.vue'
import { VAutocomplete } from 'vuetify/components'

const { t } = useI18n()
const model = defineModel<string[]>({ required: true })

type LocalUser = OrganizationUserPickerQuery['account']['organizationUsers'][0]
const fetchQuery = useQuery<OrganizationUserPickerQuery, OrganizationUserPickerQueryVariables>(
  gql`
    query OrganizationUserPicker($ids: [ID!]!) {
      account {
        organizationUsers(ids: $ids) {
          id
          firstname
          lastname
        }
      }
    }
  `,
  () => ({
    ids: model.value,
  }),
)
const users = computed(() => fetchQuery.result.value?.account.organizationUsers || [])
const headers = [
  {
    key: 'firstname',
    title: t('entity.user.field.firstname'),
  },
  {
    key: 'lastname',
    title: t('entity.user.field.lastname'),
  },
  {
    key: 'actions',
    width: '100',
    sortable: false,
    align: 'end',
  },
]
const sort = [{ key: 'firstname', order: 'asc' }]

const search = ref<string>()
const findUsersQuery = useQuery<
  OrganizationUserPickerFindUserQuery,
  OrganizationUserPickerFindUserQueryVariables
>(
  gql`
    query OrganizationUserPickerFindUser($contains: String) {
      account {
        organizationSearchUsers(criteria: { contains: $contains }) {
          id
          firstname
          lastname
        }
      }
    }
  `,
  () => ({
    contains: search.value,
  }),
  () => ({
    debounce: 300,
  }),
)
type LocalFoundUser = OrganizationUserPickerFindUserQuery['account']['organizationSearchUsers'][0]
const foundUsers = computed(
  () =>
    findUsersQuery.result.value?.account.organizationSearchUsers
      .filter((u) => !model.value.includes(u.id))
      .map((u) => ({
        title: `${u.firstname} ${u.lastname}`,
        value: u,
      })) || [],
)

const doDelete = (item: LocalUser) => {
  model.value = model.value.filter((id) => id != item.id)
}
const addMenuOpen = ref(false)
const doAdd = (user: LocalFoundUser) => {
  if (!model.value.includes(user.id)) {
    model.value.push(user.id)
  }

  addMenuOpen.value = false
  search.value = undefined
  findUsersQuery.refetch()
}
</script>

<template>
  <form-field v-bind="$attrs">
    <data-table
      :items="users"
      :headers="headers"
      :sort-by="sort"
      :loading="fetchQuery.loading"
      hide-default-footer
    >
      <template #header.actions>
        <v-menu v-model="addMenuOpen" :close-on-content-click="false">
          <template #activator="{ props }">
            <data-table-create-button v-bind="props" />
          </template>

          <v-card width="500">
            <v-card-text>
              <v-autocomplete
                v-if="addMenuOpen"
                auto-select-first
                :items="foundUsers"
                hide-details
                autofocus
                :loading="findUsersQuery.loading.value"
                @update:model-value="(v: LocalFoundUser) => doAdd(v)"
                @update:search="(v) => (search = v)"
              />
            </v-card-text>
          </v-card>
        </v-menu>
      </template>
      <template #item.actions="{ item }: { item: LocalUser }">
        <button-delete @click="doDelete(item)" />
      </template>
    </data-table>
  </form-field>
</template>

<style scoped lang="scss"></style>
