<script setup lang="ts">
import { useQuery } from '@vue/apollo-composable'
import gql from 'graphql-tag'
import { computed } from 'vue'
import {
  EbomBulkAllocateStepTreeDataQuery,
  EbomBulkAllocateStepTreeDataQueryVariables,
} from '@/generated/graphql'
import { v4 } from 'uuid'
import EbomBulkAllocateModuleTreeNode from '@/components/ebom/EbomBulkAllocateModuleTreeNode.vue'

const props = defineProps<{
  bopId: string
}>()

const fetchQuery = useQuery<
  EbomBulkAllocateStepTreeDataQuery,
  EbomBulkAllocateStepTreeDataQueryVariables
>(
  gql`
    query EbomBulkAllocateStepTreeData($id: ID!) {
      product {
        bop(id: $id) {
          nodes {
            id
            parentIds
            module {
              id
              name
              description
              nodes {
                id
                parentId
                step {
                  id
                  name
                }
              }
            }
          }
        }
      }
    }
  `,
  () => ({
    id: props.bopId,
  }),
)
const bopNodes = computed(() => fetchQuery.result.value?.product.bop.nodes || [])

type LocalModuleNode = EbomBulkAllocateStepTreeDataQuery['product']['bop']['nodes'][0]
type LocalModule = LocalModuleNode['module']
type LocalStepNode = LocalModule['nodes'][0]
export type EbomBulkAllocateModuleNode = {
  id: string
  module: LocalModule
  stepNodes: EbomBulkAllocateStepNode[]
}
export type EbomBulkAllocateStepNode = {
  id: string
  step: LocalStepNode['step']
  children: EbomBulkAllocateStepNode[]
}

const rootNodes = computed<EbomBulkAllocateModuleNode[]>(() =>
  bopNodes.value
    .filter((n) => n.parentIds.length == 0)
    .reduce((agg, p) => addNode(agg, bopNodes.value, p), [] as EbomBulkAllocateModuleNode[]),
)
const addNode = (
  aggregator: EbomBulkAllocateModuleNode[],
  nodes: LocalModuleNode[],
  node: LocalModuleNode,
): EbomBulkAllocateModuleNode[] => {
  if (!aggregator.some((m) => m.module.id == node.module.id)) {
    aggregator.push({
      id: v4(),
      module: node.module,
      stepNodes: node.module.nodes
        .filter((n) => !n.parentId)
        .map((n) => createStepNode(node.module.nodes, n)),
    })
  }
  nodes.filter((p) => p.parentIds.includes(node.id)).forEach((n) => addNode(aggregator, nodes, n))
  return aggregator
}
const createStepNode = (nodes: LocalStepNode[], node: LocalStepNode): EbomBulkAllocateStepNode => ({
  id: v4(),
  step: node.step,
  children: nodes.filter((n) => n.parentId == node.id).map((n) => createStepNode(nodes, n)),
})
</script>

<template>
  <v-list nav density="compact">
    <ebom-bulk-allocate-module-tree-node v-for="n in rootNodes" :key="n.id" :node="n" />
  </v-list>
</template>

<style scoped lang="scss"></style>
