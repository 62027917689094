<script setup lang="ts">
import { useQuery } from '@vue/apollo-composable'
import {
  OrganizationUserPickerFindUserQuery,
  OrganizationUserPickerFindUserQueryVariables,
} from '@/generated/graphql'
import gql from 'graphql-tag'
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import FormField from '@/components/input/FormField.vue'
import { VAutocomplete } from 'vuetify/components'

const { t } = useI18n()
const model = defineModel<string>({ required: false })

const search = ref<string>()
const findUsersQuery = useQuery<
  OrganizationUserPickerFindUserQuery,
  OrganizationUserPickerFindUserQueryVariables
>(
  gql`
    query OrganizationUserPickerFindUser($contains: String) {
      account {
        organizationSearchUsers(criteria: { contains: $contains }) {
          id
          firstname
          lastname
        }
      }
    }
  `,
  () => ({
    contains: search.value,
  }),
  () => ({
    debounce: 300,
  }),
)
type LocalFoundUser = OrganizationUserPickerFindUserQuery['account']['organizationSearchUsers'][0]
const foundUsers = computed(
  () =>
    findUsersQuery.result.value?.account.organizationSearchUsers.map((u) => ({
      title: `${u.firstname} ${u.lastname}`,
      value: u,
    })) || [],
)
</script>

<template>
  <form-field v-bind="$attrs">
    <v-autocomplete
      auto-select-first
      :items="foundUsers"
      hide-details
      clearable
      :loading="findUsersQuery.loading.value"
      @update:model-value="(v?: LocalFoundUser) => (model = v?.id)"
      @update:search="(v) => (search = v)"
    />
  </form-field>
</template>

<style scoped lang="scss"></style>
