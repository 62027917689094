<script setup lang="ts">
import { useI18n } from 'vue-i18n'

const { t } = useI18n()
</script>

<template>
  <v-btn density="compact" variant="text" icon="zoom_in" :title="t('button.view')" />
</template>

<style scoped lang="scss"></style>
