<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { computed, reactive, ref } from 'vue'
import { useMutation, useQuery } from '@vue/apollo-composable'
import {
  CreateStepType,
  Query,
  StepTypeViewCreateMutation,
  StepTypeViewCreateMutationVariables,
  StepTypeViewDataQuery,
  StepTypeViewDeleteMutation,
  StepTypeViewDeleteMutationVariables,
  StepTypeViewUpdateMutation,
  StepTypeViewUpdateMutationVariables,
  UpdateStepType,
} from '@/generated/graphql'
import gql from 'graphql-tag'
import useVuelidate from '@vuelidate/core'
import { KeysOfType } from '@/app'
import TextField from '@/components/input/TextField.vue'
import { normalName, required } from '@/validation'
import ItemEditIcon from '@/components/button/ButtonEdit.vue'
import ItemDeleteIcon from '@/components/button/ButtonDelete.vue'
import { ComponentExposed } from 'vue-component-type-helpers'
import UpsertDialog from '@/components/dialogs/UpsertDialog.vue'
import DeleteDialogNext from '@/components/dialogs/AsyncDeleteDialog.vue'
import AsyncDeleteDialog from '@/components/dialogs/AsyncDeleteDialog.vue'
import DataTable from '@/components/dataTable/DataTable.vue'
import DataTableSearch from '@/components/dataTable/DataTableSearch.vue'
import DataTableCreateButton from '@/components/dataTable/DataTableCreateButton.vue'
import Checkbox from '@/components/input/Checkbox.vue'
import IconArchived from '@/components/icons/IconArchived.vue'

type LocalStepType = StepTypeViewDataQuery['product']['stepTypes'][0]
const { t } = useI18n()

const fetchQuery = useQuery<Query>(gql`
  query StepTypeViewData {
    product {
      stepTypes {
        id
        name
        archived
        released
      }
    }
  }
`)
const items = computed(() => fetchQuery.result.value?.product.stepTypes || [])

type CreateModel = Partial<CreateStepType>
const createModelKeys: KeysOfType<keyof CreateModel> = ['id', 'name']
const createModel = reactive<UpdateModel>({})
const createValidation = useVuelidate<UpdateModel>({ name: { required, normalName } }, createModel)
const createMutation = useMutation<StepTypeViewCreateMutation, StepTypeViewCreateMutationVariables>(
  gql`
    mutation StepTypeViewCreate($command: CreateStepType!) {
      product {
        createStepType(command: $command) {
          id
        }
      }
    }
  `,
)
const createDialog = ref<ComponentExposed<typeof UpsertDialog<() => Promise<unknown>>>>()
const doCreate = async () => {
  createModelKeys.forEach((k) => {
    ;(createModel as Record<string, unknown>)[k] = undefined
  })
  await createDialog.value?.open(() =>
    createMutation
      .mutate({ command: createModel as CreateStepType })
      .then(() => fetchQuery.refetch()),
  )
}

type UpdateModel = Partial<UpdateStepType>
const updateModelKeys: KeysOfType<keyof UpdateModel> = ['id', 'name', 'archived']
const updateModel = reactive<UpdateModel>({})
const updateValidation = useVuelidate<UpdateModel>({ name: { required, normalName } }, updateModel)
const updateMutation = useMutation<StepTypeViewUpdateMutation, StepTypeViewUpdateMutationVariables>(
  gql`
    mutation StepTypeViewUpdate($command: UpdateStepType!) {
      product {
        updateStepType(command: $command) {
          id
        }
      }
    }
  `,
)
const updateIsReleased = ref(false)
const updateDialog = ref<ComponentExposed<typeof UpsertDialog<() => Promise<unknown>>>>()
const doUpdate = async (item: LocalStepType) => {
  updateModelKeys.forEach((k) => {
    ;(updateModel as Record<string, unknown>)[k] = item[k as keyof LocalStepType]
  })
  updateIsReleased.value = item.released
  await updateDialog.value?.open(() =>
    updateMutation
      .mutate({ command: updateModel as UpdateStepType })
      .then(() => fetchQuery.refetch()),
  )
}

const deleteMutation = useMutation<StepTypeViewDeleteMutation, StepTypeViewDeleteMutationVariables>(
  gql`
    mutation StepTypeViewDelete($id: ID!) {
      product {
        deleteStepType(id: $id)
      }
    }
  `,
)
const deleteDialog = ref<ComponentExposed<typeof DeleteDialogNext>>()
const doDelete = async (item: LocalStepType) => {
  await deleteDialog.value?.open(item.name, () =>
    deleteMutation.mutate({ id: item.id }).then(() => fetchQuery.refetch()),
  )
}

const search = ref('')
const headers = [
  {
    key: 'name',
    title: t('entity.stepType.field.name'),
  },
  {
    key: 'archived',
    title: t('entity.stepType.field.archived'),
    width: 100,
  },
  {
    key: 'actions',
    width: 100,
    sortable: false,
    align: 'end',
  },
]
const sort = [{ key: 'name', order: 'asc' }]
</script>

<template>
  <h1>{{ t('entity.stepType.plural') }}</h1>

  <data-table
    :items="items"
    :headers="headers"
    :loading="fetchQuery.loading.value"
    :sort-by="sort"
    :search="search"
    density="comfortable"
  >
    <template #header.actions>
      <data-table-search v-model="search" />
      <data-table-create-button @click="doCreate" />
    </template>
    <template #item.actions="{ item }: { item: LocalStepType }">
      <item-edit-icon @click="doUpdate(item)" />
      <item-delete-icon
        :disable-reason="item.released ? t('entity.stepType.disableDeleteBecause') : undefined"
        @click="doDelete(item)"
      />
    </template>
    <template #item.archived="{ item }: { item: LocalStepType }">
      <icon-archived :is-archived="item.archived" />
    </template>
  </data-table>

  <async-delete-dialog ref="deleteDialog" :warning="t('entity.stepType.deleteWarning')" hindrance />

  <upsert-dialog ref="createDialog" type="create" :validation="createValidation">
    <text-field
      v-model="createModel.name"
      :label="t('entity.stepType.field.name')"
      required
      :validation="updateValidation.name"
    />
  </upsert-dialog>

  <upsert-dialog ref="updateDialog" type="update" :validation="updateValidation">
    <v-alert v-if="updateIsReleased" type="warning" variant="outlined" class="mb-5">
      {{ t('entity.stepType.editWarning') }}
    </v-alert>
    <text-field
      v-model="updateModel.name"
      required
      :label="t('entity.stepType.field.name')"
      :validation="updateValidation.name"
    />
    <checkbox v-model="updateModel.archived" :label="t('entity.stepType.field.archived')" />
  </upsert-dialog>
</template>

<style scoped lang="scss"></style>
