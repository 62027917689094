import {
  ApolloClient,
  ApolloLink,
  createHttpLink,
  DefaultOptions,
  InMemoryCache,
  NextLink,
  Operation,
} from '@apollo/client/core'
import result from './generated/graphql-fragments'
import { currentOrganization } from '@/app'
import { onError } from '@apollo/client/link/error'
import { authentication } from '@/authentication'
import { ref } from 'vue'
import { ApiErrors } from '@/components/layout/ApiErrorSnackbar.vue'
import useTracker from '@/useTracker'

const environment = import.meta.env.VITE_ENVIRONMENT as string
export const apiErrors = ref<ApiErrors>()
const errorLink = onError((errorResponse) => {
  errorResponse.graphQLErrors?.forEach(({ message, locations, path }) =>
    console.error(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`),
  )

  apiErrors.value = errorResponse
})
const authLink = new ApolloLink((operation: Operation, forward: NextLink) => {
  const headers: { [key: string]: string | undefined } = {
    Authorization: `Bearer ${authentication.value?.token}`,
  }
  if (environment == 'development') {
    headers['baseboard-user-id'] = authentication.value?.userId
  }
  if (currentOrganization.value) {
    headers['baseboard-organization-id'] = currentOrganization.value?.id
  }
  operation.setContext({ headers })

  return forward(operation)
})
const httpLink = createHttpLink({
  uri: import.meta.env.VITE_GRAPHQL_ENDPOINT as string,
})
const cache = new InMemoryCache({
  possibleTypes: result.possibleTypes,
})
const defaultOptions: DefaultOptions = {
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'none',
  },
  watchQuery: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'none',
  },
  mutate: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'none',
  },
}

const { trackerApolloLink } = useTracker()
export const apolloClient = new ApolloClient({
  link: authLink.concat(errorLink).concat(trackerApolloLink).concat(httpLink),
  cache,
  defaultOptions,
  connectToDevTools: environment == 'development',
})
