<script setup lang="ts">
import { useMutation, useQuery } from '@vue/apollo-composable'
import gql from 'graphql-tag'
import { computed, ref } from 'vue'
import {
  UserProductionBatchesDataQuery,
  UserProductionBatchesDataQueryVariables,
  UserProductionBatchesDeleteMutation,
  UserProductionBatchesDeleteMutationVariables,
  UserProductionBatchesTransferMutation,
  UserProductionBatchesTransferMutationVariables,
} from '@/generated/graphql'
import { useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import ItemDeleteIcon from '@/components/button/ButtonDelete.vue'
import useCurrentProductionBatch from '@/components/operator/currentProductionBatch'
import AsyncDeleteDialog from '@/components/dialogs/AsyncDeleteDialog.vue'
import DeleteDialogNext from '@/components/dialogs/AsyncDeleteDialog.vue'
import { ComponentExposed } from 'vue-component-type-helpers'
import DataTable from '@/components/dataTable/DataTable.vue'
import { currentUserAndPermissions } from '@/app'
import OrganizationUserAutocomplete from '@/components/input/OrganizationUserAutocomplete.vue'
import AsyncConfirmDialog from '@/components/dialogs/AsyncConfirmDialog.vue'

type LocalBatch =
  UserProductionBatchesDataQuery['shopFloor']['productionBatchesInProgressForUser'][0]

const { t } = useI18n()
const router = useRouter()
const { setCurrentProductionBatch } = useCurrentProductionBatch()

const currentUserId = computed(() => currentUserAndPermissions.value?.user.id)
const selectedUserId = ref<string | undefined>(currentUserId.value)
const fetchQuery = useQuery<
  UserProductionBatchesDataQuery,
  UserProductionBatchesDataQueryVariables
>(
  gql`
    query UserProductionBatchesData($userId: ID!) {
      shopFloor {
        productionBatchesInProgressForUser(userId: $userId) {
          id
          poNumber
          serialNumbers
          assignedUser {
            id
          }
          currentStep {
            id
            name
          }
          bop {
            id
            version
            site {
              id
              name
            }
            productConfiguration {
              id
              name
              product {
                id
                name
              }
            }
          }
          module {
            id
            name
          }
        }
      }
    }
  `,
  () => ({
    userId: selectedUserId.value as string,
  }),
  () => ({
    enabled: !!selectedUserId.value,
  }),
)
const batches = computed(
  () => fetchQuery.result.value?.shopFloor.productionBatchesInProgressForUser || [],
)

const transferMutation = useMutation<
  UserProductionBatchesTransferMutation,
  UserProductionBatchesTransferMutationVariables
>(gql`
  mutation UserProductionBatchesTransfer($command: TransferProductionBatchCommand!) {
    shopFloor {
      transferProductionBatch(command: $command) {
        id
      }
    }
  }
`)
const confirmTransferDialog = ref<ComponentExposed<typeof AsyncConfirmDialog<LocalBatch>>>()
const doTransfer = async (item: LocalBatch) => {
  await confirmTransferDialog.value?.open(
    () =>
      transferMutation.mutate({ command: { id: item.id, assignedUserId: currentUserId.value } }),
    item,
  )
  fetchQuery.refetch()
}

const deleteMutation = useMutation<
  UserProductionBatchesDeleteMutation,
  UserProductionBatchesDeleteMutationVariables
>(gql`
  mutation UserProductionBatchesDelete($id: ID!) {
    shopFloor {
      deleteProductionBatch(id: $id)
    }
  }
`)
const deleteDialog = ref<ComponentExposed<typeof DeleteDialogNext>>()
const doDelete = async (item: LocalBatch) => {
  await deleteDialog.value?.open(item.poNumber, () =>
    deleteMutation.mutate({ id: item.id }).then(() => fetchQuery.refetch()),
  )
}

const goToBatch = (_: unknown, row: { item: LocalBatch }) => {
  const batch = row.item
  setCurrentProductionBatch(batch.id)
  router.push({
    name: 'operatorModule',
    params: {
      productId: batch.bop.productConfiguration.product.id,
      productConfigId: batch.bop.productConfiguration.id,
      siteId: batch.bop.site.id,
      bopId: batch.bop.id,
      moduleId: batch.module.id,
      stepId: batch.currentStep?.id,
    },
  })
}

const headers = [
  {
    key: 'poNumber',
    title: t('entity.productionBatch.field.poNumber'),
    width: 150,
  },
  {
    key: 'serialNumbers',
    title: t('entity.productionBatch.field.serialNumbers'),
    sortable: false,
    width: 300,
  },
  {
    key: 'module',
    title: t('entity.module.singular'),
    sortable: false,
  },
  {
    key: 'actions',
    width: 100,
    sortable: false,
    align: 'end',
  },
]
const sort = [{ key: 'poNumber', order: 'asc' }]

const otherUsersMenuOpen = ref(false)
</script>

<template>
  <data-table
    :items="batches"
    :headers="headers"
    :loading="fetchQuery.loading.value"
    :sort-by="sort"
    density="comfortable"
    hide-default-footer
    @click:row="goToBatch"
  >
    <template #header.actions>
      <v-menu v-model="otherUsersMenuOpen" :close-on-content-click="false">
        <template #activator="{ props }">
          <v-btn
            icon="people"
            v-bind="props"
            density="compact"
            :variant="selectedUserId == currentUserId ? 'flat' : 'tonal'"
            :title="t('component.userProductionBatches.buttonOtherUsers')"
          />
        </template>

        <v-card width="25em">
          <v-card-text>
            <organization-user-autocomplete
              v-model="selectedUserId"
              autofocus
              @update:model-value="otherUsersMenuOpen = false"
            />
          </v-card-text>
        </v-card>
      </v-menu>
    </template>
    <template #item.actions="{ item }: { item: LocalBatch }">
      <template v-if="item.assignedUser?.id == currentUserId">
        <item-delete-icon @click="doDelete(item)" />
      </template>
      <template v-else>
        <v-btn
          variant="flat"
          density="compact"
          icon="add_shopping_cart"
          :title="t('component.userProductionBatches.buttonTransferBatch')"
          @click.stop="doTransfer(item)"
        />
      </template>
    </template>
    <template #item.module="{ item }: { item: LocalBatch }">
      <span>{{ item.bop.productConfiguration.product.name }} <v-icon icon="chevron_right" /></span>
      <span>{{ item.bop.productConfiguration.name }} <v-icon icon="chevron_right" /></span>
      <span>{{ item.bop.site.name }} <v-icon icon="chevron_right" /></span>
      <span>{{ item.bop.version }} <v-icon icon="chevron_right" /></span>
      <span>{{ item.module.name }}</span>
    </template>
    <template #item.serialNumbers="{ item }: { item: LocalBatch }">
      {{ item.serialNumbers.join(', ') }}
    </template>
  </data-table>

  <async-delete-dialog ref="deleteDialog" />
  <async-confirm-dialog ref="confirmTransferDialog">
    <template #default="{ item }: { item: LocalBatch }">
      {{ t('component.userProductionBatches.confirmTransferText', { poNumber: item.poNumber }) }}
    </template>
  </async-confirm-dialog>
</template>

<style scoped lang="scss"></style>
