<script setup lang="ts">
import { useQuery } from '@vue/apollo-composable'
import {
  MultiUploadPickerDataQuery,
  MultiUploadPickerDataQueryVariables,
} from '@/generated/graphql'
import gql from 'graphql-tag'
import { computed, ref } from 'vue'
import UploadPicker, { PickedUpload } from '@/components/upload/UploadPicker.vue'
import { MimeTypeFilter } from '@/app'
import DataTable from '@/components/dataTable/DataTable.vue'
import { useI18n } from 'vue-i18n'
import DataTableCreateButton from '@/components/dataTable/DataTableCreateButton.vue'
import ButtonDelete from '@/components/button/ButtonDelete.vue'
import FormField from '@/components/input/FormField.vue'

const props = defineProps<{
  mimeTypes: MimeTypeFilter[]
}>()
const model = defineModel<string[]>({ required: true })
const { t } = useI18n()

type LocalUpload = MultiUploadPickerDataQuery['upload']['uploads'][0]
const fetchQuery = useQuery<MultiUploadPickerDataQuery, MultiUploadPickerDataQueryVariables>(
  gql`
    query MultiUploadPickerData($ids: [ID!]!) {
      upload {
        uploads(ids: $ids) {
          id
          name
          contentType
        }
      }
    }
  `,
  () => ({
    ids: model.value,
  }),
)
const uploads = computed(() => fetchQuery.result.value?.upload.uploads || [])
const uploadPickerOpen = ref(false)
const headers = [
  {
    key: 'name',
    title: t('entity.upload.field.name'),
  },
  {
    key: 'contentType',
    title: t('entity.upload.field.contentType'),
  },
  {
    key: 'actions',
    width: '100',
    sortable: false,
    align: 'end',
  },
]
const sort = [{ key: 'name', order: 'asc' }]

const doDelete = (item: LocalUpload) => {
  model.value = model.value.filter((id) => id != item.id)
}
const doAdd = (uploads: PickedUpload[]) => {
  uploads.forEach((u) => model.value.push(u.id))
}
</script>

<template>
  <form-field v-bind="$attrs">
    <data-table
      :items="uploads"
      :headers="headers"
      :sort-by="sort"
      :loading="fetchQuery.loading"
      hide-default-footer
    >
      <template #header.actions>
          <data-table-create-button @click="uploadPickerOpen = true" />
      </template>
      <template #item.actions="{ item }: { item: LocalUpload }">
          <button-delete @click="doDelete(item)" />
      </template>
    </data-table>
  </form-field>

  <upload-picker
    v-model="uploadPickerOpen"
    :mime-types="[MimeTypeFilter.IMAGE_JPEG, MimeTypeFilter.IMAGE_PNG, MimeTypeFilter.IMAGE_SVG]"
    @pick-multiple="doAdd"
  />
</template>

<style scoped lang="scss"></style>
